import React, { useState, useEffect, useLayoutEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { dashboardData } from "../../redux/_actions/apis.action";
import AdminChart from "./AdminChart";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
// import { getUSDTDecimal } from "../../redux/_actions/user.action";
import { BUSD_ADDRESS, IFANCY_TOKEN_ADDRESS } from "../../constant";
import { getUSDTDecimal } from "../../redux/_actions/user.action";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [totalUser, setTotalUser] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [data, setData] = useState([]);
  const [monthData, setMonthData] = useState();
  const [dashboardDetails, setDashboardDetails] = useState();
  const [ifanctTokenDecimal, setTokenDecimal] = useState(0);
  const [claimData, setClaimData] = useState();
  const [busdTokenDecimal, setBusdTokenDecimal] = useState(0);

  const wallet = useSelector((state) => state?.user.connectedWallet);

  useLayoutEffect(() => {
    dashboardApiData();
  }, []);

  const dashboardApiData = async () => {
    const result = await dispatch(dashboardData());
    // const tokenDecimal = await dispatch(
    //   getUSDTDecimal({
    //     wallet: wallet,
    //     address: IFANCY_TOKEN_ADDRESS,
    //   })
    // );

    setMonthData(result?.data?.monthData);
    setTokenDecimal(10 ** 6);
    setData(result?.data?.phaseData);
    setTotalUser(result?.data?.totalUsers);
    setTotalOrder(result?.data?.totalOrders);
    setDashboardDetails(result?.data?.dashboardData[0]);
    setClaimData(result?.data?.buyAmounttotal[0]);

    const busdDecimal = await dispatch(
      getUSDTDecimal({
        wallet: wallet,
        address: BUSD_ADDRESS,
      })
    );
    setBusdTokenDecimal(10 ** busdDecimal);
  };

  console.log("data~~~~~~~~~~~", data);
  return (
    <Container className="commonTop">
      <h3 className="title" style={{ color: "#fff" }}>
        Dashboard{" "}
      </h3>
      <Row>
        <Col lg={6} className="mb-4">
          <div className="adminInfoData">
            <h4>{totalUser}</h4>
            <p>Total Users</p>
          </div>
        </Col>
        <Col lg={6} className="mb-4">
          <div className="adminInfoData">
            <h4>{totalOrder}</h4>
            <p>Total Orders</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4} className="mb-4">
          <div className="adminInfoData">
            <h4>
              {dashboardDetails?.TotalTokensSold
                ? (
                    Number(dashboardDetails?.TotalTokensSold) /
                    ifanctTokenDecimal
                  ).toLocaleString("US")
                : 0}
            </h4>
            <p>Total Token Sold</p>
          </div>
        </Col>
        <Col lg={4} className="mb-4">
          <div className="adminInfoData">
            <h4>
              {dashboardDetails?.TokenLimit
                ? (
                    Number(dashboardDetails?.TokenLimit) / ifanctTokenDecimal -
                    Number(dashboardDetails?.TotalTokensSold) /
                      ifanctTokenDecimal
                  ).toLocaleString("US")
                : 0}
            </h4>
            <p>Total Token Left</p>
          </div>
        </Col>
        <Col lg={4} className="mb-4">
          <div className="adminInfoData">
            <h4>
              {claimData?.totalClaimedTokens
                ? (
                    Number(claimData?.totalClaimedTokens) / ifanctTokenDecimal
                  ).toLocaleString("US")
                : 0}
            </h4>
            <p>Total Token claimed</p>
          </div>
        </Col>
      </Row>
      <Row className="graphclass mb-5 pb-3">
        <Col lg={12}>
          {monthData ? (
            <AdminChart
              monthData={monthData}
              ifanctTokenDecimal={ifanctTokenDecimal}
            />
          ) : null}
        </Col>
      </Row>
      <div className="AdminCard mb-5">
        <Table striped hover responsive>
          <thead>
            <tr className="text-center">
              {/* <th>S.No.</th> */}
              <th>Phase-No.</th>
              <th>Token-Sold</th>
              <th>Token-Limit</th>
              <th>Dollar-Amount</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, key) => (
              <tr className="text-center">
                {/* <td>{key + 1}</td> */}
                <td>
                  {item?.phaseNumber == 1 ? (
                    "Pre-pre stage"
                  ) : item?.phaseNumber == 2 ? (
                    "Seed Round"
                  ) : (
                    <>Phase {Number(item?.phaseNumber) - 2}</>
                  )}
                </td>
                <td>
                  {(
                    Number(item?.tokenSold) / ifanctTokenDecimal
                  ).toLocaleString("US")}
                </td>
                <td>
                  {(
                    Number(item?.tokenLimit) / ifanctTokenDecimal
                  ).toLocaleString("US")}
                </td>
                <td>
                  ${" "}
                  {(
                    (Number(item?.tokenSold) / ifanctTokenDecimal) *
                    Number(item?.tokenPrice)
                  ).toLocaleString("US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </td>
                <td>{item?.isComplete == 1 ? "Completed" : "Not-Completed"}</td>
                <td>
                  {moment
                    .unix(Number(item?.startTimestamp))
                    .format("MM/DD/YYYY")}
                </td>
                <td>
                  {moment
                    .unix(Number(item?.expirationTimestamp))
                    .format("MM/DD/YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default AdminDashboard;
