import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import fiat from "../../../assets/images/wallet/fiat.svg";

const Fiat = () => {
  return (
    <>
      <section className="fiatSec">
        <Container className="commnCont">
          <Row>
            <Col md={6} left className="pr-xl-5">
              {/* <ImageWrap main={fiat} className="fiatImg" /> */}
              <img src={fiat} alt="" className="img-fluid" />
            </Col>
            <Col md={6} right className="pl-xl-5">
              <h2 className="walletheading__title common-heading">
                Hold Fiat <span className="walletheading__subText">&</span>{" "}
                Crypto
                <span className="walletheading__subText d-block">
                  only when you need to
                </span>
              </h2>
              <div className="wallet__content">
                <p>
                  The card is linked to a crypto wallet and converts crypto
                  automatically into fiat currency while making a payment. The
                  transactions use traditional payment processing networks, such
                  as Visa or MasterCard.
                </p>

                {/* <p>Simply login with a username and password</p> */}
                {/* <StoreBtns /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Fiat;
