import React from "react";
import "./Sell.scss";
import { Container, Row, Col } from "react-bootstrap";
import mob from "../../../assets/images/wallet/mob_card.png";

const LaunchpadSec = () => {
  return (
    <section className="sellSec">
      <Container className="commnCont">
        <Row>
          <Col md={6} left>
            {/* <ImageWrap main={mob} className="sellImg" /> */}
            <img src={mob} alt="" className="img-fluid" />
          </Col>
          <Col md={6} right>
            <h2 className="walletheading__title common-heading">
              Spend Anywhere, Anytime
            </h2>
            <div className="wallet__content">
              <p>
                Go beyond the traditional banking approach and explore the
                crypto-enabled financial system supported by easy-to-use crypto
                cards. Here are the top benefits:
              </p>
              <ul className="wallet__dotlist">
                <li className="wallet__dotitem">
                  Support to multiple cryptocurrencies
                </li>
                <li className="wallet__dotitem">Enhanced security</li>
                <li className="wallet__dotitem">Earn cashback</li>
                <li className="wallet__dotitem">Global accessibility</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LaunchpadSec;
