export const RPC_URL = process.env.REACT_APP_RPC_URL;
export const API_HOST = process.env.REACT_APP_API_HOST;
export const BINANCE_CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const rootName = process.env.REACT_APP_ROOTNAME;
export const ADMIN_NAME = process.env.REACT_APP_ADMIN_NAME;
export const LTC_TXID = process.env.REACT_APP_LTC_TXID;
export const DOGE_TXID = process.env.REACT_APP_DOGE_TXID;
export const LTC_ADDRESS = process.env.REACT_APP_LTC_ADDRESS;
export const DOGE_ADDRESS = process.env.REACT_APP_DOGE_ADDRESS;
export const ANTIS_ADDRESS = process.env.REACT_APP_ANTIS_ADDRESS;
export const BNB_TX_ADDRESS = process.env.REACT_APP_BNB_TXID;

// export const rootName = "";export const rootName = process.env.REACT_APP_ROOTNAME;
// export const RPC_URL = process.env.REACT_APP_RPC_URL;
// export const API_HOST = "https://ws-stage.antisrewards.com/api/api/v1"
export const CHAIN_ID = 56;
export const BNBTOUSDADDRESS = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
//export const API_URL = process.env.REACT_APP_API_URL;

export const APIURL = {
  SIGNUP: "/common/register",
  VERIFYEMAIL: "/common/verifymail",
  RESENDEMAIL: "/common/resendEmail",
  LOGIN: "/common/login",
  PROFILE: "/common/getProfile",
  TRANSACTION_DETAILS: "/common/getTransactionDetails",
  ADMINLOGIN: `admin/loginAdmin`,
  ADMIN_DASHBOARD_DATA: `admin/getDashboard`,
  ADMIN_TIER: `admin/tiersList`,
  ADMIN_REWARD: `admin/getRewards`,
  ADMIN_CLAIM_REWARD: `/admin/getClaimData`,
  ADMIN_USER_ORDER_LIST: `/admin/getUserOrderList`,
  ADMIN_TIER_SETTING: `/admin/tiersSettings`,
  ADMIN_TIER_DELETE: `/admin/tiersDeleteSettings`,
  ADMIN_USER_DATA: `/admin/userList`,
  ADMIN_USER_ORDER: `/admin/getTokensBoughtList`,
  ADMIN_STAKING_DATA: `/admin/getStakingData`,
  USER_CLAIM_REWARD: `/common/userDashboard`,
  LITECOIN_CLAIM_REWARD: `/common/claimReward`,
  UPDATE_PASS: `/admin/changePassword`,
  ADMIN_LOGOUT: `/admin/logOut`,
  CONTACTUS: `/common/contactUs`,
  LANDINGPAGEDETAILS: `/common/getLandingPageDetails`,
  SUBSCRIBE: `/common/Subscribe`,
};

export const RESPONSES = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NOCONTENT: 204,
  BADREQUEST: 400,
  UN_AUTHORIZED: 401,
  INVALID_REQ: 422,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  TIMEOUT: 408,
  TOOMANYREQ: 429,
  INTERNALSERVER: 500,
  BADGATEWAYS: 502,
  SERVICEUNAVILABLE: 503,
  GATEWAYTIMEOUT: 504,
};

// export const ECHAIN_CONTRACT_ADDRESS =
//   process.env.REACT_APP_ECHAIN_CONTRACT_ADDRESS
export const IFANCY_TOKEN_ADDRESS = process.env.REACT_APP_IFANCY_TOKEN_ADDRESS;
export const IFANCY_PUBLIC_ADDRESS =
  process.env.REACT_APP_IFANCY_PUBLIC_ADDRESS;

export const BUSD_ADDRESS = process.env.REACT_APP_IFANCY_USDT_ADDRESS;

export const blogUrl = "https://blogadmin.fancythattoken.io/"