import { EthereumProvider } from "@walletconnect/ethereum-provider";

let provider;

export async function initializeProvider() {
  provider = await EthereumProvider.init({
    projectId: "2d73296078c564e6fbdea297bb36306d",
    chains: [56],
    showQrModal: true,
  });

  return provider;
}
