import Web3 from "web3";
import { toast } from "../components/Toast/Toast";
import { provider } from "./wallectConnectProvider";

// /**
//  * @function: callWeb3
//  * @returns
//  */

const callWeb3 = async (type) => {
  try {
    const { BinanceChain, ethereum } = window;
    let web3Object;

    if (type === "BINANCE") {
      if (BinanceChain && (await BinanceChain.isConnected())) {
        web3Object = new Web3(BinanceChain);
      } else {
        toast.info("Please install Binance Chain extention");
      }
    } else if (type === "METAMASK") {
      if (ethereum && ethereum?.isMetaMask) {
        web3Object = new Web3(ethereum);
      } else {
        toast.info("Please install metamask extention");
      }
    } else if (ethereum) {
      web3Object = new Web3(ethereum);
    } else if (Web3) {
      web3Object = new Web3(window.web3.currentProvider);
    } else {
      web3Object = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
    }

    return web3Object;
  } catch (error) {
    return error;
  }
};

const getAccount = async (type) => {
  let types = type ? type : "WALLETCONNECT";
  const { BinanceChain, ethereum } = window;
  const result = Boolean((ethereum && ethereum.isMetaMask) || BinanceChain);
  let account;

  if (types == "BINANCE") {
    account = await BinanceChain.enable();
  } else if (!result && types == "TRUST_WALLET") {
    const web3 = await new Web3(window.web3.currentProvider);
    const accounts = await web3.eth.getAccounts();
    let account = accounts[0];
    return account;
  }
  //  else if (types == "WALLETCONNECT") {
  //   if (!provider.connected) {
  //     const wc_Connect = await provider
  //       .enable()
  //       .then((result) => {
  //         provider.on("accountsChanged", async (accounts) => {
  //           setTimeout(function () {
  //             window.location.reload();
  //           }, 5000);
  //           account = accounts[0];
  //           return account;
  //         });
  //       })
  //       .catch((err) => {
  //         window.location.reload();
  //       });
  //     // let _web3Instance = Web3(provider);
  //   }
  //   const accounts = await provider?.accounts[0];

  //   return accounts;
  // }
  else {
    await ethereum.on("accountsChanged", async function (accounts) {
      setTimeout(function () {
        // window.location.reload();
      }, 500);
      account = accounts[0];
    });
    account = await ethereum.request({ method: "eth_requestAccounts" });
  }

  return account[0];
};

export const Web3Service = {
  callWeb3,
  // CallContract,
  // isWalletInstalled,
  getAccount,
  // web3,
};
