import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import AdminSearch from "./AdminSearch";
import { _adminUserList } from "../../redux/_actions/apis.action";
import { _adminUserListSeach } from "../../redux/_actions/apis.action";
import { useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { CSVLink } from "react-csv";
import moment from "moment";
import UserOrder from "./UserOrder";

// const fields = [
//   { name: "S.No.", key: "id" },
//   { name: "Name", key: "name" },
//   { name: "Email", key: "email" },
//   { name: "Wallet address", key: "wallet_address" },
//   { name: "Created Date", key: "created_at" },
//   { name: "Status", key: "Status" },
// ];

const UserInfo = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [records, setRecords] = useState([]);
  const [csvRecord, setCsvRecord] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [usersData, setUsersData] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    userListGet();
  }, [page]);

  const userListGet = async () => {
    const datas = {
      page: page,
      limit: limit,
    };
    const userDetails = await dispatch(_adminUserList(datas));
    setCsvRecord(userDetails?.data?.usersListCsvx);
    setRecords(userDetails?.data);
    setUsersCount(userDetails?.data?.count);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const userListSearch = async (e) => {
    e.preventDefault();
    let data = {};
    let skip_value = e.target.value.length;
    if (skip_value) {
      data.search = e.target.value;
      const userDetails = await dispatch(_adminUserListSeach(data));
      setRecords(userDetails?.data);
      setUsersCount(userDetails?.data?.count);
    }
  };

  // const pageHandler = async (e, page) => {
  //   e.preventDefault();
  //   setCurrentPage(page);
  //   let data = {};
  //   const userDetails = await dispatch(_adminUserList(data));
  //   setRecords(userDetails?.data?.data);
  // };

  return (
    <Container fluid className="commonTop">
      <AdminSearch
        title="User List"
        total={records?.count}
        searchApi={userListSearch}
      />
      <CSVLink data={csvRecord}>
        <button className="btn theme-btn mb-3">Download CSV</button>
      </CSVLink>
      ;
      <div className="AdminCard mb-5">
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Wallet address</th>
              <th>Created Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {records && records?.count > 0 ? (
              records?.usersList?.map((items, key) => (
                <tr key={key}>
                  <td>{10 * (page - 1) + key + 1}</td>
                  <td>{items?.username}</td>
                  <td>{items?.email}</td>
                  <td>{items?.wallet_address}</td>
                  <td>
                    {moment(items?.created_at).format("DD-MM-YYYY hh:mm:ss")}
                  </td>
                  <td>{items?.status == 1 ? "Active" : "Inactive"}</td>
                  <td>
                    <Button
                      onClick={() => {
                        setUsersData(items);
                        setShow(true);
                      }}
                      className="theme-btn"
                    >
                      User Order Details
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colspan="6" style={{ textAlign: "center" }}>
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <UserOrder usersData={usersData} show={show} setShow={setShow} />
        {usersCount > 10 ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={10}
            totalItemsCount={450}
            pageRangeDisplayed={Math.ceil(usersCount / 10)}
            onChange={(e) => handlePageChange(e)}
          />
        ) : null}
      </div>
    </Container>
  );
};

export default UserInfo;
