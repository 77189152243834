import React from "react";
import "./index.css";
import bnbLogo from "../../assets/images/bnb-logo.svg";
import busdLogo from "../../assets/images/busd.svg";
const SmartContracts = (props) => {
  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };

  return (
    <section className="SmartContracts comnPadd">
      <div className="container">
        <ComnHeading
          beforeTxt="Smart Contracts"
          heading="Smart Contracts"
        />
        <div className="row">


          <div className="col-12 col-md-12 col-lg-3 my-5 text-center">
            <div className="d-flex justify-content-center">
              <img
                src={bnbLogo}
                width="100%"
                className="text-center mr-4"
                style={{ maxWidth: "120px", minWidth: "120px" }}
                alt="eht "
              />


              <img
                src={busdLogo}
                width="100%"
                className="text-center"
                style={{ maxWidth: "120px", minWidth: "120px" }}
                alt="eht "
              />
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-9 smart-contract-info">
            <div className="row">
              <div className="col-md-4"></div>
              <div className=" col-md-8">
                <p className="token-head text-center mb-2">
                  <span> fancythattoken BEP-20</span>
                </p>
              </div>

              <div className="col-md-4">
                <p className="left-heading">Smart Contract:</p>
              </div>
              <div className=" col-md-8">
                <p>
                  <a target={"_blank"} href="https://bscscan.com/address/0x59185ab959c4807d991587256c231b36f94831cd">
                  0x59185ab959c4807d991587256c231b36f94831cd
                    <i className="fa fa-external-link"></i>
                  </a>
                </p>
              </div>
              <div className="col-md-4">
                <p className="left-heading">Crypto Supported:</p>
              </div>
              <div className=" col-md-8">
                <p>BNB, BUSD</p>
              </div>
              <div className="col-md-4">
                <p className="left-heading">Decimals:</p>
              </div>
              <div className=" col-md-8">
                <p>6</p>
              </div>
            </div>

          </div>

          {/* <div className="col-12 col-md-3 my-5 text-center">
            <img
              src={busdLogo}
              width="100%"
              className="text-center"
              style={{ maxWidth: "120px" }}
              alt="eht "
            />
          </div>

          <div className="col-12 col-md-9">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <p className="token-head text-center mb-2">
                  <span> fancythattoken BEP-20</span>
                </p>
              </div>

              <div className="col-md-4">
                <p className="left-heading">Smart Contract:</p>
              </div>
              <div className=" col-md-8">
                <p>
                  TBA
                </p>
              </div>
              <div className="col-md-4">
                <p className="left-heading">Token Symbol:</p>
              </div>
              <div className=" col-md-8">
                <p>BUSD </p>
              </div>
              <div className="col-md-4">
                <p className="left-heading">Decimals:</p>
              </div>
              <div className=" col-md-8">
                <p>18</p>
              </div>
            </div>

          </div> */}

        </div>
      </div>
    </section>
  );
};

export default SmartContracts;
