//     XIV - BSC = 0x00518f36d2E0E514E8EB94D34124fC18eE756f10
// XIV - MATIC = 0xde799636aF0d8D65a17AAa83b66cBBE9B185EB01
// XIV - ETH = 0x44f262622248027f8e2a8fb1090c4cf85072392c

export const rinkeby = {};
rinkeby.chainName = "Rinkeby Test Network";
rinkeby.rpcUrl =
  "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
rinkeby.chainId = "0x4";
rinkeby.currenyName = "ETH";
rinkeby.currencySymbol = "ETH";
rinkeby.blockUrl = "https://rinkeby.etherscan.io";
rinkeby.currencyDecimal = 18;

export const binance = {};
binance.chainName = "Binance Test Network";
binance.rpcUrl = "https://bsc-dataseed.binance.org/";
binance.chainId = "0x38";
binance.currenyName = "BNB";
binance.currencySymbol = "BNB";
binance.blockUrl = "https://bscscan.com/";
rinkeby.currencyDecimal = 18;

// export const rinkeby = {}
// rinkeby.chainName = 'Ethereum Mainnet'
// rinkeby.rpcUrl = 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
// rinkeby.chainId = '0x1'
// rinkeby.currenyName = "ETH"
// rinkeby.currencySymbol = 'ETH'
// rinkeby.blockUrl = 'https://etherscan.io'
// rinkeby.currencyDecimal = 18;

// export const binance = {}
// binance.chainName = 'Binance Mainnet'
// binance.rpcUrl = 'https://bsc-dataseed.binance.org/'
// binance.chainId = '0x38'
// binance.currenyName = "BNB"
// binance.currencySymbol = 'BNB'
// binance.blockUrl = 'https://bscscan.com/'
// rinkeby.currencyDecimal = 18;
//mainnet

// export const rinkeby = {}
// rinkeby.chainName = 'Ethereum Mainnet'
// rinkeby.rpcUrl = 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
// rinkeby.chainId = '0x1'
// rinkeby.currenyName = "ETH"
// rinkeby.currencySymbol = 'ETH'
// rinkeby.blockUrl = 'https://etherscan.io'
// rinkeby.currencyDecimal = 18;

// export const binance = {}
// binance.chainName = 'Binance Mainnet'
// binance.rpcUrl = 'https://bsc-dataseed.binance.org/'
// binance.chainId = '0x38'
// binance.currenyName = "BNB"
// binance.currencySymbol = 'BNB'
// binance.blockUrl = 'https://bscscan.com/'
// binance.currencyDecimal = 18;
