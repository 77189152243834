import Web3 from "web3";
import commonABI from "../assets/ABI/ifancyTokenABI.json";
import ifancy_publicABI from "../assets/ABI/ifancyPublicABI.json";
import { BINANCE_CHAIN_ID } from "../constant";
import { binance } from "./CustomServiceJson";
import { initializeProvider } from "./wallectConnectProvider";
import { Alert } from "react-bootstrap";
import { stopLoader } from "../redux/_actions/loader";

let web3Instance;
const { ethereum, BinanceChain } = window;
let provider;

const callWeb3 = () => {
  return new Promise(async (resolve, reject) => {
    if (!web3Instance) {
      if ((ethereum && ethereum.isMetaMask) || BinanceChain) {
        web3Instance = new Web3(ethereum);
      } else if (window.web3) {
        web3Instance = new Web3(window.ethereum);
      }
    }
    resolve(web3Instance);
  });
};

const callGlobalFunction = async () => {
  // let { ethereum } = window;

  // if (type === "WALLETCONNECT") {
  //   ethereum = provider;
  // }
  ethereum.on("networkChanged", (response) => {
    if (response === BINANCE_CHAIN_ID) {
      // localStorage.setItem("activeChain", response);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      return (
        alert("please select appropriate network"), window.location.reload()
      );
    }
  });
  let web3 = await callWeb3();
  return true;
};
callGlobalFunction();

const commonAbiInstances = async (wallet, address) => {
  wallet = wallet?.toUpperCase();
  if (wallet === "METAMASK") {
    let _web3Instance = new Web3(window.ethereum);
    let Instance = new _web3Instance.eth.Contract(commonABI, address);
    console.log("check------", Instance);
    return Instance;
  } else if (wallet === "BINANCE") {
    let _web3Instance = new Web3(window.BinanceChain);
    let Instance = new _web3Instance.eth.Contract(commonABI, address);
    return Instance;
  } else if (wallet === "WALLETCONNECT") {
    if (!provider) {
      provider = await initializeProvider();
    }
    await provider.enable();
    let _web3Instance = new Web3(provider);
    let Instance = new _web3Instance.eth.Contract(commonABI, address);
    return Instance;
  } else {
    let _web3Instance = new Web3(binance?.rpcUrl);
    let Instance = new _web3Instance.eth.Contract(commonABI, address);
    return Instance;
  }
};

const ifancy_instance_abi = async (wallet, address) => {
  wallet = wallet?.toUpperCase();
  if (wallet === "METAMASK") {
    let _web3Instance = new Web3(window.ethereum);
    let Instance = new _web3Instance.eth.Contract(ifancy_publicABI, address);
    return Instance;
  } else if (wallet === "BINANCE") {
    let _web3Instance = new Web3(window.BinanceChain);
    let Instance = new _web3Instance.eth.Contract(ifancy_publicABI, address);
    return Instance;
  } else if (wallet == "WALLETCONNECT") {
    if (!provider) {
      provider = await initializeProvider();
    }
    // await provider.enable();
    let _web3Instance = new Web3(provider);
    let Instance = new _web3Instance.eth.Contract(ifancy_publicABI, address);
    // alert("eth84" + JSON.stringify(provider) + JSON.stringify(Instance));
    return Instance;
  } else {
    let _web3Instance = new Web3(binance?.rpcUrl);
    let Instance = new _web3Instance.eth.Contract(ifancy_publicABI, address);
    return Instance;
  }
};

const callCommonABIMethod = async (method, data, values, _ABI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract;
      if (_ABI) {
        switch (_ABI) {
          case "Common":
            contract = await commonAbiInstances(
              values?.wallet,
              values?.address
            );
            break;
          case "ifancy_finance":
            contract = await ifancy_instance_abi(
              values?.wallet,
              values?.address
            );
            break;
          default:
        }
      }

      if (contract.methods) {
        let _parmsLength = data?.length;
        if (_parmsLength) {
          switch (_parmsLength) {
            case 1:
              contract.methods[method](data[0])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 2:
              contract.methods[method](data[0], data[1])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 3:
              contract.methods[method](data[0], data[1], data[2])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 4:
              contract.methods[method](data[0], data[1], data[2], data[3])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 5:
              contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4]
              )
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            default:
              contract.methods[method](data[0])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
          }
        } else {
          if (contract) {
            contract.methods[method]()
              .call()
              .then((result) => {
                resolve(result);
              })
              .catch((error) => {
                reject(error);
              });
          }
        }
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const common_send_method = async (method, data, values, _ABI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract;
      if (_ABI) {
        switch (_ABI) {
          case "Common":
            contract = await commonAbiInstances(
              values?.wallet,
              values?.address
            );

            break;

          case "ifancy_finance":
            contract = await ifancy_instance_abi(
              values?.wallet,
              values?.address
            );
            break;
          default:
        }
      }
      if (contract.methods) {
        let _parmsLength = data.length;

        let gasLimit;
        if (_parmsLength) {
          switch (_parmsLength) {
            case 1:
              gasLimit = await contract.methods[method](data[0]).estimateGas({
                from: values.to,
                value: values?.value,
              });

              contract.methods[method](data[0])
                .send({ from: values.to, value: values?.value, gas: gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 2:
              gasLimit = await contract.methods[method](
                data[0],
                data[1]
              ).estimateGas({
                from: values.to,
                value: values?.value,
              });

              contract.methods[method](data[0], data[1])
                .send({ from: values.to, value: values?.value, gas: gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 3:
              gasLimit = await contract.methods[method](
                data[0],
                data[1],
                data[2]
              ).estimateGas({
                from: values.to,
                value: values?.value,
              });

              contract.methods[method](data[0], data[1], data[2])
                .send({ from: values.to, value: values?.value, gas: gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 4:
              gasLimit = await contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3]
              ).estimateGas({
                from: values.to,
              });
              contract.methods[method](data[0], data[1], data[2], data[3])
                .send({ from: values.to, gas: gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 5:
              gasLimit = await contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4]
              ).estimateGas({
                from: values.walletAddress,
                value: values?.value,
              });
              contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4]
              )
                .send({ from: values.to, value: values?.value, gas: gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 6:
              contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4],
                data[5]
              )
                .send({ from: values.to, value: values?.value })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            default:
              contract.methods[method](data[0])
                .send({ from: values.to, value: values?.value })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
          }
        } else {
          gasLimit = await contract.methods[method]().estimateGas({
            from: values.to,
            value: values?.value,
          });
          contract.methods[method]()
            .send({ from: values.to, value: values?.value, gas: gasLimit })
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const EthereumService = {
  callGlobalFunction,
  common_send_method,
  callCommonABIMethod,
};
