import React, { useState, useEffect } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import ReactPaginate from 'react-paginate';
import Loader from "../../components/Loader/Loader"
import Form from 'react-bootstrap/Form';
import { blogUrl } from "../../constant"

import blogBanner from "../../assets/images/blog-banner.png"

import "./index.css";

const Blog = (props) => {

    const [loading, setLoading] = useState(true);
    const [blogdata, setblogdata] = useState([])
    const [alldata, setalldata] = useState([])

    const [catagiorylist, setcatagiorylist] = useState([])
    const [selectedcatagiory, setSelectedcatagiory] = useState();
    const [searchvalue, setsearchvalue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);



    const loadblogdata = async () => {
        let catagiorydata;
        const res = await fetch(blogUrl + "/wp-json/wp/v2/posts", {
            method: "GET",
        })
        const data = await res.json();
        if (data) {
            let upatedata = await Promise.all(data.map(async (key) => {
                catagiorydata = await getCategories(key.id)
                if (catagiorydata) {
                    key = { ...key, catagiory: catagiorydata }
                    return (key)
                }
            }))
            if (upatedata) {
                setblogdata(upatedata)
                setalldata(upatedata)
                setLoading(false)
            }

        }
    }

    const loadCategories = async () => {
        let item = []
        const res = await fetch(blogUrl + "/wp-json/wp/v2/categories", {
            method: "GET",
        })
        const data = await res.json();
        if (data) {
            data.map((key) => {
                item.push(key.name)
            })
            setcatagiorylist(item)
        }


    }
    useEffect(() => {
        loadblogdata();
        loadCategories();
    }, []);
    const ComnHeading = (props) => {
        return (
            <div className="comnHeading">
                <span className="beforeTxt_Style">{props.beforeTxt}</span>
                <h2>{props.heading}</h2>
                <p>{props.subTxt}</p>
            </div>
        );
    };

    const getCategories = async (id) => {
        let list = []
        const res = await fetch(`${blogUrl}/wp-json/wp/v2/categories?post=` + id, {
            method: "GET",
        })
        const data = await res.json();
        if (data) {
            data.map((key) => {
                list.push(key.name)
            })
            return (list)
        }

    }

    const decodeHtmlCharCodes = (str) => {
        return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
        });
    }

    const handleSearch = (e) => {
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        setsearchvalue(e.target.value)
        let value = e.target.value.toLowerCase();
        let result = [];
        if (value) {
            result = alldata.filter((data) => {
                return data.title.rendered.toLowerCase().search(value) != -1;

            });
            setblogdata(result)
        }
        else {
            setblogdata(alldata)
        }

    }
    const handleSelectedcatagiory = (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        let value = data
        setSelectedcatagiory(value)
        let result = [];
        if (value) {
            result = alldata.filter((data) => {
                return data.catagiory.indexOf(value) != -1;

            });
            setblogdata(result)
        }
        else {
            setblogdata(alldata)
        }
    }


    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const PageCount = Math.ceil(blogdata.length / PER_PAGE);



    const currentPageData = blogdata
        .slice(offset, offset + PER_PAGE)
        .map((data, index) => {
            return (
                (index === 0 ?
                    <>
                        <ScrollToTop />
                        <div className="col-12 col-md-8">
                            <a href={"blog/" + data.slug}>
                                <div className="card">
                                    <span className="bage-tag">#{data.catagiory.toString()}</span>
                                    <img className="main-img" src={data.jetpack_featured_media_url ? data.jetpack_featured_media_url : blogBanner}></img>
                                    <div className="blogCardText">
                                        <h3>{decodeHtmlCharCodes(data.title.rendered)}</h3>
                                        <h6>{data.modified.split("T")[0]}</h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                        {/* <div className="col-12 col-md-4">
                            <a className="mb-3" onClick={e => handleSelectedcatagiory("web3")}>
                                <div className="card small-card mt-md-0 mt-4">
                                    <span className="bage-warning">#Web3</span>
                                    <h5>Defi comes to XRP  Dogecoin</h5>
                                </div>
                            </a>
                            <a className="mb-3" onClick={e => handleSelectedcatagiory("Uncategorized")}>
                                <div className="card small-card">
                                    <span className="bage-warning">#Uncategorized</span>
                                    <h5>Defi comes to XRP  Dogecoin</h5>
                                </div>
                            </a>
                        </div> */}
                    </>
                    : null
                )
            )
        })

    function handlePageClick({ selected: selectedPage }) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setCurrentPage(selectedPage);
    };

    const secondPageData = blogdata
        .slice(offset, offset + PER_PAGE)
        .map((data, index) => {
            return (
                (index !== 0 ?
                    <>
                        <div className="col-12 col-md-6 col-lg-4">
                            <a href={"blog/" + data.slug}>
                                <div className="card">
                                    <span className="bage-tag">#{data.catagiory.toString()}</span>
                                    <img className="sm-img" src={data.jetpack_featured_media_url ? data.jetpack_featured_media_url : blogBanner}></img>
                                    <div className="card-info">
                                        <h2>{decodeHtmlCharCodes(data.title.rendered)}</h2>
                                        <h6>{data.modified.split("T")[0]}</h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </>
                    : null
                )
            )
        })

    const searchedPageData = blogdata
        .slice(offset, offset + PER_PAGE)
        .map((data, index) => {
            return (
                <>
                    <div className="col-12 col-md-6 col-lg-4">
                        <a href={"blog/" + data.slug}>
                            <div className="card">
                                <span className="bage-tag">#{data.catagiory.toString()}</span>
                                <img className="sm-img" src={data.jetpack_featured_media_url ? data.jetpack_featured_media_url : blogBanner}></img>
                                <div className="card-info">
                                    <h2>{decodeHtmlCharCodes(data.title.rendered)}</h2>
                                    <h6>{data.modified.split("T")[0]}</h6>
                                </div>
                            </div>
                        </a>
                    </div>
                </>
            )
        })


    return (
        <>
            {loading ? <Loader /> :

                <section className="blog">
                    <div className="container">
                        <ComnHeading beforeTxt="Blog" heading="Blog" />
                        {searchvalue || selectedcatagiory ? <>
                            <ScrollToTop />
                            <div className="row my-5">
                                <div className="col-12 col-md-8">
                                    <div className="catagiory">
                                        <div class="form-group selct-drop">
                                            <Form.Select className="form-control" aria-label="Default select example"
                                                value={selectedcatagiory} onChange={e => handleSelectedcatagiory(e.target.value)}>
                                                <option value="">All Categories</option>
                                                {catagiorylist.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data}>{data}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div class="form-group search-field">
                                        <input type="text" class="form-control" id="" placeholder="Search" value={searchvalue} onChange={e => handleSearch(e)} />
                                        <button className="search-btn"><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                            {blogdata.length !== 0 ?
                                <>
                                    <div className="row">
                                        {searchedPageData}
                                    </div>
                                    <div className="d-flex">
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={PageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                        />
                                    </div>
                                </> : <h1 className="text-center">Data Not Found</h1>}
                        </> :
                            (blogdata.length !== 0 ?
                                <>
                                    <div className="row">
                                        {currentPageData}
                                    </div>
                                    <div className="row my-5">
                                        <div className="col-12 col-md-8">
                                            <div className="catagiory">
                                                <div class="form-group selct-drop">
                                                    <Form.Select className="form-control" aria-label="Default select example"
                                                        value={selectedcatagiory} onChange={e => handleSelectedcatagiory(e.target.value)}>
                                                        <option value="">All Categories</option>
                                                        {catagiorylist.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data}>{data}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div class="form-group search-field">
                                                <input type="text" class="form-control" id="" placeholder="Search" value={searchvalue} onChange={e => handleSearch(e)} />
                                                <button className="search-btn"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {secondPageData}
                                    </div>

                                    <div className="d-flex">
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={PageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                        />
                                    </div>
                                </> : <h1 className="text-center">Data Not Found</h1>)
                        }

                        <div className="leran-now">

                            <div className="big-card">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-4">
                                        <img className="w-100" src="https://app.learncrypto.com/file-manager-cache/orig/ad5/c38/54f/ad5c3854f078aac334ac8273a470112a/Basic.webp"></img>
                                    </div>
                                    <div className="col-12 col-md-8 text-center">
                                        <h3>Learn Crypto Basics</h3>
                                        <p>We cover everything from what cryptocurrency is, to why it has value.</p>
                                        <button className="btn theme-btn mt-3">View More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-md-8">
                                <h2 className="text-white font-weight-bold mb-4">Learning About Crypto Never Stops</h2>
                                <p className="text-white">Things move fast in the world of crypto. There is an ever expanding ecosystem of applications and technologies building on the revolutionary idea of blockchain. Established financial institutions might be starting to take Bitcoin seriously but we are only just starting to see the potential in areas DEFI, NFTs and the Internet of Things. So learning about crypto is a constantly moving target and the Learn Crypto Blog is the best place to keep up to date. We share the latest big news stories, and interpret the latest developments in crypto so that you can understand how they might impact your life.</p>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default Blog;
