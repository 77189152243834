import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Fancy1 from '../../../assets/images/wallet/crypto-hero.png'
function CryptoHero() {
    return (
        <>
            <Container className="container-inner crypto-hero">

                <Row className="align-items-center minHeight">
                    <Col md={5} className="left">
                        <div className="left">
                            <h2 className="walletheading__title common-heading">
                                <span className="walletheading__subText d-block upper">
                                    Welcome to the
                                </span>
                                fancy<span className='thinner'>that</span>token  <span className="walletheading__subText d-inline-block bottom"> Card </span> 
                            </h2>
                            <p className="my-4 pb-2">A crypto card that can revolutionize the ways of spending cryptocurrencies.</p>
                            {/* <CommonBtn title="Get Started" to="#" /> */}
                        </div>
                    </Col>
                    <Col md={7} className="right">
                        <div className="right">
                            <img src={Fancy1} alt="" />
                        </div>
                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default CryptoHero