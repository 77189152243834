import moment from "moment";
import { APIURL, rootName, RESPONSES } from "../constant";
// import { RESPONSES } from "../constant";
import { toast } from "../components/Toast/Toast";
import { apiCallDelete } from "./apiService";
import { useDispatch, useSelector } from "react-redux";
import { admin_log_out } from "../redux/_actions/apis.action";

const unAuthMessages = [
  "invalid signature",
  "Unauthorize Request",
  "Permission has been denied for this user.",
  "jwt malformed",
];

export const handleTokenExpiry = (error) => {
  const s = error?.response?.status;
  const errorMessage = error?.response?.data.message;

  if (
    s === 400 &&
    errorMessage == "Unauthorize Request" &&
    (error?.response?.config.url?.split("/")[1] == "common" ||
      error?.response?.config.url == "/common/userDashboard")
  ) {
    toast.error("Login session expired");
    setTimeout(() => {
      logout();
    }, 500);
  }
  if (
    s === 400 &&
    errorMessage == "Unauthorize Request" &&
    (error?.response?.config.url?.split("/")[1] == "admin" ||
      error?.response?.config.url == "admin/getDashboard" ||
      error?.response?.config.url?.split("?")[0] ==
        "/admin/getTokensBoughtList" ||
      error?.response?.config.url?.split("?")[0] == "/admin/userList" ||
      error?.response?.config.url?.split("?")[0] == "/admin/changePassword")
  ) {
    toast.error("Login session expired");
    setTimeout(() => {
      Adminlogout();
    }, 500);
  }
};
// export const log_out_func = async () => {
//   const authStatus = useSelector((state) => state?.user?.AdminLogin?.token);
//   const dispatch = useDispatch();
//   const result = await dispatch(admin_log_out({ token: authStatus }));
//   return result;
// };

export const logout = async () => {
  localStorage.clear();
  setTimeout(() => {
    window.location.replace("/");
  }, 2000);
};
export const Adminlogout = async () => {
  localStorage.clear();
  setTimeout(() => {
    window.location.replace("/admin/adminLogin");
  }, 2000);
};

export const getCookie = (c_name) => {
  let i;
  let x;
  let y;
  const ARRcookies = document.cookie.split(";");
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x === c_name) {
      return decodeURI(y);
    }
  }
};

const isLoggedIn = () => {
  const csrfToken = localStorage.getItem("csrfToken");
  if (csrfToken) {
    return csrfToken;
  }
};

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
export function clearUserCookie(cname, cvalue, exMins) {
  const d = new Date();
  d.setTime(d.getTime() + exMins * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}
export const setToken = (c_name, value, exdays) => {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  var c_value =
    escape(value) + (exdays == null ? "" : "; expires=" + exdate.toUTCString());
  document.cookie = c_name + "=" + c_value + "; path=/";
};

export function getFormattedDate(d) {
  const date = d;
  if (date) {
    let result = moment(date).format("MM-DD-YYYY HH:mm:ss");
    return result;
  }
}

export const UserService = {
  isLoggedIn,
  clearUserCookie,
  getFormattedDate,
};
