import React from "react";
import Search from "../../assets/images/search.png";
import { _adminUserListSeach } from "../../redux/_actions/apis.action";

const AdminSearch = (props) => {
  return (
    <div className="topinfo">
      <h3 className="title">{props.title} </h3>
      {/* <p>Total Users: {props.total}</p> */}
      <div className="searchBox">
        <input type="text" placeholder="Search.." onChange={props.searchApi} />
        <img className="searchImg" src={Search} alt="" />
      </div>
    </div>
  );
};

export default AdminSearch;
