import axios from "axios";

import { storeInstance } from "../App";
import { toast } from "../components/common/Toast";
import { APIURL, API_HOST } from "../constant";
import { ADMINLOGIN } from "../redux/constant.actionTypes";
import { setLoader, stopLoader } from "../redux/_actions/loader";
import { handleTokenExpiry } from "./UserService";

axios.defaults.baseURL = API_HOST;

let token;
axios.interceptors.request.use(
  (config) => {
    let path = window.location.pathname;
    if (
      path == "/login" ||
      path == "/dashboard" ||
      path == "/my-profile" ||
      path == "/transaction-history" ||
      path == "/referral-code"
    ) {
      token = storeInstance.getState().user?.login?.data?.token
        ? storeInstance.getState().user?.login?.data?.token
        : storeInstance.getState().user?.login?.token
        ? storeInstance.getState().user?.login?.token
        : storeInstance.getState().user?.signUp?.data?.token;
    } else if ("/AdminDashboard") {
      token = storeInstance.getState()?.user?.AdminLogin?.token;
    }

    config.headers["api-access-token"] = token;
    // config.headers["Content-Type"] = "application/json";
    //localStorage.getItem("csrfToken");

    return config;
  },
  (error) => {
    storeInstance.dispatch(stopLoader(false));
    return error;
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    storeInstance.dispatch(stopLoader(false));

    return response;
  },
  (error) => {
    if (!error?.status || error?.status == 400) {
      // window.localStorage.clear()
      //  toast.error("Please login First");
    }
    storeInstance.dispatch(stopLoader(false));
    //  if (process.env.NODE_ENV !== "development") {
    handleTokenExpiry(error);
    // }
    handleError(error);
    return error.response;
  }
);

function formatUrl(url, params) {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : "";
  return `${url}${params}`;
}

async function handleError(error) {
  error?.response?.data?.message?.error &&
    toast.error(error?.response?.data?.message?.error);
  error?.response?.data?.message && toast.error(error.response.data.message);
  error?.response?.data?.errors[0]?.message &&
    toast.error(error?.response?.data?.errors[0]?.message);
}

function handleSuccess(res) {
  if (
    (res?.status === 200 || res?.status === 201) &&
    res?.data?.message !== "Data fetched successfully"
  )
    res?.data?.message && toast.success(res.data.message);
  else {
    return res?.data?.message;
  }
}

export const apiCallPost = (
  url,
  data,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve, reject) => {
    showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .post(formatUrl(url, params), data)
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res);
        // resolve(res.data);
      })
      .catch((error) => {
        reject(error?.data?.message?.error);
      });
  });

export const apiCallGet = (
  url,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve, reject) => {
    showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .get(formatUrl(url, params))
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        reject(error?.data?.message?.error);
      });
  });

export const apiCallGet_header = (
  url,
  params = {},
  headers,
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve, reject) => {
    showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .get(formatUrl(url, params), {
        headers: {
          //"api-access-token" : token
        },
      })
      .then((res) => {
        if (!res == "Data fetched successfully") {
          showToast && handleSuccess(res);
        }
        resolve(res.data);
      })
      .catch((error) => {
        showLoader && storeInstance.dispatch(setLoader(false));
        reject(error);
      });
  });
export const apiCallPost_header = (
  url,
  data,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve, reject) => {
    showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .get(formatUrl(url, params), data, {
        headers: {
          //"api-access-token" : token
        },
      })
      .then((res) => {
        if (!res == "Data fetched successfully") {
          showToast && handleSuccess(res);
        }
        resolve(res.data);
      })
      .catch((error) => {
        showLoader && storeInstance.dispatch(setLoader(false));
        reject(error);
      });
  });

export const admin_login = async (data) => {
  let details = await axios
    .post(APIURL.ADMINLOGIN, data)
    .then(function (response) {
      return response?.data;
    })
    .catch(function (error) {
      return error;
    });
  return details;
};

export const token_expiration = async (data) => {
  let token_expire = storeInstance.getState().user?.token_expiration;
  let user_token_expire = storeInstance.getState().user?.user_token_expiration;
  const path = window.location.pathname.split("/");

  if (path[1] == "/dashboard") {
    if (user_token_expire < Date.now()) {
      return "usertokenexpire";
    } else if (user_token_expire > Date.now()) {
      return false;
    }
  } else {
    if (parseFloat(token_expire) < Date.now()) {
      return "admintokenexpire";
    } else if (token_expire > Date.now()) {
      return false;
    }
  }
};

export const apiCallPut = (
  url,
  data,
  params = {},
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve, reject) => {
    showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .put(formatUrl(url, params), data)
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        showLoader && storeInstance.dispatch(setLoader(false));
        reject(error);
      });
  });

// export const apiCallPatch = (
//   url,
//   data,
//   params = {},
//   showToast = false,
//   showLoader = true
// ) =>
//   new Promise((resolve) => {
//     showLoader && storeInstance.dispatch(setLoader(true));
//     axios
//       .patch(formatUrl(url, params), data)
//       .then((res) => {
//         showToast && handleSuccess(res);
//         resolve(res.data);
//       })
//       .catch((error) => {
//         resolve(null);
//       });
//   });

export const apiCallDelete = (
  url,
  data,
  params,
  showToast = false,
  showLoader = true
) =>
  new Promise((resolve) => {
    showLoader && storeInstance.dispatch(setLoader(true));
    axios
      .delete(formatUrl(url, params), data)
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res.data);
      })
      .catch((error) => {
        showLoader && storeInstance.dispatch(setLoader(false));
        resolve(null);
      });
  });
