import {
  CHAIN_ID,
  CHAIN_NAME,
  BLOCK_EXPLORE_URL,
  ICON_URL,
  CURRENCY_DECIMAL,
  CURRENCY_NAME,
  CURRENCY_SYMBOL,
  BINANCE_CHAIN_ID,
  RINKEBY_CHAINID,
} from "../constant";
import { rinkeby, binance } from "./CustomServiceJson";

export const switchNetwork = async (network_chainId, type) => {
  if (localStorage.getItem("type") == "rinkeby") {
    return new Promise(async (resolve, reject) => {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: rinkeby?.chainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const result = await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: rinkeby.chainId, // A 0x-prefixed hexadecimal string
                  chainName: rinkeby?.chainName,
                  rpcUrls: [rinkeby?.rpcUrl],
                  blockExplorerUrls: [rinkeby?.blockUrl],
                  nativeCurrency: {
                    name: rinkeby.currenyName,
                    symbol: rinkeby.currencySymbol, // 2-6 characters long
                    decimals: 18,
                  },
                },
              ],
            });

            resolve(true);
          } catch (addError) {
            reject(addError);
          }
        }

        reject(switchError);
      }
    });
  } else {
    return new Promise(async (resolve, reject) => {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: binance.chainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const result = await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: binance.chainId, // A 0x-prefixed hexadecimal string
                  chainName: binance?.chainName,
                  rpcUrls: [binance?.rpcUrl],
                  blockExplorerUrls: [binance?.blockUrl],
                  nativeCurrency: {
                    name: binance.currenyName,
                    symbol: binance.currencySymbol, // 2-6 characters long
                    decimals: 18,
                  },
                },
              ],
            });

            resolve(true);
          } catch (addError) {
            reject(addError);
          }
        }

        reject(switchError);
      }
    });
  }
};
