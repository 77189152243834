import React from 'react'
import loadImage from '../../assets/images/loading.gif'

// const LoaderContainer = styled.div`

// display: flex;
// justify-content: center;
// align-items: center;
// // height: 200px;

// `;


const Loader = () => {
    return (
        <div className='Loader'>
            <img src={loadImage} />
        </div>
    )
}

export default Loader
