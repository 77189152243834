import react from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./assets/css/bootstrap.min.css";
import WhitePaper from "./pages/whitepaper";
import HowToBuy from "./pages/howtobuy";
import TokenOmics from "./pages/tokenomics";
import PitchDeck from "./pages/pitchdeck";
import Roadmap from "./pages/roadmap";
import VestingSchedule from "./pages/vestingschedule";
import SmartContracts from "./pages/smartcontracts";
import ContactUs from "./pages/contactus";
import CryptoCards from "./pages/CryptoCards";
import MultiProductsPage from "./pages/MultiProducts";

import Register from "./pages/register";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import TransactionHistory from "./pages/transactionhistory";
import MyProfile from "./pages/myprofile";
import SideBarPageLayout from "./components/SideBarPageLayout";
import PageLayout from "./components/PageLayout";
import NewHome from "./pages/home-new";

import PrivacyPolicy from "./pages/footerPages/PrivacyPolicy";
import TermsAndConditions from "./pages/footerPages/TermsAndConditions";
import Cookies from "./pages/footerPages/Cookies";
import Disclaimer from "./pages/footerPages/Disclaimer";
import WhitePaperFooter from "./pages/footerPages/WhitePaperFooter";
import blog from "./pages/blog/blog";
import blogDetailPage from "./pages/blog/detailPage";


import AdminLayout from "./components/AdminLayout/AdminLayout";
import AdminLogin from "./pages/Admin/AdminLogin";
import Changepassword from "./pages/Admin/Changepassword";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Transactions from "./pages/Admin/Transactions";
import Order from "./pages/Admin/Order";
import UserInfo from "./pages/Admin/UserInfo";
import { Provider, useDispatch, useSelector } from "react-redux";
import configureStore from "./redux/_store/configureStore";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ADMIN_NAME } from "./constant";
import NoGuard from "./Guard/NoGuard ";
import AuthGuard from "./Guard/AuthGuard ";
import NoAuth from "./Guard/NoAuth";
import AdminAuthGuard from "./Guard/AdminAuthGuard";
import Verifyemail from "./pages/verifyemail/VerifyEmail";
import LoaderComponent from "./components/LoaderComponent/LoaderComponent";
import ReferralCode from "./pages/ReferralCode"

const { store, persistor } = configureStore();

function App() {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <div className="App">
          <Provider store={store}>
            <LoaderComponent></LoaderComponent>
            <Routes>
              <Route
                exact
                path="/whitepaper"
                element={<PageLayout Component={WhitePaper} />}
              />
              <Route
                exact
                path="/pitch-deck"
                element={<PageLayout Component={PitchDeck} />}
              />
              <Route
                exact
                path="/roadmap"
                element={<PageLayout Component={Roadmap} />}
              />
              <Route
                exact
                path="/vesting-schedule"
                element={<PageLayout Component={VestingSchedule} />}
              />
              <Route
                exact
                path="/smart-contracts"
                element={<PageLayout Component={SmartContracts} />}
              />
              <Route
                exact
                path="/contact-us"
                element={<PageLayout Component={ContactUs} />}
              />
              <Route
                exact
                path="/tokenomics"
                element={<PageLayout Component={TokenOmics} />}
              />
              <Route
                exact
                path="/how-to-buy"
                element={<PageLayout Component={HowToBuy} />}
              />
              <Route
                exact
                path="/cards"
                element={<PageLayout Component={CryptoCards} />}
              />
              <Route
                exact
                path="/apps"
                element={<PageLayout Component={MultiProductsPage} />}
              />
              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              <Route exact path="/" element={<NewHome />} />

              <Route path={`/`} element={<NoAuth />}>
                <Route
                  exact
                  path="/login"
                  element={<PageLayout Component={Login} />}
                />
                <Route
                  exact
                  path="/register"
                  element={<PageLayout Component={Register} />}
                />
                <Route
                  exact
                  path="/verifymail/:id"
                  element={<PageLayout Component={Verifyemail} />}
                />
              </Route>
              {/* footer pages */}
              <Route
                exact
                path="/privacy-policy"
                element={<PageLayout Component={PrivacyPolicy} />}
              />
              <Route
                exact
                path="/terms-and-conditions"
                element={<PageLayout Component={TermsAndConditions} />}
              />
              <Route
                exact
                path="/cookies"
                element={<PageLayout Component={Cookies} />}
              />
              <Route
                exact
                path="/disclaimer"
                element={<PageLayout Component={Disclaimer} />}
              />
              <Route
                exact
                path="/white-paper-footer"
                element={<PageLayout Component={WhitePaperFooter} />}
              />

              <Route
                exact
                path="/blog"
                element={<PageLayout Component={blog} />}
              />

              <Route
                exact
                path="/blog/:name"
                element={<PageLayout Component={blogDetailPage} />}
              />

              {/* authguard for user  after login */}
              <Route path={`/`} element={<AuthGuard />}>
                <Route
                  exact
                  path="/dashboard"
                  element={<SideBarPageLayout Component={Dashboard} />}
                />
                <Route
                  exact
                  path="/my-profile"
                  element={<SideBarPageLayout Component={MyProfile} />}
                />

                <Route
                  exact
                  path="/transaction-history"
                  element={<SideBarPageLayout Component={TransactionHistory} />}
                />
                <Route
                  exact
                  path="/referral-code"
                  element={<SideBarPageLayout Component={ReferralCode} />}
                />
              </Route>
              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

              <Route
                path={`/${ADMIN_NAME}/adminLogin`}
                element={<AdminLogin />}
              />
              <Route path={`/${ADMIN_NAME}`} element={<AdminAuthGuard />}>
                <Route
                  path={`/${ADMIN_NAME}/AdminDashboard`}
                  element={<AdminLayout Component={AdminDashboard} />}
                />
                <Route
                  path={`/${ADMIN_NAME}/Changepassword`}
                  element={<AdminLayout Component={Changepassword} />}
                />
                <Route
                  path={`/${ADMIN_NAME}/Transactions`}
                  element={<AdminLayout Component={Transactions} />}
                />
                <Route
                  path={`/${ADMIN_NAME}/Order`}
                  element={<AdminLayout Component={Order} />}
                />
                <Route
                  path={`/${ADMIN_NAME}/UserInfo`}
                  element={<AdminLayout Component={UserInfo} />}
                />
              </Route>
            </Routes>
          </Provider>
        </div>
      </BrowserRouter>
    </PersistGate>
  );
}

export const storeInstance = store;

export default App;
