import React, { useState, useEffect } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AOS from "aos";
import "aos/dist/aos.css";
import { Formik, Form, Field } from "formik";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Col,
  Row,
} from "react-bootstrap";
import Connect from "../../components/Modal/Connect";
import logo_png from "../../assets/images/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { CommonService } from "../../util/commonService";
import { disconnectAddress } from "../../redux/_actions/user.action";
import { toast } from "../../components/common/Toast";
import { login_api } from "../../redux/_actions/apis.action";
import ConnectStage from "../../components/common/ConnectStage";
import ProcessModal from "../../components/Modal/ProcessModal/ProcessModal";

const SignupSchema = Yup.object().shape({
  useremail: Yup.string()
    .email("Invalid email")
    .required("Email Required")
    .trim("The Email cannot include leading and trailing spaces")
    .strict(true),
});

const Login = (props) => {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [connectAfterLogin, setConnectAfterLogin] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [disable, setDisabled] = useState(false);
  const [loginData, setLoginData] = useState({});

  const dispatch = useDispatch();
  const [processshow, processsetShow] = useState(false);
  const processhandleClose = () => processsetShow(false);
  const processhandleShow = () => processsetShow(true);

  const metamaskAddress = useSelector((state) => state.user?.walletAddress);
  const Authenticated = useSelector((state) => state.user?.login?.token);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const disconnectAcc = async () => {
    await dispatch(disconnectAddress());
    // toast.success("Logout Successfully.");
    // window.location.reload();
  };
  const connect = () => {
    setShow(true);
  };

  const loginFunc = async (values) => {
    // if (!metamaskAddress) {
    //   setTimeout(() => {
    //     setDisabled(true);
    //     toast.error("Please connect first");
    //   }, 100);
    //   setTimeout(() => {
    //     setDisabled(false);
    //   }, 2000);
    // }
    // let data = {
    //   email: values?.useremail,
    //   wallet_address: metamaskAddress,
    // };
    // const logindata = await dispatch(login_api(data));
    // if (logindata?.status !== 400) {
    //   navigate(`/dashboard`);
    // }
    localStorage.setItem("loginreg", "1");
    setLoginData(values);
    setConnectAfterLogin(true);
  };

  return (
    <section className="contact-us login text-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <h1 className="text-center mb-5 font-weight-bold text-white">
              {" "}
              Login
            </h1>
          </div>
          {/* {localStorage.getItem("loginreg") == "1" ? (
            <button
              className="btn m-0 mb-2 theme-btn btn-registration"
              onClick={() => {
                localStorage.setItem("loginreg", "0");
                navigate("/login");
                window.location.reload();
              }}
            >
              Back
            </button>
          ) : null} */}
          <div className="col-12 col-md-6 mx-auto">
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="contact-card py-5 px-3"
            >
              {
                // localStorage.getItem("loginreg") !== "1"
                !connectAfterLogin ? (
                  <Formik
                    initialValues={{
                      useremail: "",
                    }}
                    validationSchema={SignupSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, { resetForm }) => {
                      loginFunc(values);
                      resetForm({ values: "" });
                    }}
                  >
                    {({ handleSubmit, handleChange, values, errors }) => (
                      <Form onSubmit={handleSubmit} className="registerForm">
                        <div className="form-group">
                          <Field
                            name="useremail"
                            htmlFor="useremail"
                            id="useremail"
                            require="*"
                            type="email"
                            label="Email"
                            onChange={handleChange}
                            value={values.useremail}
                            placeholder="Enter Email"
                            maxLength={45}
                            className="form-control"
                          />
                          <p className="text-danger mt-3 position-absolute">
                            {errors.useremail}
                          </p>
                        </div>
                        <Row className="mt-5">
                          <p className="text-center px-3 text-center">
                            Please enter your email address
                          </p>
                          {/* <Col sm={12} md={4}>
                        {metamaskAddress ? (
                          <button
                            className="btn theme-btn btn-registration mb-2"
                            onClick={() => disconnectAcc()}
                          >
                            {CommonService?.custmizeAddress(metamaskAddress)}
                          </button>
                        ) : (
                          <button
                            className="btn theme-btn btn-registration mb-2"
                            onClick={handleShow}
                            // className="form-control"
                          >
                            Connect
                          </button>
                        )}
                      </Col> */}
                          <Col sm={12} className="text-center">
                            {/* <button
                          text="Submit"
                          type="submit"
                          className="btn mr-3 mb-2 theme-btn btn-registration"
                          // onClick={clickFunction()}
                          disabled={disable}
                        >
                          Login
                        </button> */}
                            <button
                              text="Submit"
                              type="submit"
                              className="btn m-0 mb-2 theme-btn btn-registration"
                              disabled={disable}
                            >
                              Next
                            </button>
                          </Col>

                          {/* <span className="pwicon" onClick={toggleShow}>
                          {!hidden ? (
                            <img src={show} alt="alt data" />
                            ) : (
                              <img src={eye} alt="alt data" />
                              )}
                            </span> */}
                        </Row>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <ConnectStage
                    type={"login"}
                    Data={loginData}
                    handleShow={handleShow}
                    disconnectAcc={disconnectAcc}
                  />
                )
              }
              <a className="centerTxt" onClick={processhandleShow}>
                Learn how to Register
              </a>
            </div>
          </div>
          <ProcessModal
            show={processshow}
            handleShow={processhandleShow}
            handleClose={processhandleClose}
          />
        </div>
      </div>
    </section>
  );
};

export default Login;
