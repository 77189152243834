import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "./index.css";

import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import Connect from "../Modal/Connect";
import logo from "../../assets/images/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { CommonService } from "../../util/commonService";
import {
  claimedTokens,
  disconnectAddress,
  lockedTokens,
  lockTokenInformation,
  userClaimedTokens,
} from "../../redux/_actions/user.action";
import { confirmAlert } from "react-confirm-alert";
import { IFANCY_PUBLIC_ADDRESS } from "../../constant";

const NewHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [processshow, processsetShow] = useState(false);
  // const processhandleClose = () => processsetShow(false);
  // const processhandleShow = () => processsetShow(true);
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const metamaskAddress = useSelector((state) => state.user?.walletAddress);
  const wallet = useSelector((state) => state.user?.connectedWallet);
  const userAuthenticated = useSelector((state) => state.user?.login?.token);

  const disconnectAcc = async () => {
    await dispatch(disconnectAddress());
    // toast.success("Logout Successfully.");
    // // const web3 = await new Web3(window.web3.currentProvider).close();

    // window.location.reload();
  };

  const connect = () => {
    setShow(true);
  };

  const [expanded, setExpanded] = useState(false);
  return (
    <header>
      {/* <div class="advertise p-3" >
      
        <p class="text-center">
         
        
          </p>
        <p class="text-center">
        <p>   <strong>Limited Time Offer:</strong><> Get 50% Free Tokens On Each Purchase Until End of October. The Extra 50% Bonus Tokens Will be Airdropped Within 48hrs After Purchase.</></p>
        </p>
      </div> */}
      <Navbar expand="lg" variant="dark" expanded={expanded}>
        <Nav.Link className="navbar-brand" to="/" as={Link}>
          <img src={logo} alt="logo" />
        </Nav.Link>

        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
          aria-controls="basic-navbar-nav"
        />

        <Navbar.Collapse
          className="collapse navbar-collapse"
          id="basic-navbar-nav"
        >
          <Nav className="navbar-nav mx-auto">
            <Nav.Link
              className="nav-item nav-link"
              to="/"
              as={NavLink}
              onClick={() => setExpanded(false)}
            >
              Home
            </Nav.Link>

            <Nav.Link
              className="nav-item nav-link"
              to="/cards"
              as={NavLink}
              onClick={() => setExpanded(false)}
            >
              Card
            </Nav.Link>

            <NavDropdown title="About Us" id="basic-nav-dropdown">
              <NavDropdown.Item href="/#team">Our Team</NavDropdown.Item>
              <NavDropdown.Item href="/#faq">FAQ</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Technology" id="basic-nav-dropdown">
              <NavDropdown.Item
                className="dropdown-item"
                to="/whitepaper"
                as={NavLink}
                onClick={() => setExpanded(false)}
              >
                White Paper
              </NavDropdown.Item>
              {/* <NavDropdown.Item className="dropdown-item" to="/pitch-deck" as={NavLink} onClick={() => setExpanded(false)}>Pitch Deck</NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown title="Resources" id="basic-nav-dropdown">
              <NavDropdown.Item
                className="dropdown-item"
                to="/how-to-buy"
                as={NavLink}
                onClick={() => setExpanded(false)}
              >
                How To Buy
              </NavDropdown.Item>
              <NavDropdown.Item
                className="dropdown-item"
                to="/Tokenomics"
                as={NavLink}
                onClick={() => setExpanded(false)}
              >
                Tokenomics
              </NavDropdown.Item>
              <NavDropdown.Item
                className="dropdown-item"
                to="/roadmap"
                as={NavLink}
                onClick={() => setExpanded(false)}
              >
                Roadmap
              </NavDropdown.Item>
              <NavDropdown.Item
                className="dropdown-item"
                to="/smart-contracts"
                as={NavLink}
                onClick={() => setExpanded(false)}
              >
                Smart Contracts
              </NavDropdown.Item>
              <NavDropdown.Item
                className="dropdown-item"
                to="/vesting-schedule"
                as={NavLink}
                onClick={() => setExpanded(false)}
              >
                Vesting Schedule
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              className="nav-item nav-link"
              to="/apps"
              as={NavLink}
              onClick={() => setExpanded(false)}
            >
              Apps
            </Nav.Link>

            <Nav.Link
              className="nav-item nav-link"
              to="/contact-us"
              as={NavLink}
              onClick={() => setExpanded(false)}
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              className="nav-item nav-link"
              to="/blog"
              as={NavLink}
              onClick={() => setExpanded(false)}
            >
              Blog
            </Nav.Link>

            <div className="d-flex">
              {userAuthenticated && metamaskAddress ? (
                <>
                  <Button
                    className="btn theme-btn mr-2 mt-2 import-desktop d-block"
                    onClick={() => navigate(`/dashboard`)}
                  >
                    Dashboard
                  </Button>

                  <Button
                    className="btn theme-btn mt-2  d-block"
                    onClick={() => disconnectAcc()}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={`btn theme-btn my-2 my-sm-0 mx-2 ${
                      window.location.pathname !== "/register" ? "" : ""
                    }`}
                    onClick={() => {
                      navigate(`/login`);
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    className={`btn theme-btn my-2 my-sm-0 mx-2 ${
                      window.location.pathname !== "/login" ? "" : ""
                    }`}
                    onClick={() => {
                      navigate(`/register`);
                      // localStorage.setItem("loginreg", "0");
                    }}
                  >
                    Register
                  </Button>
                </>
              )}
            </div>
          </Nav>

          {/* <button onClick={processhandleShow}>modal</button> */}
          {/* <ProcessModal
            show={processshow}
            handleShow={processhandleShow}
            handleClose={processhandleClose}
          /> */}

          <Connect
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
          />
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default NewHeader;
