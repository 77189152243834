import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import axios from "axios";
import ScrollToTop from "../../components/ScrollToTop";

import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/blur.css";

import "./index.css";
import "./animation.css";

import NewHeader from "../../components/Header/newHeader";
import AOS from "aos";
import "aos/dist/aos.css";
import Timer from "../../components/Timer";

import FAQItem from "../../components/Home/FAQItem";
import FancyThatTeam from "../../components/Home/fancyThatTeam";
import Catagory from "../../components/Home/catagory";

import NewFooter from "../../components/NewFooter";
// import { honeybee, bubbles } from "../../ScriptFunction/canvas";

import bnbLogo from "../../assets/images/bnb-logo.svg";
import busdLogo from "../../assets/images/busd.svg";
import NinjaPromo from "../../assets/images/NinjaPromo.png";
import Antier from "../../assets/images/final_logo.svg";
import Fetch from "../../assets/images/Fetch.svg";
import banner_bg from "../../assets/images/banner-bg.svg";
import banner from "../../assets/images/banner.png";
import Group_616 from "../../assets/images/Group 616.png";
import Group_633 from "../../assets/images/Group 633.png";
import Path_524 from "../../assets/images/Path 524.png";
import Group_615 from "../../assets/images/Group 615.png";
import Group_660 from "../../assets/images/Group 660.png";
import Path_523 from "../../assets/images/Path 523.png";
import reddit_icon from "../../assets/images/reddit-icon.svg";
// import wave_green_part from "../../assets/images/wave-green-part.png";
// import wave_blue_part from "../../assets/images/wave-blue-part.png";
// import wave_pink_part from "../../assets/images/wave-pink-part.png";

import { useDispatch, useSelector } from "react-redux";
import { connectWithWallet } from "../../redux/_actions/connect.action";
import Countdown, { zeroPad } from "react-countdown";
import {
  disconnectAddress,
  getUSDTDecimal,
  phaseInformation,
  totalPhases,
  totalTokenSold,
  totalTokenSoldUSD,
} from "../../redux/_actions/user.action";
import { IFANCY_PUBLIC_ADDRESS, IFANCY_TOKEN_ADDRESS } from "../../constant";
import Connect from "../../components/Modal/Connect";
import { CommonService } from "../../util/commonService";
import { landing_details } from "../../redux/_actions/apis.action";
import video1 from "../../assets/video/wave1.mp4";
import ProcessModal from "../../components/Modal/ProcessModal/ProcessModal";
import { getUsdPrice } from "../../CommonServices/BnbToUsd";

import { startMainLoader, stopMainLoader } from "../../redux/_actions/loader";

import visitwebsite from "../../assets/images/visitwebsite.png";
import apple from "../../assets/images/apple.e8246da2.svg";
import playstore from "../../assets/images/playstore.dc059237.svg";
import Confetti from 'react-confetti'
import { APIURL, API_HOST } from "../../constant";


axios.defaults.baseURL = API_HOST /*|| "http://3.211.198.61:3000"*/;
const NewHome = () => {
  const [faqOpenIndex, setFaqOpenIndex] = useState(0);
  const videoEle = useRef(null);
  const authStatus = useSelector((state) => state?.loader?.isLoading);

  const walletAddress = useSelector((state) => state.user?.walletAddress);
  const wallet = useSelector((state) => state?.user.connectedWallet);
  const [totalSoldToken, setTotalSoldToken] = useState("");
  const [totalSoldTokenPrice, setTotalSoldTokenPrice] = useState("");
  const [tokenBalane, setTokenBalane] = useState();
  const [phaseInfoVal, setPhaseInfo] = useState();
  const [loading, setloading] = useState(true);
  const [phaseInfoOldValue, setPhaseInfoOld] = useState();
  const [phaseInfoNextValue, setPhaseInfoNext] = useState();
  const [checkPast, setCheckPast] = useState(false);
  const [usersCount, setUsersCount] = useState(1309);
  const [iCORunning, setICORunning] = useState(false);
  const [ifanctTokenDecimal, setTokenDecimal] = useState(0);
  const [soldToken, setSoldToken] = useState(1440626028.64699);
  const [show, setShow] = useState(false);
  const [totalValueRaised, setTotalValueraised] = useState(38840910621137);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [processshow, processsetShow] = useState(false);
  const processhandleClose = () => processsetShow(false);
  const processhandleShow = () => processsetShow(true);
  const userAuthenticated = useSelector((state) => state.user?.login?.token);
const [formwalletAddress,setFormwalletAddress]=useState("")
const [airdropError,setairdropError]=useState({})
const dispatch = useDispatch();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // honeybee();
    // bubbles();
    // getUsersCount();

    videoEle.current.setAttribute("autoPlay", "");
    videoEle.current.setAttribute("muted", "");
    videoEle.current.setAttribute("playsInline", "");
  }, []);

  useEffect(() => {
    callWallectConnect();
  });

  const convertInMilions = (value) => {
    let milionValue = `${
      Math.abs(value) > 999999
        ? Math.sign(value) * (Math.abs(value) / 1000000).toFixed(1) + "M"
        : Math.abs(value) > 999
        ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + "k"
        : Math.sign(value) * Math.abs(value)
    }`;
    return milionValue;
  };
  const callWallectConnect = async () => {
    let type = await localStorage.getItem("walletType");

    if (walletAddress) {
      await connectWithWallet(type, dispatch);
    }
  };
  // useEffect(() => {
  //   dispatch(startMainLoader())
  //   Promise.all([
  //     total_Phases(),
  //     totalTokenSoldFunc()
  //   ]).then(() => {
  //     dispatch(stopMainLoader())
  //   })
  // }, [wallet]);

  const totalTokenSoldFunc = async () => {
    let totalTokenPrice = await dispatch(
      totalTokenSoldUSD({
        wallet: wallet,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );
        setTotalValueraised(totalTokenPrice);
  };
  const total_Phases = async () => {
    const tokenDecimal = await dispatch(
      getUSDTDecimal({
        wallet: wallet,
        address: IFANCY_TOKEN_ADDRESS,
      })
    );
    setTokenDecimal(tokenDecimal);

    let soldToken = await dispatch(
      totalTokenSold({
        wallet: wallet,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );

    setSoldToken(soldToken / 10 ** tokenDecimal);

    let totalPhase = await dispatch(
      totalPhases({
        wallet: wallet,
        walletAddress,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );
    let currentTimeStapm = new Date().getTime() / 1000;
    let currentPhase;
    let phaseInfoValue;
    if(totalPhase){
      for (let i = 1; i <= totalPhase; i++) {
        let phaseInfo = await dispatch(
          phaseInformation({
            wallet: wallet,
            type: i,
            address: IFANCY_PUBLIC_ADDRESS,
          })
        );
        if (
          phaseInfo?.startTimestamp <= currentTimeStapm &&
          phaseInfo?.expirationTimestamp >= currentTimeStapm
        ) {
          currentPhase = i;
          setICORunning(true);

          phaseInfoValue = phaseInfo;
          phaseInfoValue.phase = i;
          setPhaseInfo(phaseInfoValue);
          setloading(false);
          break;
        } else if (phaseInfo?.startTimestamp > currentTimeStapm) {
          setICORunning(false);
          currentPhase = i;
          phaseInfoValue = phaseInfo;
          phaseInfoValue.phase = i;
          setPhaseInfo(phaseInfoValue);
          setloading(false);
          break;
        } else if (
          i == totalPhase &&
          phaseInfo?.expirationTimestamp < currentTimeStapm
        ) {
          setICORunning(false);

          currentPhase = i + 1;
          setPhaseInfo();
          setloading(false);
          break;
        }
      }
    } else setloading(false);
  };

  const disconnectAcc = async () => {
    await dispatch(disconnectAddress());

    // window.location.reload();
  };

  const checkPhase = (value) => {
    if (value == 1) {
      return "Pre Pre Sale";
    } else if (value == 2) {
      return "seed sale";
    } else {
      return `Phase ${value - 2}`;
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      // { window.location.reload() }

      return (
        <div className="countdownTimer">
          {/* <div className="timerBlocks box">
            <h5>00</h5>
            <p>Days</p>
          </div>
          <p>:</p>
          <div className="timerBlocks box">
            <h5>00</h5>
            <p>Hours</p>
          </div>
          <p>:</p>
          <div className="timerBlocks box">
            <h5>00</h5>
            <p>Minutes</p>
          </div>
          <p>:</p>
          <div className="timerBlocks box">
            <h5>00</h5>
            <p>Seconds</p>
          </div> */}
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div className="countdownTimer mt-3">
          <div className="timerBlocks ">
            <div className="box">
              <h5 className="text-dark">{zeroPad(days)}</h5>
            </div>
            <p className="text-white">Days</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              <h5 className="text-dark">{zeroPad(hours)}</h5>
            </div>
            <p className="text-white">Hours</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              {" "}
              <h5 className="text-dark">{zeroPad(minutes)}</h5>
            </div>
            <p className="text-white">Minutes</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              {" "}
              <h5 className="text-dark">{zeroPad(seconds)}</h5>
            </div>
            <p className="text-white">Seconds</p>
          </div>
        </div>
      );
    }
  };
  const getUsersCount = async () => {
    const result = await dispatch(landing_details());
    setUsersCount(result?.data?.usersList);
  };

  const handleChange=(e)=>{
    setFormwalletAddress(e.target.value)
    setairdropError({})
  }
  const handleSubmit= async(e)=>{
    e.preventDefault()
    if(formwalletAddress.length==0){
      setairdropError({
        ...airdropError,
        status:true,
        message:"Wallet Address is required"
      })
      return
    }else{
     axios.post("/common/walletAddress",{"wallet_address":formwalletAddress})
     .then((res)=>{
      if(res?.data?.error==false){
      setairdropError({
        ...airdropError,
        success:true,
        message:"Wallet Address add successfully!"
      })
    }
     })
     .catch((err) => {
      console.log(err.message);
     })
    }
    }

    useEffect(()=>{
      if(airdropError?.success){
        const timeout = setTimeout(() => {
      setairdropError({});
    }, 5000);

    return () => clearTimeout(timeout);
      }

    },[airdropError])

    const appAdvertise = [
      {
        heading:<h1>fancy<span className="font-weight-light">that</span>wallet <span className="font-weight-light">App is Now Live</span></h1>,
        WebLink:"https://fancythatwallet.io/",
        AppStore:"https://apps.apple.com/gb/app/fancythatwallet/id1640049529",
        GooglePlay:"https://play.google.com/store/apps/details?id=com.fancythattoken"
      },
      {
        heading:<h1><span className="font-weight-light">ifancya</span>hotel <span className="font-weight-light">App is Now Live</span></h1>,
        WebLink:"https://ifancyahotel.com/",
        AppStore:"https://ifancyahotel.com/",
        GooglePlay:"https://ifancyahotel.com/"
      },
      {
        heading:<h1><span className="font-weight-light">ifancya</span>date <span className="font-weight-light">App is Now Live</span></h1>,
        WebLink:"https://ifancyadate.com/",
        AppStore:"https://apps.apple.com/pk/app/ifancyadate/id1437705188",
        GooglePlay:"https://play.google.com/store/apps/details?id=com.ifancyadat.app"
      }
    ]
  
  return (
    <div className="body-bg">
      <ScrollToTop />
      <div
        className="main-section"
        style={{ backgroundImage: `url(${banner_bg})` }}
      >
        <NewHeader />
        
        <div class="wallet-advertise">
          <Confetti />
          {appAdvertise?.map((data,index)=>{
            return(
              <div key={index}>
              {data?.heading}
              <div class="storeBtns__wrap">
                        <a class="storeBtns__btn" target="_blank" href={data?.WebLink}><img src={visitwebsite}/>  </a>
                        <a class="storeBtns__btn" target="_blank" href={data?.AppStore}> <img src={apple}/> </a>
                        <a class="storeBtns__btn" target="_blank" href={data?.GooglePlay}> <img src={playstore}/></a>
                      </div>
              </div>
            )
          })}

        </div>

        <section className="banner ">
          <div className="container">
            {/* <canvas id="bubbles"></canvas> */}

            <div className="row align-items-center">
              <div
                className="col-12 col-md-12 col-lg-6 position-relative min-height m-auto"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                {/* <img className="loading position-relative" src={banner} /> */}
                <LazyLoadImage
                  className="loading position-relative"
                  src={banner}
                  effect="blur"
                  // size={{height:"90px"}}
                  // height="90px"
                ></LazyLoadImage>

                <div className="contant">
                  <h1>fancythattoken</h1>
                  <h4>
                    Crypto Currency is the future and with the{" "}
                    <b>FancyThatToken </b>
                    you can purchase what you Fancy within the{" "}
                    <b>IFancyThat </b>
                    Brand.
                  </h4>
                  <p>
                    Non-Custodial multi chain wallet, privacy chat,
                    decentralised exchange.
                  </p>
                </div>
              </div>
              {/* <div
                className="col-12 col-md-12 offset-lg-1 col-lg-5"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <div className="card py-4">
                  <p className="text-center text-white my-3">A BEP-20 fancythattokens</p>
                  <h2 className="text-center text-white mb-3">AirDrop</h2>
                  <Countdown
                        date={
                          Date.now()+ (new Date('July 31, 2023 23:59:00').getTime()- new Date().getTime())
                        }
                        renderer={renderer}
                   />
                  <div className="form-group">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                    <input type="text" className="form-control" placeholder="Wallet Address" required
                    onChange={handleChange}
                    name="walletAddress"
                    value={formwalletAddress}/>
                    {airdropError?.status ?
                    <p class="text-danger mt-2">{airdropError?.message}</p>
                      :null}
                    <div className="text-center mt-3">
                    <button type="submit" className="btn theme-btn ">Claim</button>
                    {airdropError?.success ?
                    <p class="text-success mt-3"><b>{airdropError?.message}</b></p>
                      :null}
                    </div>
                    </div>
                  </form>                  
                  <p className="text-center text-white my-3">All claims will be cross-referenced to the winner's list</p>
                  </div>
                </div>
                **<div className="card py-4">
                  {phaseInfoVal ? (
                    <>
                      <h5
                        style={{ "font-size": "30px" }}
                      >{`fancythattoken ${checkPhase(phaseInfoVal?.phase)} ${
                        iCORunning ? "is NOW LIVE and ends in": "starts in"
                      }:`}</h5>
                      <Countdown
                        date={
                          JSON.parse(
                            iCORunning
                              ? phaseInfoVal?.expirationTimestamp
                              : phaseInfoVal?.startTimestamp
                          ) * 1000
                        }
                        renderer={renderer}
                      />
                      <ul className="mb-0">
                        <li>
                          <img src={bnbLogo} />
                          <p>BNB</p>
                        </li>
                        <li>
                          <img src={busdLogo} width={"45px"} />
                          <p>BUSD </p>
                        </li>
                      </ul>

                      <p className="text-center text-white">
                        {`Tokens for sale: 22,560,753,611 FNCY`}
                        <br />
                        Phase 3 price: $0.0016 / FNCY
                        <br />

                        Next Phase 4 price: $0.0020 / FNCY
                      </p>
                  
                
                      <div className="text-center ">
                        <a
                          href="/white-paper-footer"
                          className="btn theme-btn mt-3"
                        >
                          White Paper
                        </a>
                        <a
                          href="/how-to-buy"
                          className="btn theme-btn ml-3 mt-3"
                        >
                          How To Buy
                        </a>
                      </div>
                      <div className="row text-center">
                        <div className=" text-center w-100">
                          <p className="text-white mb-0 d-inline-block text-center mt-4">
                            {`Min $${(
                              phaseInfoVal?.minPurchaseAmount /
                              10 ** 8
                            ).toLocaleString("US")} - Max $${(
                              phaseInfoVal?.maxPurchaseAmount /
                              10 ** 8
                            ).toLocaleString("US")}`}
                          
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-center text-white mt-3">
                          20% of tokens will be delivered within 24hours and the
                          rest as per the{" "}
                          <a href="/vesting-schedule">
                            Vesting schedule{" "}
                            <i className="fa fa-external-link"></i>
                          </a>
                        </p>
                      </div>
                      {userAuthenticated && walletAddress ? (
                        <div className="text-center">
                          <a href="/dashboard" className="btn theme-btn mt-3">
                            Dashboard
                          </a>
                          <Button
                            className="btn ml-3 theme-btn mt-3"
                            onClick={() => disconnectAcc()}
                          >
                            Logout
                          </Button>
                        </div>
                      ) : (
                        <div className="text-center">
                          <a href="/login" className="btn theme-btn mt-3">
                            LOGIN
                          </a>
                          <a
                            href="/register"
                            className="btn theme-btn ml-3 mt-3"
                          >
                            REGISTER
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <h5>
                        {loading
                          ? "Loading "
                          : "ICO ENDED"}
                      </h5>

                      <ul className="mb-0">
                        <li>
                          <img src={bnbLogo} />
                          <p>BNB</p>
                        </li>
                        <li>
                          <img src={busdLogo} width={"45px"} />
                          <p>BUSD </p>
                        </li>
                      </ul>

                      <div className="text-center">
                        <a
                          href="/white-paper-footer"
                          className="btn theme-btn mt-3"
                        >
                          White Paper
                        </a>

                        <a
                          href="/how-to-buy"
                          className="btn theme-btn ml-3 mt-3"
                        >
                          How To Buy
                        </a>
                      </div>
                      <div>
                        <p className="text-center text-white mt-3">
                          20% of tokens will be delivered within 24hours and the
                          rest as per the{" "}
                          <a href="/vesting-schedule">
                            Vesting schedule{" "}
                            <i className="fa fa-external-link"></i>
                          </a>
                        </p>
                      </div>
                      {userAuthenticated && walletAddress ? (
                        <div className="text-center">
                          <a href="/dashboard" className="btn theme-btn mt-3">
                            Dashboard
                          </a>
                          <Button
                            className="btn ml-3 theme-btn mt-3"
                            onClick={() => disconnectAcc()}
                          >
                            Logout
                          </Button>
                        </div>
                      ) : (
                        <div className="text-center">
                          <a href="/login" className="btn theme-btn mt-3">
                            LOGIN
                          </a>

                          <a
                            href="/register"
                            className="btn theme-btn ml-3 mt-3"
                          >
                            REGISTER
                          </a>
                        </div>
                      )}
                    </>
                  )}
                </div> **
              </div> */}
            </div>
          </div>
          <div className="status">
            <div className="container">
              <div className="row">
                <div
                  className="col-12 col-md-4 text-center mt-4" //here
                  data-aos="fade-right"
                  data-aos-duration="3000"
                >
                  <h5>{usersCount || 0}</h5>
                  {/* <h5>0</h5> */}
                  <p>FNCY Members</p>
                </div>
                <div
                  className="col-12 col-md-4 text-center mt-4"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <h5>{convertInMilions(soldToken ? soldToken : 0)}</h5>
                  {/* <h5>0</h5> */}
                  <p>FNCY Tokens Sold</p>
                </div>
                <div
                  className="col-12 col-md-4 text-center mt-4"
                  data-aos="fade-left"
                  data-aos-duration="3000"
                >
                  {/* <h5>0</h5> */}
                  <h5>{`$${
                    totalValueRaised
                      ? convertInMilions(totalValueRaised / 10 ** 8) > 0
                        ? Number(
                            convertInMilions(totalValueRaised / 10 ** 8)
                          ).toFixed(2)
                        : convertInMilions(totalValueRaised / 10 ** 8)
                      : 0
                  }`}</h5>
                  <p>Value Raised</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="video-sec">
        <div className="video-bgsection">
          <div className="bg-video">
            <video ref={videoEle} playsInline loop autoPlay muted>
              <source src={video1} type="video/mp4" />
            </video>
          </div>

          <div>
            <div className="center-video">
              <h4
                data-aos="fade-up"
                data-aos-duration="1000"
                className="text-white pt-5 text-center font-weight-bold"
              >
                fancythattoken part of the ifancythat brand
              </h4>
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                className="text-white col-12 col-md-7 mx-auto text-center"
              >
                {" "}
                Our vision for <b>fancythattoken</b> is to incorporate it as a
                payment method using our own wallet and exchange, users will be
                able to purchase real products using the online platforms which
                are stored inside the wallet ranging from retail, travel and
                leisure industries. The user will have the ability to invest,
                trade or even pay for their hotels, flights, car hire, mobile
                phones plus much more using the <b>fancythattoken</b>.
              </p>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/fancythattoken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Group_616} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/fancythattoken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Group_633} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/fancythattoken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Path_524} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/fancythattoken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Group_615} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCc7Znal_hdjUTBE62s9arYw"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Group_660} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/i-fancy-that-token"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Path_523} />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.reddit.com/u/FancyThatToken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={reddit_icon} width={"30px"} />
                  </a>
                </li>
              </ul>
              <div className="row pb-5">
                <div className="col-12 col-lg-6 mx-auto">
                  <embed
                    width="100%"
                    height="500"
                    src="https://www.youtube.com/embed/_94afC6w5uI"
                    title="YouTube video player"
                  />
                </div>
              </div>
            </div>
            {/* className="video-bg" */}
            {/* <div
            className="top-bg video-img"
            style={{
              backgroundImage: `url(${wave_green_part}`,
            }}
          ></div>
          <div
            className="center-bg video-img"
            style={{
              backgroundImage: `url(${wave_blue_part}`,
            }}
          ></div> */}
            {/* <div
            className="bottom-bg video-img"
            style={{
              backgroundImage: `url(${wave_pink_part}`,
            }}
          ></div> */}
            {/* <video src={video1}  width="600" height="300" controls="controls" autoPlay="true" /> */}
          </div>
        </div>
      </section>
      <section className="catagory" id="about-us">
        {/* <canvas id="honeybee" className="home-annimat-canvas"></canvas> */}
        <Catagory />
      </section>

      <section className="facny-team" id="team">
        <FancyThatTeam />
      </section>

      <section className="Partners">
        <div className="container">
          <div className="comnHeading">
            <span className="beforeTxt_Style">Partners</span>
            <h2>Partners</h2>
          </div>
          <div className="row mt-5">
            {/* <div className="col-md-4">
              <img src={NinjaPromo} />
            </div> */}
            <div className="col">
              <img src={Antier} />
            </div>
            <div className="col">
              <img src={Fetch} />
            </div>
          </div>
        </div>
      </section>
      <div id="faq">
        <FAQItem />
      </div>
      <ProcessModal
        show={processshow}
        handleShow={processhandleShow}
        handleClose={processhandleClose}
      />
      <NewFooter />
    </div>
  );
};

export default NewHome;
