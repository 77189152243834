import React, { Fragment } from "react";
import NewHeader from "../Header/newHeader";
import NewFooter from "../NewFooter";

const PageLayout = ({ Component, ...props }) => {
  return (
    <Fragment>
      <div className="blue-gradiant">
        <NewHeader />
      </div>
      <div className="full-height">
        <Component {...props} />
      </div>
      <NewFooter />
    </Fragment>
  );
};

export default PageLayout;
