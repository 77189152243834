import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { useDispatch } from "react-redux";
import {
  LOGIN,
  SIGNUP,
  USER_ADDRESS,
  USER_WALLET,
} from "../../redux/constant.actionTypes";

const SideBar = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [status, setstatus] = useState(false);
  const LogOutFunc = async () => {
    dispatch({ type: USER_ADDRESS, payload: "" });
    dispatch({ type: USER_WALLET, payload: "" });
    dispatch({ type: LOGIN, payload: "" });
    dispatch({ type: SIGNUP, payload: "" });
    localStorage.clear();
  };

  return (
    <header className="header dash-sidebar">
      <nav className="navbar">
        <span className="open-menu">
          <button
            className="bg-transparent border-0"
            onClick={() => setstatus(!status)}
          >
            <i className="fa fa-bars text-white fa-lg"></i>
          </button>
        </span>

        <div className={"menu-wrapper " + (status ? "off-menu" : "")}>
          <ul className="menu">
            <li className="menu-block">
              <span className="close-menu">
                <button
                  className="bg-transparent border-0"
                  onClick={() => setstatus(!status)}
                >
                  <i className="fa fa-times fa-lg text-white"></i>
                </button>
              </span>
            </li>
            <li className="menu-item">
              <NavLink
                to="/"
                className="menu-link"
                onClick={() => {
                  navigate(`/`);
                }}
              >
                Home page
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink className="menu-link" to="/dashboard">
                Dashboard{" "}
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                to="/my-profile"
                className="menu-link"
                onClick={() => setstatus(!status)}
              >
                Profile
              </NavLink>
            </li>
            <li className="menu-item">
              <a
                href="https://t.me/fancythattoken"
                target={"_blank"}
                className="menu-link"
              >
                Technical Support
              </a>
            </li>
            <li className="menu-item">
              <NavLink
                to="/how-to-buy"
                className="menu-link"
                onClick={() => setstatus(!status)}
              >
                How to Buy
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                to="/transaction-history"
                className="menu-link"
                onClick={() => setstatus(!status)}
              >
                Transaction History
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                to="/referral-code"
                className="menu-link"
                onClick={() => setstatus(!status)}
              >
                Referral Code
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                to="/"
                className="menu-link"
                onClick={() => {
                  LogOutFunc();
                }}
              >
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default SideBar;
