import React, { useState, useEffect } from "react";
import { Col, Row, Modal, ModalBody, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BUSD_ADDRESS, IFANCY_TOKEN_ADDRESS } from "../../constant";
import { Admin_user_list_action } from "../../redux/_actions/apis.action";
import { getUSDTDecimal } from "../../redux/_actions/user.action";
// import { getUSDTDecimal } from "../../redux/_actions/user.action";
import "./userorder.scss";
const UserOrder = ({ usersData, show, setShow }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [ifanctTokenDecimal, setTokenDecimal] = useState(0);
  const [busdTokenDecimal, setBusdTokenDecimal] = useState(0);

  const wallet = useSelector((state) => state?.user.connectedWallet);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const orderFunc = async () => {
    const result = await dispatch(
      Admin_user_list_action(usersData?.wallet_address)
    );
    setUserData(result?.data?.userOrderList);
    const busdDecimal = await dispatch(
      getUSDTDecimal({
        wallet: wallet,
        address: BUSD_ADDRESS,
      })
    );
    setBusdTokenDecimal(10 ** busdDecimal);

    // const tokenDecimal = await dispatch(
    //   getUSDTDecimal({
    //     wallet: wallet ? wallet : "METAMASK",
    //     address: IFANCY_TOKEN_ADDRESS,
    //   })
    // );
    setTokenDecimal(10 ** 6);
  };
  useEffect(() => {
    orderFunc();
  }, [usersData?.wallet_address]);
  return (
    <div>
      {/* <Button onClick={() => handleShow()}>User Order Details</Button> */}
      <Modal
        scrollable={true}
        className="connect_modal"
        show={show}
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title className="m-auto">
            User Order Details for {usersData?.username}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {userData && userData.length > 0 ? (
            <>
              {userData?.map((data, key) => {
                return (
                  <>
                    <div className="p-1">
                      <ul className="list-unstyled ul_listing text-dark p-3">
                        <li className="">
                          <span>User Name</span>
                          {usersData?.username}
                        </li>
                        <hr />
                        <li>
                          <span>Email</span>
                          {usersData?.email}
                        </li>
                        <hr />

                        <li>
                          <span>Amount Bought</span>
                          {data?.type == 1 && data?.tokenType == 1
                            ? (data?.buyAmount / 10 ** 18).toLocaleString("US")
                            : data?.type == 1 && data?.tokenType == 2
                            ? (
                                data?.buyAmount / busdTokenDecimal
                              ).toLocaleString("US")
                            : data?.type == 2 && data?.tokenType == 1
                            ? (
                                data?.userTransferTokens /
                                10 ** 18
                              ).toLocaleString("US")
                            : data?.type == 2 && data?.tokenType == 2
                            ? (
                                data?.userTransferTokens / busdTokenDecimal
                              ).toLocaleString("US")
                            : 0}
                        </li>
                        {/* <li>
                          <span>
                            {data.type == 1
                              ? "Tokens Bought (20%)"
                              : "Claimed Tokens"}
                          </span>
                          {data.type == 1 && userData?.tokenType == 1
                            ? data?.buyAmount / 10 ** 18
                            : data.type == 1 && userData?.tokenType == 2
                            ? data?.buyAmount / busdTokenDecimal
                            : data?.userTransferTokens / ifanctTokenDecimal}
                        </li> */}
                        <hr />

                        <li>
                          <span>Wallet Address</span>
                          {data?.walletAddress}
                        </li>
                        <hr />

                        <li>
                          <span>Token Type</span>
                          {data?.tokenType == 1 ? "BNB" : "BUSD"}
                        </li>
                        <hr />
                      </ul>
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <Col className="baseToken_style next-modal">
              <p className="my-4">No Record Found</p>
            </Col>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserOrder;
