import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Col, Row, Modal, ModalBody } from "react-bootstrap";

import { Link } from "react-router-dom";
import Metamask from "../../assets/images/metamask_icon.png";
import Walleticon from "../../assets/images/wallet_icon.svg";
import Trustwallet from "../../assets/images/trust-wallet.png";
import Binanceicon from "../../assets/images/Binance-Icon.png";
import "./Connect.scss";
import { connectWithWallet } from "../../redux/_actions/connect.action";
import { isMobile } from "react-device-detect";
import { connectWithWalletConnect } from "../../redux/_actions/walletConnect.action";
// import { connectWithWallet } from "../redux/action/connect.action";

const Connect = (props) => {
  const [modalState, setModalState] = useState(false);
  const [msgDiff, setMsgDiff] = useState();
  const handleClosed = () => setModalState(false);
  const handleShowd = () => setModalState(true);
  const dispatch = useDispatch();
  const metamaskAddress = useSelector((state) => state.user?.walletAddress);

  const connectToWallet = async (e, type) => {
    e.preventDefault();
    if (type === "walletconnect") {
      props.handleClose(false);
      const result = await connectWithWalletConnect(dispatch);
      setTimeout(() => {
        if (!result?.code) {
          setModalState(true);
          setMsgDiff(0);
        } else {
          setModalState(true);
          setMsgDiff(1);
        }
      }, 1000);

      setTimeout(() => {
        setModalState(false);
      }, 7000);
    } else {
      let Type = type?.toUpperCase();
      localStorage.setItem("walletType", Type);
      props.handleClose(false);

      let result = await connectWithWallet(Type, dispatch);
      setTimeout(() => {
        if (!result?.code) {
          setModalState(true);
          setMsgDiff(0);
        } else {
          setModalState(true);
          setMsgDiff(1);
        }
      }, 1000);

      setTimeout(() => {
        setModalState(false);
      }, 7000);
      if (result) {
        if (metamaskAddress) {
          // window.location.reload();
        }
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
      } else {
        window.location.reload();
      }
    }

    let Type = type?.toUpperCase();
    localStorage.setItem("walletType", Type);

    let result = await dispatch(connectWithWallet(Type));
  };

  // console.log("props", props);

  return (
    <>
      <Modal
        scrollable={true}
        className="connect_modal"
        show={props.show}
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect to a wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {isMobile ? (
              <Col className="baseToken_style">
                <ul>
                  <li>
                    <div onClick={(e) => connectToWallet(e, "walletconnect")}>
                      WalletConnect
                      <span>
                        <img src={Walleticon} alt="Wallet Icon" />
                      </span>{" "}
                    </div>
                  </li>
                </ul>
                <div className="add_new text-center"></div>
              </Col>
            ) : (
              <Col className="baseToken_style">
                <ul>
                  <li>
                    <div onClick={(e) => connectToWallet(e, "metamask")}>
                      Metamask
                      <span>
                        <img src={Metamask} alt="Metamask" />
                      </span>{" "}
                    </div>
                  </li>
                  <li>
                    <div onClick={(e) => connectToWallet(e, "walletconnect")}>
                      WalletConnect
                      <span>
                        <img src={Walleticon} alt="Wallet Icon" />
                      </span>{" "}
                    </div>
                  </li>
                  <li>
                    <div onClick={(e) => connectToWallet(e, "trustwallet")}>
                      Trust Wallet{" "}
                      <span>
                        <img src={Trustwallet} alt="Trust wallet" />
                      </span>{" "}
                    </div>
                  </li>
                  <li>
                    <div onClick={(e) => connectToWallet(e, "binance")}>
                      Binance{" "}
                      <span>
                        <img src={Binanceicon} alt="Binance Icon" />
                      </span>{" "}
                    </div>
                  </li>
                </ul>
                <div className="add_new text-center">
                  {/* <span>New to Ethereum? &nbsp;</span>
              <a
                href="https://ethereum.org/wallets/"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about wallets
              </a> */}
                </div>
              </Col>
            )}
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalState}
        onHide={handleClosed}
        keyboard={false}
        className="connect_modal process_cl"
      >
        <Modal.Body className="modal_body12 ht_fix">
          <button
            className="closeBtn"
            type="button"
            onClick={() => handleClosed()}
          >
            X
          </button>
          {msgDiff && msgDiff === 1 ? (
            <h2>
              Click on your wallet and unlock it, then try again to connect
            </h2>
          ) : (
            <h2>
              Congratulations, Your Wallet is connected. You can{" "}
              {props?.type === "login" ? "login" : "register"} now
            </h2>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Connect;
