
import React from "react";
import { Fragment } from "react/cjs/react.production.min";
import Header from "./AdminHeader";
import AdminSideBar from "./AdminSideBar";
import './AdminLayout.scss'
import { Row, Col } from "react-bootstrap";

const AdminLayout = ({ Component, ...props }) => {
  return (
    <Fragment>
      <div className="blue-gradiant">
        <Header />
      </div>
          <div className='body-bg'>
            <div className='AdminMain'>
            <Row>
              <Col lg={2}>
                <AdminSideBar />
              </Col>
              <Col lg={10}>
                <Component {...props} />
              </Col>
            </Row>
          </div>
        </div>
    </Fragment>
  );
};

export default AdminLayout;
