import Web3 from "web3";
import { Web3Service } from "../../CommonServices/web3.service";
import { switchNetwork } from "../../CommonServices/CustomNetworkService";
// import * as Session from "../../utils/session";
// import { provider } from "../../../CommonServices/wallectConnectProvider";
import {
  rootName,
  BINANCE_CHAIN_ID,
  adminRootName,
  publicAdminRootName,
  RINKEBY_CHAINID,
} from "../../constant";
import { useHistory, useNavigate } from "react-router-dom";
import { toast } from "../../components/Toast/Toast";

// import { METAMASK_TYPE, WALLET_TYPE, CLAIMED_ADDRESS } from "./type";
import {
  USER_ADDRESS,
  USER_WALLET,
  SIGNUP,
  LOGIN,
} from "../constant.actionTypes";
import { provider } from "../../CommonServices/wallectConnectProvider";

/**
 * export type here
 */

export const isMetaMaskInstalled = async () => {
  const { ethereum, web3 } = window;
  const result = Boolean(ethereum && ethereum.isMetaMask);
  if (result) {
    return "metamask";
  } else if (web3) {
    return "trustwallet";
  } else {
    return "none";
  }
};

const isWalletInstalled = async (type) => {
  const { BinanceChain, web3, ethereum } = window;
  let result = false;
  if (type === "METAMASK") {
    const isInstalled = Boolean(ethereum && ethereum.isMetaMask);
    if (isInstalled) {
      result = type;
    } else {
      alert("Please install wallet");
    }
  } else if (type === "BINANCE") {
    const isInstalled = Boolean(
      BinanceChain && (await BinanceChain.isConnected())
    );
    if (isInstalled) {
      result = type;
    } else {
      alert("Please install wallet");
    }
  }
  if (result === "BINANCE") {
    return "BINANCE_WALLET";
  } else if ("METAMASK") {
    return "METAMASK_WALLET";
  } else if (ethereum || web3 || BinanceChain) {
    return "TRUST_WALLET";
  }
};

export const connectWithWallet = async (type, dispatch) => {
  const isInstalled = await isWalletInstalled(type);
  try {
    let address;
    const { ethereum, BinanceChain } = window;
    let web3Instance = new Web3(ethereum);

    await ethereum.on("accountsChanged", async function (accounts) {
      setTimeout(function () {
        // window.location.reload();
      }, 500);
      address = accounts[0];
      localStorage.clear();
      dispatch({ type: USER_ADDRESS, payload: "" });
      dispatch({ type: USER_WALLET, payload: "" });
      dispatch({ type: LOGIN, payload: "" });
      dispatch({ type: SIGNUP, payload: "" });
      // window.location.replace("/");

      return address;
    });

    // if (type == "WALLETCONNECT") {
    //   address = await Web3Service?.getAccount(type);
    //   dispatch({ type: USER_ADDRESS, payload: address });
    //   dispatch({ type: USER_WALLET, payload: type });
    //   await provider.on("accountsChanged", async function (accounts) {
    //     setTimeout(function () {
    //       window.location.reload();
    //     }, 500);
    //     address = accounts[0];
    //     localStorage.clear();
    //     dispatch({ type: USER_ADDRESS, payload: "" });
    //     dispatch({ type: USER_WALLET, payload: "" });
    //     dispatch({ type: LOGIN, payload: "" });
    //     dispatch({ type: SIGNUP, payload: "" });
    //     window.location.replace("/");

    //     return address;
    //   });
    // }

    let chainId = await web3Instance.eth.getChainId();
    if (chainId == BINANCE_CHAIN_ID) {
      if (isInstalled == "BINANCE_WALLET") {
        address = await Web3Service.getAccount(type);
        await BinanceChain.on("accountsChanged", async function (accounts) {
          setTimeout(function () {
            window.location.reload();
          }, 500);
          address = accounts[0];

          dispatch({ type: USER_ADDRESS, payload: "" });
          dispatch({ type: USER_WALLET, payload: "" });

          return address;
        });
      } else if (isInstalled == "METAMASK_WALLET") {
        address = await Web3Service.getAccount(type);
        dispatch({ type: USER_ADDRESS, payload: address });
        dispatch({ type: USER_WALLET, payload: type });
      } else if (isInstalled === "TRUST_WALLET") {
        const result = Boolean(
          (ethereum && ethereum.isMetaMask) || BinanceChain
        );
        if (!result) {
          const web3 = await new Web3(window.web3.currentProvider);
          const accounts = await web3.eth.getAccounts();
          address = accounts[0];

          dispatch({ type: USER_WALLET, payload: type });
          return dispatch({ type: USER_ADDRESS, payload: address });
        }
        return address;
      }
      if (address) {
        await dispatch({ type: USER_ADDRESS, payload: address });
        dispatch({ type: USER_WALLET, payload: type });
        return address;
      } else {
        return address;
      }
    } else {
      let res = await switchNetwork(chainId);
      if (res) {
        alert("Binance network added successfully.Please connect now.");
        window.location.reload();
      } else {
        window.location.reload();
      }
    }
    dispatch({ type: USER_ADDRESS, payload: address });
    return address;
  } catch (error) {
    return error;
  }
};

// export const disconnectAddress = () => async (dispatch, getState) => {
//   try {
//     let type = Session.getLoginSession();
//     if (type === "WALLETCONNECT") {
//       await provider.disconnect();
//     } else {
//       if (type === "USER_ADDRESS") {
//         await provider.disconnect();
//       }
//     }
//     dispatch({ type: USER_ADDRESS, payload: "" });
//     dispatch({ type: CLAIMED_ADDRESS, payload: "" })
//   } catch (error) {
//     return error;
//   }
// };
