import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import ifancythat_colour from "../../assets/images/ifancythat-full-colour.svg";
import icon_phone from "../../assets/images/icon-phone.svg";
import logo_phone from "../../assets/images/logo_phone.svg";
import icon_flight from "../../assets/images/icon-flight.svg";
import logo_flight from "../../assets/images/logo_flight.svg";
import icon_watch from "../../assets/images/icon-watch.svg";
import logo_watch from "../../assets/images/logo_watch.svg";
import icon_date from "../../assets/images/icon-date.svg";
import logo_date from "../../assets/images/logo_date.svg";
import icon_hotel from "../../assets/images/icon-hotel.svg";
import logo_hotel from "../../assets/images/logo_hotel.svg";
import icon_car from "../../assets/images/icon-car.svg";
import logo_hirecar from "../../assets/images/logo_hirecar.svg";

import ifacnyflight from "../../assets/images/ifacnyflight.jpg";
import ifacywatch from "../../assets/images/ifacywatch.jpg";
import ifancycar from "../../assets/images/ifancycar.jpg";
import ifancydate from "../../assets/images/ifancydate.jpg";
import ifancyhotel from "../../assets/images/ifancyhotel.jpg";
import ifancyphone from "../../assets/images/ifancyphone.jpg";

import CryptoWallet from "../../assets/images/CryptoWallet.svg";
import bg_1 from "../../assets/images/wallet/bg_1.svg"
import VPNLayer from "../../assets/images/VPNLayer.svg";
import bg_2 from "../../assets/images/wallet/bg_2.svg"
import Airdrops from "../../assets/images/Airdrops.svg";
import Chat from "../../assets/images/Chat.svg";
import Swap from "../../assets/images/Swap.svg";
import Launchpad from "../../assets/images/Launchpad.svg";
import DebitCard from "../../assets/images/DebitCard.svg";

import One from '../../assets/images/i1.svg'
import Two from '../../assets/images/i2.svg'
import Three from '../../assets/images/i3.svg'
import Four from '../../assets/images/i4.svg'
import Five from '../../assets/images/i5.svg'
import Six from '../../assets/images/i6.svg'
import Cards from "../../assets/images/crypto-cards.svg";
import MultiPro from '../../assets/images/multiproduct.png';
import video1 from "../../assets/video/backgroundVideo.mp4"
import { NavHashLink } from 'react-router-hash-link';

import apple from "../../assets/images/apple.e8246da2.svg";
import playstore from "../../assets/images/playstore.dc059237.svg";

import "./ImageWrap.scss"

const Catagory = (props) => {
  const catagory = [
    {
      mainImg: icon_phone,
      brandLogo: logo_phone,
      link: "https://ifancyaphone.com/",
    },
    {
      mainImg: icon_flight,
      brandLogo: logo_flight,
      link: "https://ifancyaflight.com/",
    },
    {
      mainImg: icon_watch,
      brandLogo: logo_watch,
      link: "http://www.ifancyawatch.com/",
    },
    {
      mainImg: icon_date,
      brandLogo: logo_date,
      link: "https://ifancyadate.com/",
    },
    {
      mainImg: icon_hotel,
      brandLogo: logo_hotel,
      link: "https://ifancyahotel.com/",
    },
    {
      mainImg: icon_car,
      brandLogo: logo_hirecar,
      link: "https://ifancyahirecar.com/",
    },
  ];

  const types = [
    // {
    //   heading: <>
    //     <p className="mb-1">Secure and quick transactions</p>
    //     <h2 className="font-weight-bold mb-4">Crypto Cards</h2>
    //   </>,
    //   description:
    //     <>
    //       <p>fancythatcard enables you to seamlessly pay for your hotel booking, flight booking, car hire, and more. Get your card today and simplify your payments.</p>
    //       <a href="/cards" className="btn theme-btn mt-3">Read More</a>
    //     </>,
    //   image: <><img src={Cards} style={{ width: '100%' }}></img></>,
    //   grndimgbox: false

    // },
    {
      heading: <>
        <h2 className="font-weight-bold mb-4">One App <br />Multiple Products</h2>
      </>,
      description:
        <div className="multiproducts-sec">
          <p>fancythatwallet is the 1st mobile app in the world to provide multiple services. User can purchase and store cryptos into the Virtual/Physical card and can use for availing the services from the app by spending the crypto and FNCY token.</p>
          <ul>
            <li>
              <NavHashLink to="/apps#ff" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
              ><i><img src={One} alt="" /></i> ifancyadate</NavHashLink>
            </li>
            <li>
              <NavHashLink to="/apps#bb" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
              ><i><img src={Two} alt="" /></i> ifancyawatch</NavHashLink>
            </li>
            <li>
              <NavHashLink to="/apps#aa" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
              ><i><img src={Three} alt="" /></i> ifancyaflight</NavHashLink>
            </li>
            <li>
              <NavHashLink to="/apps#dd" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
              ><i><img src={Four} alt="" /></i> ifancyaphone</NavHashLink>
            </li>
            <li>
              <NavHashLink to="/apps#cc" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
              ><i><img src={Five} alt="" /></i> ifancyahirecar</NavHashLink>
            </li>
            <li>
              <NavHashLink to="/apps#ee" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
              ><i><img src={Six} alt="" /></i> ifancyahotel</NavHashLink>
            </li>
          </ul>
          <div class="text-center"><a className="btn theme-btn mt-3 text-center" href="/apps">Read More</a></div>

        </div>,
      image: <><img src={MultiPro} style={{ width: '100%' }}></img></>,
      grndimgbox: false

    },

    {
      heading: <>
        <p className="mb-1">Decentralized - Send - Receive - Secure </p>
        <h2 className="font-weight-bold mb-4">Crypto Wallet Non-Custodial</h2>
      </>,
      description:
        <>
          <p>Portal to cryptocurrency destination, Expand access to decentralized finance (DeFI), store with Fancythatwallet over 13000 cryptos: ERC-20 / BEP-20 / FTM / GoChain / Polygon / Avax / One / HECO / Klaytn </p>
          <ul>
            <li>Stay private & secure storing your privacy coins</li>
            <li>Self-control for private key to manage assets</li>
            <li>User-Friendly Interface that is easy to navigate</li>
          </ul>
        </>,
      image: <>
        <div className="imgDiv cryptoImg">
          <img src={bg_1} alt="bg" className="imgBg" />
          <img src={CryptoWallet} alt="img" className="imgMain" />
        </div>
      </>,
      grndimgbox: false,
      button:
        <div class="storeBtns__wrap justify-content-start">
          <a class="storeBtns__btn" target="_blank" href="https://apps.apple.com/gb/app/fancythatwallet/id1640049529"> <img src={apple} /> <span><small>Download on the</small>App Store</span> </a>
          <a class="storeBtns__btn" target="_blank" href="https://play.google.com/store/apps/details?id=com.fancythattoken"> <img src={playstore} /> <span><small>Download on the</small>Google Play</span> </a>
        </div>
    },
    {
      heading: <>
        <p className="mb-1">Proxying - Authentication - Network Scalability </p>
        <h2 className="font-weight-bold mb-4">VPN Layer 2 Encryption</h2>
      </>,
      description:
        <>
          <p>Provide safety through anonimity, disguise for network data helps keep data VPN users
            safe, because anyone looking to steal information would be getting the VPN server data.</p>

          <h4 className="font-weight-bold mt-4">Hides your Private information</h4>
          <p>Websites and apps can constantly track your online activity, analyzing the data they collect.A VPN can prevent web browsers and others from accessing your connection, helping to keep information you send and receive anonymous and secure.</p>

          <h4 className="font-weight-bold mt-4">Provide network scalability</h4>
          <p>As organizations grow, so do the costs of building a dedicated private network.Internet - based VPNs can allow businesses to tap into network lines and network capability already available, potentially giving remote and international locations in particular better reach and service quality.</p>

          <h4 className="font-weight-bold mt-4">Strong Encryption</h4>
          <p>Fancythatwallet VPN uses Advanced Encryption Standards, also encrypts your traffic between
            secure VPN servers and your device, so that it can’t be read by third parties in between.</p>
        </>,
      image: <>
        <div className="imgDiv vpnImg">
          <img src={bg_2} alt="bg" className="imgBg" />
          <img src={VPNLayer} alt="img" className="imgMain" />
        </div>
      </>,
      grndimgbox: false

    },
    {
      heading: <>
        <p className="mb-1" >Get Free Tokens - No Risk - Increase Value</p>
        <h2 className="font-weight-bold mb-4">Airdrops Rewards Loyality</h2>
      </>, description: <>
        <p>Adopters will have their loyalty rewarded participating in different activities, while projects will benefit from awareness for their token sales, stimulate trade, or encourage tokens holding with minimal costs.</p>
        <p>In a crypto airdrop, a new crypto project gives cryptocurrency to new users for free, or in exchange for a simple task like sharing a social media post.</p>
        <p>Most crypto enthusiasts love airdrops simply because they see them as free money.</p>
        <ul>
          <li>Joining Airdrops is your risk-free ticket for entering the crypto world</li>
          <li>The coins you get are FREE</li>
          <li>Crypto Airdrops can increase in value</li>
          <li>You'll have the opportunity to be a part of new and exciting projects</li>
          <li>Learn & earn by joining airdrops</li>
        </ul>
      </>,
      image: <><img src={Airdrops} style={{ width: '100%' }}></img></>,
      grndimgbox: false

    },
    {
      heading: <>
        <p className="mb-1">Discrete - No Phone Permissions - No third Parties </p>
        <h2 className="font-weight-bold mb-4">Chat LE2E Encrypted Privacy</h2>
      </>,
      description: <>
        <p>E2E encryption completely encodes data, This means that there is always a secure connection between two users on the internet or between devices and storage locations.</p>
        <p>Encryption via two endpoints is one of the most secure methods for data exchange. Information is encoded from one end to the other with a continuous key.</p>
        <p>Fancythatwallet is Easy to use allowing the users to Chat private, transfer Crypto in the chat screen and Create Group messaging.</p>
        <ul>
          <li>Fancythatwallet Chat is Easy to use</li>
          <li>Doesn't ask you for any LIFE Permissions like: Phone number, Location, Microphone,contact access, Media access.</li>
          <li>You can login Anonymously</li>
          <li>Secure Secreen (Cannot be Captured)</li>
          <li>No Third Parties, No Ads, No Trackers</li>
        </ul>
        <p>Simply login with a username and password</p>
      </>,
      image: <><img src={Chat} style={{ width: '100%' }}></img></>,
      grndimgbox: false

    },
    {
      heading: <>
        <p className="mb-1">Security - Control Over your Funds </p>
        <h2 className="font-weight-bold mb-4">Swap Decentralized Confidential</h2>
      </>,
      description: <>
        <h4 className="font-weight-bold mt-4">What is DEX ?</h4>
        <p>Decentralized exchanges (DEX) allows users to Swap crypto through direct peer-to-peer cryptocurrency transactions over a secure online platform without an intermediary.</p>

        <h4 className="font-weight-bold mt-4">No Counterparty Risk</h4>
        <p>Because users don't have to transfer their assets to an exchange, decentralized exchanges can reduce risks of theft and loss of funds due to hacks.</p>

        <h4 className="font-weight-bold mt-4">Preventing Market Manipulation</h4>
        <p>Due to their nature of allowing for the peer-to-peer exchange of cryptocurrencies, decentralized exchanges prevent market manipulation</p>
      </>,
      image: <><img src={Swap} style={{ width: '100%' }}></img></>,
      grndimgbox: false

    },
    {
      heading: <>
        <p className="mb-1">Fair Fundrasing - Fast Trading - Immediate Liquidity </p>
        <h2 className="font-weight-bold  mb-4">Launchpad IDO Multi Chain</h2>
      </>,
      description: <>
        <p>Fancythatwallet Launchpad focuses providing a reliable platform that can accommodate various crypto projects and investors' needs.</p>
        <p>The features offered by IDO Launchpad enable users to invest in crypto projects of the highest quality and also enabling projects to raise capital in a Decentralized way.</p>
        <p>Multi cross chained, and fully interoperable environment based on a multi-blockchain architecture with the most powerful and fairest distribution algorithms.</p>
        <p>Fancythatwallet Launchpad is the one-stop ideal location for decentralized fundraising for the highest quality projects which add real value to the world and that have a sustainable business model and proper development architectures.</p>
      </>,
      image: <><img src={Launchpad} style={{ width: '100%' }}></img></>,
      grndimgbox: false

    },
    {
      heading: <>
        <p className="mb-1">Transactions - Balance Available </p>
        <h2 className="font-weight-bold mb-4">Debit Card</h2>
      </>,
      description:
        <>
          <p>Accelerated, seamless buying and selling of crypto</p>
          <p>With the option of debit cards, Fancythatwallet aims to streamline the process of buying and selling of cryptocurrency. </p>
          <p>It empowers users to utilize their debit cards to buy their desired cryptocurrency in exchange for fiat. Similarly, users can sell their cryptocurrency for fiat and get the respective amount debited to their accounts. </p>
          <p>The platform will be reinforced with a liquidity management module to ensure sufficient liquidity so as to ensure quick transactions, without any latency. </p>
          <p>Furthermore, Fancythatwallet will use debit card provider APIs to issue personal branded debit cards to the users.</p>
        </>,

      image: <><img src={DebitCard} style={{ width: '100%' }}></img></>,
      grndimgbox: false

    },
  ]

  const products = [
    {
      heading: <h2 className="font-weight-bold mb-4">ifancyaphone</h2>,
      description: <>
        <p><b>ifancyaphone</b> enables you to compare the best prices available for your
          old phone with the best reviews – offering you an easy and reliable service.
          If you are looking to purchase, we have access to thousands of phones at brilliant prices;
          meaning you will never pay too much for the phone you want!</p>
        <p><b>ifancyaphone</b> will even soon accept our <b>fancythattoken</b> to allow you to spend these
          tokens on the phone you fancy.</p>
      </>,
      image: ifancyphone,
      grndimgbox: true

    },

    {
      heading: <h2 className="font-weight-bold mb-4">ifancyaflight</h2>,
      description: <>
        <p>The holiday starts before you even get on the plane; allow <b>ifancyaflight</b> to do all the hard work for you!</p>
        <p>We are working with the world’s biggest brands to give you easy access to all flight times and destinations. <b>ifancyaflight</b> will even offer you lastminute deals, presenting the best prices to amazing
          destinations around the world. To earn even greater rewards, soon you will be able to book your dream holiday using crypto or our <b>fancythattoken</b>.</p>
      </>,

      image: ifacnyflight,
      grndimgbox: true

    },
    {
      heading: <h2 className="font-weight-bold mb-4">ifancyawatch</h2>,
      description: <>
        <p>If you’re in the market to invest or just update your timepiece, <b>ifancyawatch</b> works with the biggest companies to offer you the best deals on any watch you browse.</p>
        <p>Soon <b>ifancyawatch</b> will allow you to purchase your new accessory with crypto and our
          <b> fancythattoken</b>, earning you a further discount!</p>
      </>,
      image: ifacywatch,
      grndimgbox: true


    },
    {
      heading: <h2 className="font-weight-bold mb-4">ifancyadate</h2>,
      description: <> <p>Our app is designed to give your dating
        life a new spark, our functions allow you to alter whose profiles you are seeing to give you
        the best chance of finding your perfect match. Taking dating seriously doesn’t have to be
        daunting.</p>
        <p><b>ifancyadate</b> will encourage you to talk and meet those on the app, prioritising
          the opportunity of relationship and falling in love. Launching soon with many new features
          to guarantee you a date. Coming soon <b>ifancyadate</b> will allow you to purchase all upgrades
          with our <b>fancythattoken</b>.</p>
      </>,
      image: ifancydate,
      grndimgbox: true


    },
    {
      heading: <h2 className="font-weight-bold mb-4">ifancyahotel</h2>,
      description: <>
        <p>Whether for business or pleasure, <b>ifancyahotel</b> will find the correct accommodation for you. <b>ifancyahotel</b> will reveal to you hotels in all
          locations, handpicking our favourites to inspire your next holiday destination.</p>
        <p>With <b>ifancyahotel</b>, thousands of stunning hotels are simply a click away. We are excited to announce,
          soon <b>ifancyahotel</b> will allow you to purchase with our <b>fancythattoken</b>.</p>
      </>,
      image: ifancyhotel,
      grndimgbox: true


    },
    {
      heading: <h2 className="font-weight-bold mb-4">ifancyahirecar</h2>,
      description:
        <>
          <p>Working with major car hire brands on the market to bring you the best deals on any vehicle you desire.</p>
          <p><b>ifancyahirecar</b> offers car, coach and even motor home hires for wherever you are in the
            world. It will even soon accept our <b>fancythattoken</b> to allow you to spend these tokens on
            the vehicle you fancy.</p>
        </>,
      image: ifancycar,
      grndimgbox: true

    },


  ];

  return (
    <section>
      <div className="row grad-background py-4">
        <div className="col-12 col-md-7 mx-auto text-center">
          <a
            data-aos="fade-up"
            data-aos-duration="1000"
            href="http://www.ifancythat.com"
            target={"_blank"}
            rel="noreferrer"
          // style={{marginBottom:"10px"}}
          >
            {" "}
            <img src={ifancythat_colour} width="250px" className="mx-auto" />
          </a>
          <p></p>
          <h4
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-white text-center font-weight-bold"
          >
            Vision
          </h4>
          <p
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-white text-center mt-3"
          >
            {" "}
            <b>ifancythat’s</b> vision is to create a single wallet and
            connect it with every service available on the <b>ifancythat</b> platform
            to enable users to purchase their favourite products using
            <b> fancythattoken</b>. Each time a product is purchased using
            <b> fancythattoken</b>, the user will receive a discount on the final
            price.  A percentage of the token used to purchase a product will be burned, reducing the circulation of FNCY token, which increases the value of the token for current token holders
          </p>
          <h4
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-white text-center font-weight-bold"
          >
            Mission
          </h4>
          <p
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-white text-center mt-3"
          >
            {" "}
            <b>ifancythat's</b> mission is to provide a comprehensive platform
            where users can perform multiple operations such as flight
            booking, hotel booking, buying, selling cell phones and watches,
            hiring a vehicle, playing casino games and more. The features of the wallet will include Airdrop opportunities, VPN layer 2 encryption, Chat encrypted privacy, Launchpad IDO opportunities and an exchange which enables you to trade your crypto assets
          </p>
        </div>
      </div>

      <div class="video-wrapper">
        <video className="card-video" playsinline autoPlay muted loop >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="catagory-section text-center mt-0">
          <div className="mt-0 row">
            <div className="justify-content-center col-12 col-md-7 mx-auto text-center">

              <>
                <p className="mb-1">Secure and quick transactions</p>
                <h2 className="font-weight-bold mb-4">Crypto Cards</h2>
              </>
              <>
                <p>fancythatcard enables you to seamlessly pay for your hotel booking, flight booking, car hire, and more. fancythatcard is coming soon to help simplify your payments.</p>
                <a href="/cards" className="btn theme-btn mt-3">Read More</a>
              </>
            </div>
          </div>
        </div>
      </div>

      <div className="container catagory-container">
        <div className="catagory-section">
          {types.map((data, index) => (
            <>
              {index % 2 !== 0 ? (
                <div key={index}>

                  <div data-aos="fade-right"
                    data-aos-duration="2000"
                    className="row">
                    <div className="col-12 col-md-12 col-lg-6 my-4 d-flex align-items-center">
                      {data.image}
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 align-self-center">
                      {data.heading}
                      {data.description}
                      {data.button}
                    </div>
                  </div>
                </div>
              ) : (
                <div key={index}>

                  <div data-aos="fade-left"
                    data-aos-duration="2000"
                    className="row row-revers">
                    <div className="col-12 col-md-12 col-lg-6 align-self-center">
                      {data.heading}
                      {data.description}
                      {data.button}
                    </div>

                    <div className="col-12 col-md-12 col-lg-6 my-4 d-flex align-items-center">
                      {data.image}
                    </div>

                  </div>
                </div>
              )}
            </>
          ))}
        </div>


        <div className="catagory-section">
          {products.map((data, index) => (<>
            <div >
              {index % 2 === 0 ? (
                <div key={index}>

                  <div data-aos="fade-right"
                    data-aos-duration="2000"
                    className="row">
                    <div className="col-12 col-md-6 my-4 d-flex align-items-center">
                      <div className={data.grndimgbox ? "largebox left-side" : ""}>
                        <div className={data.grndimgbox ? "grndimgbox" : ""}>
                          <div className={data.grndimgbox ? "gridinnbx" : ""}>
                            <img src={data.image} className={data.grndimgbox ? "center-img" : ""} style={!data.grndimgbox ? { width: '100%' } : {}}></img>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      {data.heading}
                      {data.description}
                    </div>
                  </div>
                </div>

              ) : (
                <div key={index}>

                  <div data-aos="fade-left"
                    data-aos-duration="2000"
                    className="row row-revers">
                    <div className="col-12 col-md-6">
                      {data.heading}
                      {data.description}
                    </div>

                    <div className="col-12 col-md-6 my-4 d-flex align-items-center">
                      <div className={data.grndimgbox ? "largebox left-side" : ""}>
                        <div className={data.grndimgbox ? "grndimgbox" : ""}>
                          <div className={data.grndimgbox ? "gridinnbx" : ""}>
                            <img src={data.image} className={data.grndimgbox ? "center-img" : ""} style={!data.grndimgbox ? { width: '100%' } : {}}></img>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              )}

            </div>

          </>))}
        </div>
      </div>
    </section >
  );
};

export default Catagory;
