import React, { useState, useEffect } from "react";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import Connect from "../../components/Modal/Connect";
import logo_png from "../../assets/images/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { CommonService } from "../../util/commonService";
import { disconnectAddress } from "../../redux/_actions/user.action";
import { toast } from "../../components/common/Toast";
import CheckBox from "../../components/Checkbox/CheckBox";
import { register_api } from "../../redux/_actions/apis.action";
import {
  LOGIN,
  SIGNUP,
  USER_ADDRESS,
  USER_WALLET,
} from "../../redux/constant.actionTypes";
import ConnectStage from "../../components/common/ConnectStage";
import ProcessModal from "../../components/Modal/ProcessModal/ProcessModal";
import { useSearchParams } from "react-router-dom";

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name Required")
    .trim("The name cannot include leading and trailing spaces")
    .strict(true),
  useremail: Yup.string().email("Invalid email").required("Email Required"),
  confirmemail: Yup.string().oneOf(
    [Yup.ref("useremail"), null],
    "Email Must Match"
  ),
});
const Register = (props) => {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [connectAfterLogin, setConnectAfterLogin] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [disable, setDisabled] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const dispatch = useDispatch();
  const [processshow, processsetShow] = useState(false);
  const processhandleClose = () => processsetShow(false);
  const processhandleShow = () => processsetShow(true);

  const metamaskAddress = useSelector((state) => state.user?.walletAddress);
  let [searchParams, setSearchParams] = useSearchParams()

  const [referralcode, setReferralcode] = useState(searchParams.get("referral_code"))

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const disconnectAcc = async () => {
    await dispatch(disconnectAddress());
    // toast.success("Logout Successfully.");
    // window.location.reload();
  };
  const connect = () => {
    setShow(true);
  };

  const registerFunc = async (values) => {
    localStorage.setItem("reglogin", "1");

    if (!metamaskAddress) {
      setTimeout(() => {
        setDisabled(true);
        // toast.error("Please connect first");
      }, 100);
      setTimeout(() => {
        setDisabled(false);
      }, 2000);
    }
    let data = {
      name: values?.name,
      useremail: values?.useremail,
      referral_code: referralcode || ""
    };

    setRegisterData(data);
    setConnectAfterLogin(true);
  };

  return (
    <section className="contact-us signup text-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <h1 className="text-center mb-5 font-weight-bold text-white">
              {" "}
              Register Here
            </h1>
          </div>
          {/* {localStorage.getItem("reglogin") == "1" ? (
            <button
              className="btn m-0 mb-2 theme-btn btn-registration"
              onClick={() => {
                localStorage.setItem("loginreg", "0");
                navigate("/login");
                window.location.reload();
              }}
            >
              Back
            </button>
          ) : null} */}
          <div className="col-12 col-md-6 mx-auto">
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="contact-card registerCard py-5"
            >
              {
                // localStorage.getItem("reglogin") !== "1"
                !connectAfterLogin ? (
                  <Formik
                    initialValues={{
                      name: "",
                      useremail: "",
                      confirmemail: "",
                    }}
                    validationSchema={SignupSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values, { resetForm }) => {
                      registerFunc(values);
                      // localStorage.setItem(
                      //   "regemail",
                      //   JSON.stringify(values.useremail)
                      // );
                      // localStorage.setItem(
                      //   "regname",
                      //   JSON.stringify(values.name)
                      // );
                      resetForm({ values: "" });
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit} className="registerForm">
                        <div className="row">
                          <div className="col-12 ">
                            <h2 className="font-weight-bold mb-4 text-white">
                              Your personal details:
                            </h2>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <Field
                                name="name"
                                htmlFor="name"
                                id="name"
                                require="*"
                                type="text"
                                label="Full Name"
                                onChange={handleChange}
                                value={values.name}
                                placeholder="Full Name"
                                error={errors.name}
                                maxLength={45}
                                className="form-control"
                              />
                              <i className="fa fa-user"></i>
                              <p className="text-danger mt-3">{errors.name}</p>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="form-group">
                              <Field
                                name="useremail"
                                htmlFor="useremail"
                                id="useremail"
                                require="*"
                                type="email"
                                label="Email"
                                onChange={handleChange}
                                value={values.useremail}
                                placeholder="Enter Email"
                                error={errors.useremail}
                                maxLength={45}
                                className="form-control"
                              />
                              <i className="fa fa-envelope"></i>
                              <p className="text-danger mt-3">
                                {errors.useremail}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="form-group">
                              <Field
                                name="confirmemail"
                                htmlFor="confirmemail"
                                id="confirmemail"
                                require="*"
                                type="email"
                                label="Confirm Email"
                                onChange={handleChange}
                                value={values.confirmemail}
                                placeholder="Confirm Email"
                                error={errors.confirmemail}
                                maxLength={45}
                                className="form-control"
                              />
                              <i className="fa fa-envelope"></i>
                              <p className="text-danger mt-3">
                                {errors.confirmemail}
                              </p>
                            </div>
                          </div>
                          <Col sm={12}>
                            <p className="text-white ">
                              Get early access to the fancythattoken ICO.
                            </p>
                            <p className="text-white ">
                              We will require your full name, email address and
                              wallet connection.
                            </p>

                            <div className="col-12 px-0">
                              <div className="form-group">
                                Referral Code
                                <Field
                                  name="referralcode"
                                  htmlFor="Referral code"
                                  require="*"
                                  type="text"
                                  onChange={e => { setReferralcode(e.target.value) }}
                                  placeholder="Referral Code"
                                  value={referralcode}
                                  className="form-control"
                                  maxLength="6"
                                />
                              </div>
                            </div>

                            <Row className="mt-5">
                              <Col sm={12} md={12} className="text-center">
                                <button
                                  text="Submit"
                                  type="submit"
                                  className="btn mr-3 theme-btn btn-registration mb-2"
                                  // onClick={clickFunction()}
                                  disabled={disable}
                                >
                                  Next
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <ConnectStage
                    type={"register"}
                    Data={registerData}
                    handleShow={handleShow}
                    disconnectAcc={disconnectAcc}
                  />
                )
              }
              <a className="centerTxt" onClick={processhandleShow}>
                Learn how to Register
              </a>
            </div>
          </div>
          <ProcessModal
            show={processshow}
            handleShow={processhandleShow}
            handleClose={processhandleClose}
          />
        </div>
      </div>
    </section>
  );
};

export default Register;
