import {
  USER_ADDRESS,
  USER_WALLET,
  SIGNUP,
  ADMINLOGIN,
  LOGIN,
  TOKEN_EXPIRE_TIME,
  USER_TOKEN_EXPIRE_TIME,
} from "../constant.actionTypes";
import { SET_LOADING } from "../_actions/loader";

const initialState = {
  walletAddress: "",
  connectedWallet: "",
  signUp: "",
  AdminLogin: "",
  isLoading: "",
  login: "",
  token_expiration: "",
  user_token_expiration: "",
};

export default function user(state = initialState, { type, payload }) {
  switch (type) {
    case USER_ADDRESS:
      return { ...state, walletAddress: payload };
    case USER_WALLET:
      return { ...state, connectedWallet: payload };
    case SIGNUP:
      return { ...state, signUp: payload };
    case LOGIN:
      return { ...state, login: payload };
    case ADMINLOGIN:
      return { ...state, AdminLogin: payload };
    case SET_LOADING:
      return { ...state, isLoading: payload };
    case TOKEN_EXPIRE_TIME:
      return { ...state, token_expiration: payload };
    case USER_TOKEN_EXPIRE_TIME:
      return { ...state, user_token_expiration: payload };
    default:
      return state;
  }
}
