import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthGuard = () => {
  const userAuthenticated = useSelector((state) => state.user?.login?.token);
  const navigate = useNavigate();

  // alert("userAuthenticated"+ userAuthenticated);
  return userAuthenticated ? <Outlet /> : null;
  // return <Outlet />;
};

export default AuthGuard;
