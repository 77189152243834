import React, { useState, useEffect } from "react";
import { getUserProfile } from "../../redux/_actions/apis.action";
import { useDispatch, useSelector } from "react-redux";
import SideBar from '../../components/Sidebar'
import banner_bg from '../../assets/images/banner-bg.svg'
import './index.css'

const MyProfile = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [profileData, setProfileData] = useState();
  const [passwordVisible, setPasswordVisible] = useState(true);
  const profileFunc = async () => {
    const result = await dispatch(getUserProfile());
    setProfileData(result?.data?.userProfile);
  };

  useEffect(() => {
    profileFunc();
  }, []);

  return (
    <div className="Dashboard p-3" style={{ backgroundImage: `url(${banner_bg})` }}>
      <div className="container">
        <div className="card mx-0 m-4 p-4">
          <form>
            {/* <div className="form-row">
              <div className="form-group col-md-6">
                <label for="firstName">First Name</label>
                <input type="text" className="form-control" id="firstName" placeholder="First Name" />
              </div>
              <div className="form-group col-md-6">
                <label for="lastName">Last Name</label>
                <input type="text" className="form-control" id="lastName" placeholder="Last Name" />
              </div>
            </div> */}
            <div className="form-group">
              <label for="inputEmail4">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="inputEmail4"
                placeholder="Full Name"
                value={profileData?.username}
              />
            </div>
            <div className="form-group">
              <label for="inputEmail4">Email</label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Email"
                value={profileData?.email}
              />
            </div>
            <div className="form-group ">
              <label for="inputEmail4">Wallet Address</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="Password"
                  value={profileData?.wallet_address}
                />
              </div>
            </div>

            <hr />
            {/* <div className="form-row">
            <div className="form-group col-md-6">
              <label for="inputCity">City</label>
              <input type="text" className="form-control" id="inputCity" />
            </div>
            <div className="form-group col-md-4">
              <label for="inputState">State</label>
              <select id="inputState" className="form-control">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <div className="form-group col-md-2">
              <label for="inputZip">Zip</label>
              <input type="text" className="form-control" id="inputZip" />
            </div>
          </div> */}

            {/* <button type="submit" className="btn theme-btn">
              Save
            </button> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
