import React, { useState, useEffect } from "react";
import "./Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import logo from "../../assets/images/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { admin_login, isAdminLogin } from "../../redux/_actions/apis.action";
import { ADMIN_NAME, rootName } from "../../constant";
import { TOKEN_EXPIRE_TIME } from "../../redux/constant.actionTypes";
import moment from "moment";

function AdminLogin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const is_Admin_Auth = useSelector((state) => state?.user?.AdminLogin?.token);

  useEffect(() => {
    if (is_Admin_Auth) {
      navigate(`/${ADMIN_NAME}/AdminDashboard`);
    }
  }, []);

  const _adminLogin = async () => {
    let data = {};
    data.email = email;
    data.password = password;
    let adminlogin = await dispatch(isAdminLogin(data));
    if (adminlogin?.status === 200) {
      let time = Date.now() + 3600 * 1000 * 24;
      dispatch({ type: TOKEN_EXPIRE_TIME, payload: time });
      navigate(`/${ADMIN_NAME}/AdminDashboard`);
    }
  };

  return (
    <div className="body-bg">
      <div className="AdminLogin">
        <Form className="AdminCard logForm">
          <div className="mb-4 text-center">
            {" "}
            <img src={logo} alt="logo" width="250px" />
          </div>
          <h4>Admin Panel</h4>
          <Form.Group className="mb-4" controlId=" ">
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-4 passwordFild" controlId=" ">
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button className="btn theme-btn" onClick={() => _adminLogin()}>
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default AdminLogin;
