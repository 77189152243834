import BigNumber from "big-number/big-number";
// import { Provider } from "react-redux";
import { EthereumService } from "../../CommonServices/EthereumService";
import { initializeProvider } from "../../CommonServices/wallectConnectProvider";
import { toast } from "../../components/common/Toast";
import { IFANCY_PUBLIC_ADDRESS } from "../../constant";
import { bnbService } from "../../services/bnbService";
import { CommonService } from "../../services/commonService";
import { LOGIN, SIGNUP, USER_ADDRESS } from "../constant.actionTypes";
import { setLoader, stopLoader } from "./loader";

let provider;

export const disconnectAddress = () => async (dispatch, wallet) => {
  try {
    if (!provider) {
      provider = await initializeProvider();
    }

    if (wallet === "WALLETCONNECT") {
      // provider.close();
      provider.kill();
      dispatch({ type: USER_ADDRESS, payload: "" });
      dispatch({ type: LOGIN, payload: "" });
      dispatch({ type: SIGNUP, payload: "" });
    }
    dispatch({ type: USER_ADDRESS, payload: "" });
    dispatch({ type: LOGIN, payload: "" });
    dispatch({ type: SIGNUP, payload: "" });
  } catch (error) {
    return error;
  }
};

export const getUSDTDecimal = (data) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      let result = await EthereumService.callCommonABIMethod(
        ["decimals"],
        [],
        data,
        "Common"
      );
      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
    }
  };
};

export const calculateTokens = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));
      let result = await EthereumService.callCommonABIMethod(
        ["calculateTokens"],
        [data?.currencyType, data?._amount],
        data,
        "ifancy_finance"
      );

      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
    }
  };
};

export const totalPhases = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));
      let result = await EthereumService.callCommonABIMethod(
        ["totalPhases"],
        [],
        data,
        "ifancy_finance"
      );

      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));

      // toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};

export const phaseInformation = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));

      let result = await EthereumService.callCommonABIMethod(
        ["phaseInfo"],
        [data?.type],
        data,
        "ifancy_finance"
      );

      dispatch(stopLoader(false));

      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      // toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};

export const totalTokenSold = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));

      let result = await EthereumService.callCommonABIMethod(
        ["totalTokenSold"],
        [],
        data,
        "ifancy_finance"
      );

      dispatch(stopLoader(false));

      return result;
    } catch (error) {
      dispatch(stopLoader(false));
    }
  };
};

export const totalTokenSoldUSD = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));

      let result = await EthereumService.callCommonABIMethod(
        ["totalTokenSoldUSD"],
        [],
        data,
        "ifancy_finance"
      );

      dispatch(stopLoader(false));

      return result;
    } catch (error) {
      dispatch(stopLoader(false));

      toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};

export const getBalanceAction = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));
      let result = await EthereumService.callCommonABIMethod(
        ["balanceOf"],
        [data?.walletAddress],
        data,
        "Common"
      );

      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};

export const getAllowanceAction = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));
      let result = await EthereumService.callCommonABIMethod(
        ["allowance"],
        [data?.walletAddress, IFANCY_PUBLIC_ADDRESS],
        data,
        "Common"
      );

      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};

export const getTokenApproval = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));
      let limit = BigNumber(10).power(40).toString();
      let result = await EthereumService.common_send_method(
        ["approve"],
        [IFANCY_PUBLIC_ADDRESS, limit],
        data,
        "Common"
      );

      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};

export const toBuyTokensAction = (data) => async (dispatch, getState) => {
  try {
    let value;
    let result;
    dispatch(setLoader(true));
    if (data?.type == 1) {
      data.value = data?.amount;
      result = await EthereumService.common_send_method(
        ["buyTokens"],
        [data?.type, data?.amount],
        data,
        "ifancy_finance"
      );
    } else {
      result = await EthereumService.common_send_method(
        ["buyTokens"],
        [data?.type, data?.amount],
        data,
        "ifancy_finance"
      );
    }

    dispatch(stopLoader(false));
    return result;
  } catch (error) {
    dispatch(stopLoader(false));
    toast.error(CommonService.getError(error));
    // dispatch(stopLoading());
  }
};

export const lockedTokens = (data) => {
  return async (dispatch, getState) => {
    try {
      let value;
      // let result;
      dispatch(setLoader(true));

      let result = await EthereumService.callCommonABIMethod(
        ["userLockedTokens"],
        [data?.to],
        data,
        "ifancy_finance"
      );
      // dispatch(stopLoading());
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};

export const userUSDValueData = (data) => {
  return async (dispatch, getState) => {
    try {
      let value;
      // let result;
      dispatch(setLoader(true));

      let result = await EthereumService.callCommonABIMethod(
        ["userUSDValue"],
        [data?.to],
        data,
        "ifancy_finance"
      );
      // dispatch(stopLoading());
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};
export const claimedTokens = (data) => {
  return async (dispatch, getState) => {
    try {
      let value;
      // let result;
      dispatch(setLoader(true));

      let result = await EthereumService.callCommonABIMethod(
        ["userClaimedTokens"],
        [data?.to],
        data,
        "ifancy_finance"
      );

      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
      // dispatch(stopLoading());
    }
  };
};

export const lockTokenInformation = (data) => {
  return async (dispatch, getState) => {
    try {
      let value;
      // let result;
      dispatch(setLoader(true));

      let result = await EthereumService.callCommonABIMethod(
        ["lockTokenInfo"],
        [data?.type],
        data,
        "ifancy_finance"
      );

      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
    }
  };
};

export const claimeToken = (data) => {
  return async (dispatch, getState) => {
    try {
      let value;
      dispatch(setLoader(true));

      let result = await EthereumService.common_send_method(
        ["claimLockedTokens"],
        [],
        data,
        "ifancy_finance"
      );

      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      toast.error(CommonService.getError(error));
    }
  };
};

export const approveContract = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));
      let result = await bnbService.getContractApproveInfo(data);
      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      return toast.error(CommonService.getError(error));
    }
  };
};

export function _userMappingData(walletAddress) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));
      const result = await bnbService._getUserMappingData(walletAddress);
      dispatch(stopLoader(false));
      return result;
    } catch (error) {
      dispatch(stopLoader(false));
      return toast.error(CommonService.getError(error));
    }
  };
}
