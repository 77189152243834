import React from 'react'
import ScrollToTop from "../../components/ScrollToTop"

const TermsAndConditions = (props) => {
    const ComnHeading = (props) => {
        return (
            <div className="comnHeading">
                <span className="beforeTxt_Style">{props.beforeTxt}</span>
                <h2>{props.heading}</h2>
                <p>{props.subTxt}</p>
            </div>
        );
    };
    return (
        <section className="Terms-Conditions comnPadd text-white white-papaer-more" >
            <ScrollToTop />
        
            <div className="container">
        <ComnHeading
          beforeTxt="Terms and Conditions"
          heading="Terms and Conditions"
        />
        <h3>
          <strong>AGREEMENT TO TERMS</strong>
        </h3>
        <p>
          These Terms of Use constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity (“you”) and
          IFANCYTHAT] (“we,” “us” or “our”), doing business as fancythattoken
          (“fancythattoken”, “we”, “us”, or “our”), concerning your access to
          and use of the [https://fancythattoken.io] website as well as any
          other media form, media channel, mobile website or mobile application
          related, linked, or otherwise connected thereto (collectively, the
          “Site”).
        </p>
        <p>
          You agree that by accessing the Site, you have read, understood, and
          agree to be bound by all of these Terms of Use. If you do not agree
          with all of these Terms of Use, then you are expressly prohibited from
          using the Site and you must discontinue use immediately.
        </p>
        <p>
          Supplemental Terms of Use or documents that may be posted on the Site
          from time to time are hereby expressly incorporated herein by
          reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Terms of Use at any time and for any
          reason.
        </p>
        <p>
          We will alert you about any changes by updating the “Last updated”
          date of these Terms of Use, and you waive any right to receive
          specific notice of each such change.{" "}
        </p>
        <p>
          It is your responsibility to periodically review these Terms of Use to
          stay informed of updates. You will be subject to, and will be deemed
          to have been made aware of and to have accepted, the changes in any
          revised Terms of Use by your continued use of the Site after the date
          such revised Terms of Use are posted.{" "}
        </p>
        <p>
          {" "}
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country.
        </p>
        <p>
          {" "}
          Accordingly, those persons who choose to access the Site from other
          locations do so on their own initiative and are solely responsible for
          compliance with local laws, if and to the extent local laws are
          applicable. Terms generated with Termly.io
        </p>
        <p>
          The Site is intended for users who are at least 18 years old. Persons
          under the age of 18 are not permitted to register for the Site.{" "}
        </p>
        <p> </p>
        <p> </p>
        <h3>
          <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
        </h3>
        <p>
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property.{" "}
        </p>
        <p>
          {" "}
          The Content and the Marks are provided on the Site “AS IS” for your
          information and personal use only. Except as expressly provided in
          these Terms of Use, no part of the Site and no Content or Marks may be
          copied, reproduced, aggregated, republished, uploaded, posted,
          publicly displayed, encoded, translated, transmitted, distributed,
          sold, licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </p>
        <p>
          {" "}
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non- commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </p>
        <h3>
          <strong>USER REPRESENTATIONS</strong>
        </h3>
        <p>
          {" "}
          By using the Site, you represent and warrant that: (1) you have the
          legal capacity and you agree to comply with these Terms of Use; (2)
          not a minor in the jurisdiction in which you reside; (3) you will not
          access the Site through automated or non-human means, whether through
          a bot, script, or otherwise; (4) you will not use the Site for any
          illegal or unauthorized purpose; (5) your use of the Site will not
          violate any applicable law or regulation.
        </p>
        <p>
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).{" "}
        </p>
        {/* <ul>
                    <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                    <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                    <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                    <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                </ul> */}
        <h3>
          <strong>PROHIBITED ACTIVITIES</strong>
        </h3>
        <p>
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>
        <p>As a user of the Site, you agree not to:</p>
        <ol type="1">
          <li>
            Systematically retrieve data or other content from the Site to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any Content or enforce limitations on the use
            of the Site and/or the Content contained therein.
          </li>
          <li>engage in unauthorized framing of or linking to the Site.</li>
          <li>
            trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords;
          </li>
          <li>
            make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            interfere with, disrupt, or create an undue burden on the Site or
            the networks or services connected to the Site.
          </li>
          <li>
            attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>
            use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            use the Site as part of any effort to compete with us or otherwise
            use the Site and/or the Content for any revenue-generating endeavor
            or commercial enterprise.
          </li>
          <li>
            decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the Site.
          </li>
          <li>
            Attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site, or any portion of the Site.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Site to you.
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            copy or adapt the Site’s software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (Continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Site or modifies, impairs, disrupts, alters, or interferes with the
            use, features, functions, operation, or maintenance of the Site.
          </li>
          <li>
            except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Site, or using
            or launching any unauthorized script or other software.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </li>
          <li>
            Use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </li>
        </ol>
        <h3>
          <strong>USER GENERATED CONTRIBUTIONS</strong>
        </h3>
        <p>
          The Site may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions").
        </p>
        <p>
          Contributions may be viewable by other users of the Site and through
          third- party websites. As such, any Contributions you transmit may be
          treated as non- confidential and non-proprietary. When you create or
          make available any Contributions, you thereby represent and warrant
          that:
        </p>
        <ol type="1">
          <li>
            the creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li>
            you are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Site, and other users of the Site to use your Contributions
            in any manner contemplated by the Site and these Terms of Use.
          </li>
          <li>
            you have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of Each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Site and these Terms of Use.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </li>
          <li>
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li>
            Your Contributions do not advocate the violent overthrow of any
            government or incite, encourage, or threaten physical harm against
            another.
          </li>
          <li>
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            Your Contributions do not contain any material that solicits
            personal information from anyone under the age of 18 or exploits
            people under the age of 18 in a sexual or violent manner.
          </li>
          <li>
            your Contributions do not violate any federal or state law
            concerning child pornography, or otherwise intended to protect the
            health or well-being of minors;
          </li>
          <li>
            Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li>
            Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation.
          </li>
        </ol>
        <h3>
          <strong>IMPORTANT POINTS OF FNCY TOKEN</strong>
        </h3>
        <p>
          <strong>
            BY CLICKING ON THE WEBSITE YOU ACCEPT THESE TERMS AND CONDITIONS AND
            YOU ACKNOWLEDGE THAT YOU HAVE READ AND AGREE TO THE FOLLOWING:
          </strong>
        </p>
        <ol type="a">
          <li>
            You understand and agree that tokens are not intended to constitute
            a digital currency, commodity, security, financial instrument or any
            other form of investment in any jurisdiction. the terms, the
            whitepaper, and all other documents linked to the token offer, the
            acquisition, holding and/or use of tokens do not constitute a
            prospectus or offering document and are not a solicitation for
            investment and do not constitute an offer of securities to the
            public or a collective investment scheme..
          </li>
          <li>
            You understand that, after the date of the acceptance of these
            terms, if there are any regulatory actions, or changes to law or
            regulations imposed which are applicable in relation to
            participation to the token offer, the acquisition of tokens, and/or
            the holding and use of token and/or virtual currencies or other
            tokens: (a) these terms or any other documents may be changed
            significantly by the company to ensure compliance with such
            regulatory requirements and (b) the company retains the right to do
            all that is necessary to be in compliance with such regulatory
            requirements, including but not limited to ceasing operations (if
            necessary).
          </li>
          <li>
            You understand and agree that to the extent that you are not a
            prohibited investor, it is solely up to you to ensure that no prior
            or subsequent approval, notification, registration or license is
            needed to participate in, acquire, hold and/or use tokens and the
            platform. if such is needed, it is solely up to you to obtain such
            prior or subsequent approval, notification, registration or license
            or any other form for the participation in, acquisition, holding
            and/or use of tokens and the platform. and all of this in the
            country where you are a citizen, national, resident or having a
            similar connecting factor, or incorporated, registered or
            effectively managed, and you understand that the company shall not
            in any way be liable for any non-conformity of the above by you.
          </li>
          <li>
            You understand and agree that tokens carry no rights, whether
            express or implied, other than the right to use token on the
            platform.
          </li>
          <li>
            Tokens do not represent or confer any ownership right or stake,
            share or security or equivalent rights, intellectual property rights
            or any other form of participation relating to the company. Tokens
            do not give you any entitlement to acquire any such interest or
            entitlement in respect of the company.
          </li>
          <li>
            You understand and agree that the company shall not be held liable
            for any speculative intention by you or from any third parties who
            attempt to hold the tokens for any other reason.
          </li>
          <li>
            You understand and agree that tokens shall only be acquired from the
            company by means of the token offer unless otherwise agreed by the
            parties. any trading of tokens on the secondary market shall be at
            the sole risk and responsibility of the investor. the company shall
            not be responsible for any derived risks and losses from such
            secondary trading on an exchange or the secondary market by the
            investor.
          </li>
          <li>
            You hereby warrant that you are not a prohibited investor as defined
            in these terms and you understand and agree that tokens are not
            available to prohibited investors. the company retains the right not
            to allow the use of the platform to any prohibited investors.
          </li>
          <li>
            The company reserves the right to refuse or cancel tokens at any
            moment in time and at its sole discretion.
          </li>
        </ol>
        <p>
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.
        </p>
        <h3>
          <strong>CONTRIBUTION LICENSE</strong>
        </h3>
        <p>
          You and the Site agree that we may access, store, process and use any
          information and personal data that you provide following the terms of
          the Privacy Policy and your choices (including settings). By
          submitting suggestions or other feedback regarding the Site, you agree
          that we can use and share such feedback for any purpose without
          compensation to you. We do nor assert any ownership over your
          contributions. You retain full ownership of all of our Contributions
          and any intellectual property rights, or other proprietary rights
          associated with your Contributions. We are not liable for any
          statements or representations in your contributions provided by you in
          any area on the Site. You are solely responsible for your
          Contributions to the site, and you expressly agree to exonerate us
          from any and all responsibility and to refrain from any legal action
          against us regarding your contributions.
        </p>

        <h3>
          <strong>MOBILE APPLICATION LICENSE</strong>
        </h3>
        <p>
          <strong>Use License</strong>
        </p>
        If you access the site via a mobile application, then we grant you a
        revocable, non- exclusive, non-transferable, limited right to install
        and use the mobile application on the wireless electronic devices owned
        or controlled by you, and to access and use the mobile application on
        such devices strictly in accordance with the terms and conditions of
        this mobile application license contained in these Terms of Use. You
        shall nor:
        <ol type="1">
          <li>
            {" "}
            decompile, reverse engineer, disassemble, attempt to derive the
            source code of, or decrypt the application.{" "}
          </li>
          <li>
            {" "}
            make anu modification, adaptation, improvement, enhancement,
            translation, or derivative work from the application.
          </li>
          <li>
            {" "}
            remove, alter or obscure any proprietary notice (including any
            notice of copyright or trademark) posted by us or licensors of the
            application.
          </li>
          <li>
            {" "}
            use the application for any revenue generating endeavor, commercial
            enterprise, or other purpose for which it is not designed or
            intended.
          </li>
          <li>
            {" "}
            make the application available over a network or other environment
            permitting access or use by multiple devices or users at the same
            time;
          </li>
          <li>
            {" "}
            use the application for creating a product, service, or software
            that is, directly or indirectly, competitive with or in any way
            substitute for the application.
          </li>
          <li>
            {" "}
            use the application to send automated queries to any website or to
            send any unsolicited commercial e-mail;
          </li>
          <li>
            {" "}
            Use any application, accessories, or devices for use with the
            application
          </li>
        </ol>
        <p>
          <strong>Apple and Android Devices</strong>
        </p>
        <p>
          The following terms apply when you use a mobile application obtained
          from either the Apple Store or Google Play (each an “App Distributor”)
          to access the Site:
        </p>
        <ol type="1">
          <li>
            {" "}
            the license granted to you for our mobile application is limited to
            a non- transferable license to use the application on a device that
            utilizes the Apple iOS or Android operating systems, as applicable,
            and in accordance with the usage rules set forth in the applicable
            App Distributor’s Terms of Use;
          </li>

          <li>
            {" "}
            we are responsible for providing any maintenance and support
            services with respect to the mobile application as specified in the
            Terms of Use of this mobile application license contained in these
            Terms of Use or as otherwise required under applicable law, and you
            acknowledge that each App Distributor has no obligation whatsoever
            to furnish any maintenance and support services with respect to the
            mobile application.
          </li>

          <li>
            {" "}
            in the event of any failure of the mobile application to conform to
            any applicable warranty, you may notify the applicable App
            Distributor, and the App Distributor, in accordance with its terms
            and policies, may refund the purchase price, if any, paid for the
            mobile application, and to the maximum extent permitted by
            applicable law, the App Distributor will have no other warranty
            obligation whatsoever with respect to the mobile application;
          </li>

          <li>
            {" "}
            you represent and warrant that (i) you are not located in a country
            that is subject to a U.S. government embargo, or that has been
            designated by the U.S. government as a “terrorist supporting”
            country and (ii) you are not listed on any U.S. government list of
            prohibited or restricted parties;
          </li>

          <li>
            {" "}
            you must comply with applicable third-party terms of agreement when
            using the mobile application, e.g., if you have a VoIP application,
            then you must not be in violation of their wireless data service
            agreement when using the mobile application.
          </li>

          <li>
            {" "}
            you acknowledge and agree that the App Distributors are third-party
            beneficiaries of the Terms of Use in this mobile application license
            contained in these Terms of Use, and that each App Distributor will
            have the right (and will be deemed to have accepted the right) to
            enforce the Terms of Use in this mobile application license
            contained in these Terms of Use against you as a third-party
            beneficiary thereof.
          </li>
        </ol>
        <h3>
          <strong>SOCIAL MEDIA</strong>
        </h3>
        <p>
          As part of the functionality of the Site, you may link your account
          with online accounts you have with third-party service providers (each
          such account, a “Third-Party Account”) by either:{" "}
        </p>
        <ol type="1">
          <li>
            {" "}
            providing your Third-Party Account login information through the
            Site; or
          </li>
          <li>
            {" "}
            Allowing us to access your Third-Party Account, as is permitted
            under the applicable Terms of Use that govern your use of each
            Third-Party Account.
          </li>
        </ol>
        <p>
          You represent and warrant that you are entitled to disclose your
          Third-Party Account login information to us and/or grant us access to
          your Third-Party Account, without breach by you of any of the Terms of
          Use that govern your use of the applicable Third-Party Account, and
          without obligating us to pay any fees or making us subject to any
          usage limitations imposed by the third-party service provider of the
          Third-Party Account.
        </p>
        <p>
          By granting us access to any Third-Party Accounts, you understand that
          (1) we may access, make available, and store (if applicable) any
          content that you have provided to and stored in your Third-Party
          Account (the “Social Network Content”) so that it is available on and
          through the Site via your account, including without limitation any
          friend lists and (2) we may submit to and receive from your Third-
          Party Account additional information to the extent you are notified
          when you link your account with the Third-Party Account.
        </p>
        <p>
          Depending on the Third-Party Accounts you choose and subject to the
          privacy settings that you have set in such Third-Party Accounts,
          personally identifiable information that You post to your Third-Party
          Accounts may be available on and through your account on the Site.
        </p>
        <p>
          Please note that if a Third-Party Account or associated service
          becomes unavailable or our access to such Third-Party Account is
          terminated by the third-party service provider, then Social Network
          Content may no longer be available on and through the Site. You will
          have the ability to disable the connection between your account on the
          Site and your Third-Party Accounts at any time.
        </p>
        <p>
          <strong>
            PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
            PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
            SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
          </strong>
        </p>
        <p>
          We make no effort to review any Social Network Content for any
          purpose, including but not limited to, for accuracy, legality, or
          non-infringement, and we are not responsible for any Social Network
          Content.
        </p>
        <p>
          You acknowledge and agree that we may access your email address book
          associated with a Third-Party Account and your contacts list stored on
          your mobile device or tablet computer solely for purposes of
          identifying and informing you of those contacts who have also
          registered to use the Site.
        </p>
        <p>
          You can deactivate the connection between the Site and your
          Third-Party Account by contacting us using the contact information
          below or through your account settings (if applicable). We will
          attempt to delete any information stored on our servers that was
          obtained through such Third-Party Account, except the username and
          profile picture that become associated with your account.
        </p>
        <h3>
          <strong>SUBMISSIONS</strong>
        </h3>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          ("Submissions") provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you.
        </p>
        <p>
          You hereby waive all moral rights to any such Submissions, and you
          hereby warrant that any such Submissions are original with you or that
          you have the right to submit such Submissions. You agree there shall
          be no recourse against us for any alleged or actual infringement or
          misappropriation of any proprietary right in your Submissions.
        </p>
        <h3>
          <strong>THIRD-PARTY WEBSITES AND CONTENT</strong>
        </h3>
        <p>
          The Site may contain (or you may be sent via the Site) links to other
          websites ("Third- Party Websites") as well as articles, photographs,
          text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or
          originating from third parties ("Third-Party Content").
        </p>
        <p>
          Such Third-Party Websites and Third-Party Content are not
          investigated, monitored, or checked for accuracy, appropriateness, or
          completeness by us, and we are not responsible for any Third-Party
          Websites accessed through the Site or any Third-Party Content posted
          on, available through, or installed from the Site, including the
          content, accuracy, offensiveness, opinions, reliability, privacy
          practices, or other policies of or contained in the Third-Party
          Websites or the Third-Party Content.
        </p>
        <p>
          Inclusion of, linking to, or permitting the use or installation of any
          Third-Party Websites or any Third-Party Content does not imply
          approval or endorsement thereof by us. If you decide to leave the Site
          and access the Third-Party Websites or to use or install any
          Third-Party Content, you do so at your own risk, and you should be
          aware these Terms of Use no longer govern.
        </p>
        <p>
          You should review the applicable terms and policies, including privacy
          and data gathering practices, of any website to which you navigate
          from the Site or relating to any applications you use or install from
          the Site. Any purchases you make through Third-Party Websites will be
          through other websites and from other companies, and we take no
          responsibility whatsoever in relation to such purchases which are
          exclusively between you and the applicable third party.
        </p>
        <p>
          You agree and acknowledge that we do not endorse the products or
          services offered on Third-Party Websites and you shall hold us
          harmless from any harm caused by your purchase of such products or
          services. Additionally, you shall hold us harmless from any losses
          sustained by you or harm caused to you relating to or resulting in any
          way from any Third-Party Content or any contact with Third-Party
          Websites.{" "}
        </p>
        <h3>
          <strong>SITE MANAGEMENT</strong>
        </h3>
        <p>We reserve the right, but not the obligation, to:</p>
        <ol type="1">
          <li> monitor the Site for violations of these Terms of Use;</li>

          <li>
            {" "}
            take appropriate legal action against anyone who, in our sole
            discretion, violates the law or these Terms of Use, including
            without limitation, reporting such user to law enforcement
            authorities.
          </li>

          <li>
            {" "}
            in our sole discretion and without limitation, refuse, restrict
            access to, limit the availability of, or disable (to the extent
            technologically feasible) any of your Contributions or any portion
            thereof.
          </li>

          <li>
            {" "}
            in our sole discretion and without limitation, notice, or liability,
            to remove from the Site or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems.
          </li>

          <li>
            {" "}
            Otherwise manage the Site in a manner designed to protect our rights
            and property and to facilitate the proper functioning of the Site.
          </li>
        </ol>
        <h3>
          <strong>PRIVACY POLICY</strong>
        </h3>
        <p>
          We care about data privacy and security. Please review our Privacy
          Policy. By using the Site, you agree to be bound by our Privacy
          Policy, which is incorporated into these Terms of Use. Please be
          advised the Site is hosted in the United Kinkgdom. If you access the
          Site from any other region of the world with laws or other
          requirements governing personal date collection, use, or disclosure
          that differ from applicable laws in The United Kingdom, then through
          your continued use of the Site, you are transferring your data to the
          United Kingdom, and you agree to have your data transferred to and
          processed in the United Kingdom.
        </p>
        <h3>
          <strong>TERM AND TERMINATION</strong>
        </h3>
        <p>
          These Terms of Use shall remain in full force and effect while you use
          the Site.
        </p>
        <p>
          <strong>
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
            RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
            LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
            CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
            REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
            USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SITE OR DELETE [YOUR ACCOUNT AND] ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
          </strong>
        </p>
        <p>
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party.
        </p>
        <p>
          In addition to terminating or suspending your account, we reserve the
          right to take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress.
        </p>
        <h3>
          <strong>MODIFICATIONS AND INTERRUPTIONS</strong>
        </h3>
        <p>
          <strong>SALES POLICY:</strong>
        </p>
        <ol type="1">
          <li>
            In case you have registered for the FNCY token sale stage, the
            account will stay available open only 24H for purchase, after the
            24H passed, the account will be closed with an email notification.
          </li>
          <li> Note that all the personal data will be deleted.</li>
          <li>
            In case you made a purchase and the order was not completely
            finalized, the order will be canceled automatically after 24H, after
            24H more the account is closing automatically.
          </li>
        </ol>
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Site. We also reserve the right to modify or discontinue all or
          part of the Site without notice at any time.
        </p>
        <p>
          We will not be liable to you or any third party for any modification,
          price change, suspension, or discontinuance of the Site.
        </p>
        <p>
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors.
        </p>
        <p>
          We reserve the right to change, revise, update, suspend, discontinue,
          or otherwise modify the Site at any time or for any reason without
          notice to you. You agree that we have no liability whatsoever for any
          loss, damage, or inconvenience caused by your inability to access or
          use the Site during any downtime or discontinuance of the Site.
        </p>
        <p>
          Nothing in these Terms of Use will be construed to obligate us to
          maintain and support the Site or to supply any corrections, updates,
          or releases in connection therewith.
        </p>
        <p>
          Disputes of any kind arising from the execution of contracts concluded
          with the company ifancythat Limited,
        </p>
        <p>
          Any natural or legal person who does not comply with the contractual
          terms, conditions, press releases published by ifancythat on the
          fancythattoken platform and on social media will be liable both
          civilly and criminally and will cover all material and moral damages
          caused to the fancythattoken network.
        </p>
        <p>
          In terms of the above it is included without limiting the addition of
          liquidity before the official listing of the fancythattoken, as 20%
          has been sent into the wallet of the buyers and will be liable both
          civilly and penal for all damages created to fancythattoken.
        </p>
        <h3>
          <strong>DISPUTE RESOLUTION</strong>
        </h3>
        <p>
          <b>Option 1:</b> Any legal action of whatever nature brought by either
          you or us (collectively, the “Parties” and individually, a “Party”)
          shall be commenced or prosecuted in the state and federal courts
          located in the United Kingdom and the Parties hereby consent to, and
          waive all defenses of lack of personal jurisdiction and forum non
          convenient with respect to venue and jurisdiction in such state and
          federal courts.
        </p>
        <p>
          Application of the United Nations Convention on Contracts for the
          International Sale of Goods and the Uniform Computer Information
          Transaction Act (UCITA) are excluded from these Terms of Use. In no
          event shall any claim, action, or proceeding brought by either Party
          related in any way to the Site be commenced more than 1 years after
          the cause of action arose.
        </p>
        <p>
          <strong>Informal Negotiations</strong>
        </p>
        <p>
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Terms of Use (each a "Dispute"
          and collectively, the “Disputes”) brought by either you or us
          (individually, a “Party” and collectively, the “Parties”), the Parties
          agree to first attempt to negotiate any Dispute (except those Disputes
          expressly provided below) informally for at least 30 days before
          initiating arbitration. Such informal negotiations commence upon
          written notice from one Party to the other Party.
        </p>
        <p>
          <strong>Binding Arbitration</strong>
        </p>
        <p>
          Any dispute arising from the relationships between the Parties to this
          contract shall be determined by one arbitrator who will be chosen in
          accordance with the Arbitration and Internal Rules of the European
          Court of Arbitration being part of the European Centre of arbitration
          having its seat in Strasbourg, and which adoption of this clause
          constitutes acceptance. The seat of arbitration shall be in London
          United Kingdom. The language of the proceedings shall be in English.
          Applicable rules of substantive law shall be the law of the United
          Kingdom
        </p>
        <p>
          <strong>Restrictions</strong>
        </p>
        <p>
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilize class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.
        </p>
        <p>
          <strong>Exceptions to Informal Negotiations and Arbitration</strong>
        </p>
        <p>
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning [informal negotiations and] binding
          arbitration: (a) any Disputes seeking to enforce or protect, or
          concerning the validity of, any of the intellectual property rights of
          a Party; (b) any Dispute related to, or arising from, allegations of
          theft, piracy, invasion of privacy, or unauthorized use; and (c) any
          claim for injunctive relief.
        </p>
        <p>
          If this provision is found to be illegal or unenforceable, then
          neither Party will elect to arbitrate any Dispute falling within that
          portion of this provision found to be illegal or unenforceable and
          such Dispute shall be decided by a court of competent jurisdiction
          within the courts listed for jurisdiction above, and the Parties agree
          to submit to the personal jurisdiction of that court.
        </p>
        <h3>
          <strong>CORRECTIONS</strong>
        </h3>
        <p>
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions that may relate to the Site,
          including descriptions, pricing, availability, and various other
          information. We reserve the right to correct any errors, inaccuracies,
          or omissions and to change or update the information on the Site at
          any time, without prior notice.
        </p>
        <h3>
          <strong>DISCLAIMER</strong>
        </h3>
        <p>
          THE INFORMATION ON THIS WEBSITE DOES NOT CONSTITUTE FINANCIAL ADVICE.
          THE fancythattoken TEAM DOES NOT ENDORSE CRYPTOCURRENCY INVESTMENTS
          AND IT IS CRITICAL FOR THE INVESTOR TO CONDUCT THEIR OWN DUE
          DILIGENCE. BY PURCHASING fancythattokenS, YOU AGREE THAT YOUR ARE
          NOT PURCHASING SECURITY OR INVESTMENT AND YOU AGREE TO HOLD THE TEAM
          HARMLESS AND NOT LIABLE FOR ANY LOSSES OR TAXES YOU MAY INCUR. YOU
          ALSO AGREE THAT THE TEAM IS PRESENTING THE TOKEN “AS IS” AND IS NOT
          LEGALLY REQUIRED TO PROVIDE ANY SUPPORT OR SERVICES. ALWAYS MAKE SURE
          THAT YOU ARE IN COMPLIANCE YOU’RE YOUR LOCAL LAWS AND REGULATIONS
          BEFORE YOU MAKE ANY PURCHASE. THE SITE IS PROVIDED ON AN AS-IS AND
          AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND ANY KIND
          OF OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT
          PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
          CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT
          LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
          REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S
          CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL
          ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
          USE OF THE ifancythat SERVICIES, (3) ANY UNAUTHORIZED ACCESS TO OR
          USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
          AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES,
          TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
          SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
          CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
          AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
          OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
          GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
          ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY
          HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
          ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN
          ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
          ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        </p>
        <p>
          AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN
          ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
          CAUTION WHERE APPROPRIATE.
        </p>
        <h3>
          <strong>LIMITATIONS OF LIABILITY</strong>
        </h3>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, THIRD PARTIES, OR
          AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
          CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
          INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
          ARISING FROM YOUR USE OF THE SITE/TOKEN/FANCY THAT WALLET OR ANY KIND
          OF PRODUCTS DEVELOPED/MANAGED BY THE COMPANY, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <h3>
          <strong>INDEMNIFICATION</strong>
        </h3>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of:{" "}
        </p>
        <ol type="1">
          <li> [your Contributions];</li>
          <li> use of the Site; </li>
          <li> breach of these Terms of Use; </li>
          <li>
             any breach of your representations and warranties set forth in
            these Terms of Use;
          </li>
          <li>
           your violation of the rights of a third party, including but not
            limited to intellectual property rights; or
          </li>
          <li>
            any overt harmful act toward any other user of the Site with whom
            you connected via the Site.
          </li>
        </ol>
        <p>
          Notwithstanding the foregoing, we reserve the right, at your expense,
          to assume the exclusive defense and control of any matter for which
          you are required to indemnify us, and you agree to cooperate, at your
          expense, with our defense of such claims. We will use reasonable
          efforts to notify you of any such claim, action, or proceeding which
          is subject to this indemnification upon becoming aware of it.
        </p>
        <h3>
          <strong>USER DATA</strong>
        </h3>
        <p>
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the Site, as well as data relating to your use of
          the Site. Although we perform regular routine backups of data, you are
          solely responsible for all data that you transmit or that relates to
          any activity you have undertaken using the Site.
        </p>
        <p>
          You agree that we shall have no liability to you for any loss or
          corruption of any such data, and you hereby waive any right of action
          against us arising from any such loss or corruption of such data.
        </p>
        <h3>
          <strong>
            ELECTRONIC COMMUNICATIONS,TRANSACTIONS, AND SIGNATURES
          </strong>
        </h3>
        <p>
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing.
        </p>
        <p>
          <strong>
            YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
            ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
            POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US
            OR VIA THE SITE.
          </strong>
        </p>
        <p>
          You hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.
        </p>
        <h3>
          <strong>MISCELLANEOUS</strong>
        </h3>
        <p>
          These Terms of Use and any policies or operating rules posted by us on
          the Site constitute the entire agreement and understanding between you
          and us. Our failure to exercise or enforce any right or provision of
          these Terms of use shall not operate as a waiver of such right or
          provision.
        </p>
        <p>
          These Terms of Use operate to the fullest extent permissible by law.
          We may assign any or all of our rights and obligations to others at
          any time. We shall not be responsible or liable for any loss, damage,
          delay, or failure to act caused by any cause beyond our reasonable
          control.
        </p>
        <p>
          If any provision or part of a provision of these Terms of Use is
          determined to be unlawful, void, or unenforceable, that provision or
          part of the provision is deemed severable from these Terms of Use and
          does not affect the validity and enforceability of any remaining
          provisions.
        </p>
        <p>
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms of
          Use or use of the Site. You agree that these Terms of Use will not be
          construed against us by virtue of having drafted them.
        </p>
        <p>
          You hereby waive any and all defenses you may have based on the
          electronic form of these Terms of Use and the lack of signing by the
          parties hereto to execute these Terms of Use.
        </p>
   
      </div>
          </section >
    )
}

export default TermsAndConditions