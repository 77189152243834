import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./index.css";
import { subscribeData } from "../../redux/_actions/apis.action";

import PopUpModal from "../PopUpModal";
import Logo from "../../assets/images/Group 468.svg";
import footerLogo from "../../assets/images/Group 611.svg";
import Group_616 from "../../assets/images/Group 616.png";
import Group_633 from "../../assets/images/Group 633.png";
import Path_524 from "../../assets/images/Path 524.png";
import Group_615 from "../../assets/images/Group 615.png";
import Group_660 from "../../assets/images/Group 660.png";
import Path_523 from "../../assets/images/Path 523.png";
import reddit_icon from "../../assets/images/reddit-icon.svg";
import { useDispatch } from "react-redux";

const NewFooter = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const subscribeFunc = async (e) => {
    e.preventDefault();

    await dispatch(subscribeData({ email: email }));
    document.getElementById("subcsribeform").reset();
  };

  return (
    <footer className="footer">
      {/* <PopUpModal show={show} handleClose={handleClose} /> */}
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <img src={Logo} width="150px" />
              <ul className="social">
                <li className="mb-2">
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.facebook.com/fancythattoken"
                  >
                    <img src={Group_616} />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://www.instagram.com/fancythattoken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Group_633} />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://twitter.com/fancythattoken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Path_524} />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://t.me/fancythattoken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Group_615} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCc7Znal_hdjUTBE62s9arYw"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Group_660} style={{ marginTop: "-13px" }} />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://www.linkedin.com/company/i-fancy-that-token"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Path_523} />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://www.reddit.com/u/FancyThatToken"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={reddit_icon} width={"30px"} style={{}} />
                  </a>
                </li>{" "}
              </ul>
            </div>
            <div className="co-12 col-md-3">
              <div className="contact">
                <h4>contact us</h4>
                <a href="mailto:support@fancythattoken.io">
                  support@fancythattoken.io
                </a>
                <p>Berkeley House, Barnet Rd, St Albans AL2 1BG</p>
              </div>
            </div>
            <div className="co-12 col-md-2">
              <div className="useful-link">
                <h4>useful links</h4>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/#about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/whitepaper">White Paper</a>
                  </li>
                  <li>
                    <a href="/vesting-schedule">Vesting Schedule</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-21 col-md-3">
              <div className="subscribe">
                <h4>subscribe</h4>
                <p>
                  To keep up to date with our latest news, simply fill in your
                  details below.
                </p>

                <form
                  onSubmit={(e) => {
                    // handleShow();
                    subscribeFunc(e);
                  }}
                  id="subcsribeform"
                >
                  <div className="input">
                    <div className="form-group mb-0">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        required
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <button className="btn" type="submit">
                      SUBSCRIBE
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <NavLink to="/terms-and-conditions">
                  {" "}
                  Terms and conditions{" "}
                </NavLink>{" "}
                | <NavLink to="/cookies">Cookies </NavLink>|{" "}
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                {/* | Modern slavery statement | <NavLink to="/disclaimer" >Disclaimer</NavLink> */}
              </p>
              <p>
                Berkeley House, Barnet Rd, St Albans AL2 1BG |
                support@fancythattoken.io
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={footerLogo} />
              <a
                href="https://www.ifancythat.com"
                target={"_blank"}
                rel="noreferrer"
              >
                <p>ifancythat.com Ltd 2022</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
