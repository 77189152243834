import ifancyTokenABI from "../assets/ABI/ifancyTokenABI.json";
import ifancyDynamicABI from "../assets/ABI/ifancyPublicABI.json";
import {
  IFANCY_PUBLIC_ADDRESS,
  IFANCY_TOKEN_ADDRESS,
  ADMIN_NAME,
} from "../constant";
import Web3 from "web3";
import { toast } from "../components/common/Toast";

let web3Instance, AntisInstance, AntisDynamicInstance;

const callWeb3 = () => {
  return new Promise(async (resolve, reject) => {
    if (!web3Instance || web3Instance == undefined) {
      const { ethereum } = window;
      if (ethereum && ethereum.isMetaMask) {
        web3Instance = new Web3(ethereum);
        resolve(web3Instance);
      } else if (window.web3) {
        web3Instance = new Web3(window.web3.currentProvider);
        resolve(web3Instance);
      }
    } else {
      reject("No Wallet Found");
    }
  });
};

const callGobalFunction = async () => {
  callWeb3()
    .then((web3) => {
      AntisInstance = new web3.eth.Contract(
        ifancyTokenABI,
        IFANCY_PUBLIC_ADDRESS
      );

      AntisDynamicInstance = new web3.eth.Contract(
        ifancyDynamicABI,
        IFANCY_TOKEN_ADDRESS
      );

      // CoinCreatorInstance = new web3.eth.Contract(
      //   CoinCreatorABI,
      //   COIN_CREATOR_CONTRACT_ADDRESS
      // );

      // //DynamicInstance = new web3.eth.Contract(DynamicABI, USDT_CONTRACT_ADDRESS);

      // AntisInstance = new web3.eth.Contract(CoingemABI, COINGEM_CONTRACT_ADDRESS);

      return true;
    })
    .catch((err) => {
      toast.error(err);
    });
};

callGobalFunction();

const createAntisInstance = async () => {
  return new Promise(async (resolve, reject) => {
    if (AntisInstance) {
      resolve(AntisInstance);
    } else {
      callGobalFunction()
        .then(() => {
          resolve(AntisInstance);
        })
        .catch(reject);
    }
  });
};

const createDynamicAntisInstance = async () => {
  return new Promise(async (resolve, reject) => {
    if (AntisDynamicInstance) {
      resolve(AntisDynamicInstance);
    } else {
      callGobalFunction()
        .then(() => {
          resolve(AntisDynamicInstance);
        })
        .catch(reject);
    }
  });
};

const getdecimalsAntis = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createDynamicAntisInstance();
      if (contract.methods) {
        contract.methods
          .decimals()
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const getBalanceOfAntis = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createDynamicAntisInstance();
      if (contract.methods) {
        contract.methods
          .balanceOf(data.walletAddress)
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const getContractAllowanceInfo = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createDynamicAntisInstance();
      if (contract.methods) {
        contract.methods
          .allowance(data.walletAddress, data.contractAddress)
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const _getUserMappingData = async (walletAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createAntisInstance();
      if (contract.methods) {
        contract.methods
          .userMapping(walletAddress, 1)
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

// const getContractApproveInfo = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const contract = await createDynamicAntisInstance();
//       if (contract.methods) {
//         const gasLimit = await contract.methods
//           .approve(ANTIS_STAKING_CONTRACT_ADDRESS, data.value)
//           .estimateGas({ from: data.walletAddress });
//         await contract.methods
//           .approve(ANTIS_STAKING_CONTRACT_ADDRESS, data.value)
//           .send({ from: data.walletAddress, gasLimit })
//           .then((result) => {
//             resolve(result);
//           })
//           .catch((error) => {
//             reject(error);
//           });
//       } else {
//         reject(new Error("Contract not found."));
//       }
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

const _sendStakeAntis = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createAntisInstance();
      // here 1 is tier number it will be dynamic

      if (contract.methods) {
        const gasLimit = await contract.methods
          .stakeToken(Number(data.convert_amount), data?.tier?.value)
          .estimateGas({ from: data.walletAddress });
        await contract.methods
          .stakeToken(Number(data.convert_amount), data?.tier?.value)
          .send({ from: data.walletAddress, gasLimit })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const _getTierCount = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createAntisInstance();
      if (contract.methods) {
        contract.methods
          .tierCount()
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const _sendUnStakeAntis = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createAntisInstance();
      if (contract.methods) {
        const gasLimit = await contract.methods
          .unStakeToken(Number(data.stakeid))
          .estimateGas({ from: data.walletAddress });
        await contract.methods
          .unStakeToken(Number(data.stakeid))
          .send({ from: data.walletAddress, gasLimit })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};
// _upgradeTier;
const _upgradeTier = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createAntisInstance();
      if (contract.methods) {
        const gasLimit = await contract.methods
          .upgradeTiers(Number(data.stakeId))
          .estimateGas({ from: data.walletAddress });
        await contract.methods
          .upgradeTiers(Number(data.stakeId))
          .send({ from: data.walletAddress, gasLimit })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const bnbService = {
  _sendStakeAntis,
  _sendUnStakeAntis,
  getContractAllowanceInfo,
  getdecimalsAntis,
  getBalanceOfAntis,
  _getUserMappingData,
  _getTierCount,
  _upgradeTier,
};
