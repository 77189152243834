import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import "./index.css";
import Logo from "../../assets/images/Group 468.svg";
import banner_bg from "../../assets/images/banner-bg.svg";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { contactdata } from "../../redux/_actions/apis.action";

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name Required "),
  // .strict(true),
  email: Yup.string()
    .email("Invalid email")
    .required("Email Required")
    .trim("The email cannot include leading and trailing spaces")
    .strict(true),
  message: Yup.string()
    .min(2, "Too Short!")
    .max(300, "Too Long!")
    .required("Message Required")
    .strict(true),
});
const ContactUs = (props) => {
  const dispatch = useDispatch();

  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };

  return (
    <section
      className="contact-us"
      style={{
        backgroundImage: `url(${banner_bg})`,
      }}
    >
      <ScrollToTop />
      <div className="container">
        <ComnHeading beforeTxt="Contact US" heading="Contact US" />
        <div className="row align-items-center">
          {/* <div className="col-12">
            <h1 className="text-center mb-5 font-weight-bold text-white">Contact <span className="blue-text">Us</span></h1>
          </div> */}
          <div className="col-12 col-md-6">
            <div className="contact-info">
              <div className="d-flex align-items-center ">
                <p>
                  <i className="fa fa-envelope"></i>
                </p>
                <p className="">support@fancythattoken.io</p>
              </div>
              {/* <p><i className="fa fa-phone"></i></p>
              <p className="">+96 1234 2478</p> */}
              <div className="d-flex align-items-center ">
                <p>
                  <i className="fa fa-map-marker"></i>
                </p>
                <p className="">Berkeley House, Barnet Rd, St Albans AL2 1BG</p>
              </div>
              <p className="mt-4">
                <img src={Logo} width="150pc" />
              </p>
              <p className="">
                Cryptocurrency is the future and with the fancythattoken you can
                purchase what you fancy within the ifancythat brand.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="contact-card">
              <h4 className="font-weight-bold text-center mb-4 text-white">
                Get in touch
              </h4>

              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                }}
                validationSchema={SignupSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm }) => {
                  dispatch(contactdata(values));
                  resetForm({ values: "" });
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit} className="registerForm ">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        name="name"
                        htmlFor="name"
                        id="name"
                        type="text"
                        label="Full Name"
                        // onChange={(event) => {
                        //   if (/^([A-Za-z]|)+$/.test(event.target.value)) {
                        //     handleChange(event);
                        //   }
                        // }}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        value={values.name}
                        placeholder="Full Name"
                        error={errors.name}
                        maxLength={40}
                      />
                      <i className="fa fa-user"></i>
                    </div>
                    <div className="form-group">
                      <Field
                        className="form-control"
                        name="email"
                        id="email"
                        type="email"
                        label="Email"
                        onChange={handleChange}
                        value={values.email}
                        placeholder="Email"
                        error={errors.email}
                        maxLength={60}
                      />
                      <i className="fa fa-envelope"></i>
                    </div>
                    {/* <ErrorMessage name="email" className="text-danger" /> */}

                    <div className="form-group">
                      <Field
                        className="form-control"
                        name="message"
                        as="textarea"
                        id="message"
                        type="textarea"
                        label="Message"
                        rows="5"
                        cols="20"
                        onChange={handleChange}
                        value={values.message}
                        placeholder="Message"
                        error={errors.message}
                        maxLength={300}
                      />
                      <i className="fa fa-comments"></i>
                    </div>
                    <div className="form-group text-center">
                      <button
                        className="btn theme-btn "
                        type="submit"
                        text="Submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby=""
                    placeholder="Enter Name"
                  />
                  <i className="fa fa-user"></i>
                </div>
                <div className="form-group">
                  <input
                    type="emial"
                    className="form-control"
                    id=""
                    aria-describedby=""
                    placeholder="Enter Email"
                  />
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="7"
                  ></textarea>
                  <i className="fa fa-comments"></i>
                </div>
                <div className="text-center">
                  <button className="bnt theme-btn">Submit</button>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
