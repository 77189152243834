import React from "react";
import ScrollToTop from "../../components/ScrollToTop";

const WhitePaperFooter = (props) => {
  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };
  //   <h3><strong></strong></h3>
  //  <p></p>
  return (
    <section className="Disclaimer comnPadd text-white white-papaer-more">
      <ScrollToTop />
      <div className="container">
        <ComnHeading beforeTxt="White Paper" heading="White Paper" />
        <div className="main-heading">
          <span className="heading-bage">1</span>
          <h3>
            <strong>Executive Summary</strong>{" "}
          </h3>
        </div>
        <p>
          The global financial system moves trillions of dollars from one
          account to another daily. A single money transfer passes through a
          complex system of intermediaries before reaching its final
          destination. Transferring money around the world with a traditional
          payment gateway is a nightmare for consumers as well as financial
          institutions these days. According to a report published by PwC,
          almost 45% of financial intermediaries suffer due to economic crime
          every year.{" "}
        </p>
        <p>
          This is where Blockchain technology comes into the picture. Being one
          of the leading innovations, the technology promises to offer
          high-speed transactions, reduce frauds, and mitigate risks within the
          global financial system. According to Statista, the application of
          Blockchain technology in the financial sector is expected to grow in
          the future, reaching a market size of approximately 22.5 billion U.S.
          dollars by 2026.
        </p>
        <p>
          Such statistics and benefits have encouraged people to move to
          Blockchain-powered payment systems. ifancythat ecosystem consists of
          ifancythat apps, a wallet, and a payment gateway that are designed to
          streamline the error-prone traditional banking system.{" "}
        </p>
        <div className="main-heading">
          <span className="heading-bage">2</span>
          <h3>
            <strong>ifancythat Vision and Mission </strong>{" "}
          </h3>
        </div>

        <p>
          <strong>Vision</strong>
        </p>
        <p>
        ifancythat’s vision is to create a single wallet and connect it with every service available on the ifancythat platform to enable users to purchase their favourite products using fancythattoken. Each time a product is purchased using fancythattoken, the user will receive a discount on the final price. A percentage of the token used to purchase a product will be burned, reducing the circulation of FNCY token, which increases the value of the token for current token holders.
        </p>
        <p>
          <strong>Mission</strong>
        </p>
        <p>
        ifancythat's mission is to provide a comprehensive platform where users can perform multiple operations such as flight booking, hotel booking, buying, selling cell phones and watches, hiring a vehicle, playing casino games and more. The features of the wallet will include Airdrop opportunities, VPN layer 2 encryption, Chat encrypted privacy, Launchpad IDO opportunities and an exchange which enables you to trade your crypto assets.
        </p>
        <div className="main-heading">
          <span className="heading-bage">3</span>
          <h3>
            <strong>The Problem</strong>{" "}
          </h3>
        </div>

        <p>
          <strong>Centralised system</strong>
        </p>
        <p>
          Centralised ledgers used in the traditional banking system are fraught
          with several weaknesses. The financial data stored on centralised
          ledgers can be easily destroyed or manipulated by the central
          authority. The lack of trustworthiness in third parties and the risk
          of record losses are some reasons that make people move to
          decentralised financial platforms.{" "}
        </p>

        <p>
          <strong>Delayed and expensive transactions</strong>
        </p>
        <p>
          The delay in money transfer is one of the biggest drawbacks of
          traditional financial institutions. Trillions of dollars slosh around
          the world via an antiquated traditional financial system beset with
          inefficiencies like slow payments and added fees. A simple bank
          transfer has to pass through a complicated system of intermediaries
          which makes it an expensive and time-consuming task.{" "}
        </p>

        <p>
          <strong>Lack of transparency</strong>
        </p>
        <p>
          Auditing is an expensive process that doesn’t leave any room for
          transparency in a centralised system. In current financial systems,
          auditors are allowed to show specific pieces of information while
          shrouding others, which becomes a crucial source of unethical
          behaviour and non-compliance.
        </p>

        <p>
          <strong>Lack of Security </strong>
        </p>
        <p>
          From clearing houses to custodian banks, the traditional financial
          system is brimmed with countless intermediaries, which makes it more
          prone to deception. The data that moves from one destination to
          another flows through several intermediaries, which increases the
          possibility of fraud, data corruption, and cyberattacks.
        </p>
        <div className="main-heading">
          <span className="heading-bage">4</span>
          <h3>
            <strong>The Solution</strong>{" "}
          </h3>
        </div>


        <p>
          ifancythat comes with numerous features that not only provide
          convenience to users while making payments but also overcome several
          problems of the traditional financial ecosystem.{" "}
        </p>
        <p>
          <strong>Decentralisation</strong>
        </p>
        <p>
          ifancythat is a completely decentralised platform that eliminates the
          need for intermediaries and also ensures that no one acts as the
          “supreme leader” of the financial system. It enables users to transact
          instantly without any hefty transaction charges.
        </p>

        <p>
          <strong>Greater Transparency</strong>
        </p>
        <p>
          ifancythat offers complete transparency, which increases users’ trust
          and reduces the likelihood of fraudulent activities. Since ifancythat
          is an immutable platform and users perform all activities on a public
          ledger, it is easy to check all records for compliance. It not only
          streamlines the entire process but also brings transparency while
          curbing unethical practices.{" "}
        </p>

        <p>
          <strong>Security</strong>
        </p>
        <p>
          ifancythat provides a secure environment for users. It is impossible
          for hackers to knock down a decentralised platform since there are no
          servers to damage. It is resistant to distributed denial-of-service
          attacks, hackers, and other types of fraud.
        </p>

        <p>
          <strong>Accelerated Transactions</strong>
        </p>
        <p>
          ifancythat facilitates faster payments as compared to traditional
          banks. It offers a secure and cheap way of sending payments, which
          cuts down on the need for verification from third parties and reduces
          the transaction settlement time significantly.
        </p>

        <p>
          <strong>Cost-efficiency</strong>
        </p>
        <p>
          Most funds move through financial institutions, such as banks or
          credit card processing centres. Each of these steps adds a layer of
          complexity, along with fees, thereby leading to costly transactions.
          Traditional financial institutions make money on transaction fees that
          could be lowered or eliminated by using ifancythat.
        </p>
        <div className="main-heading">
          <span className="heading-bage">5</span>
          <h3>
            <strong>  ifancythat Ecosystem</strong>{" "}
          </h3>
        </div>


        <p>
          The ifancythat ecosystem comprises ifancythat apps, a wallet, and a
          payment gateway.{" "}
        </p>

        <h3>
          <strong>  ifancythat apps </strong>{" "}
        </h3>

        <div className="row">
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>ifancyabet</strong>
              </p>
              <p>
                ifancyabet is set to be the number 1 gaming site in the world.
                It will allow users to pay with fancythattokens to play a wide
                variety of casino games. It will launch in 2023 and could become
                the world’s first betting site that accepts both crypto and fiat
                currency.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="border-right">
              <p>
                <strong>ifancyahirecar</strong>
              </p>
              <p>
                ifancyahirecar will be a new go-to website for vehicle hire,
                ifancythat is working with major car hire brands on the market to
                bring the best deals on many hire vehicles. It will offer a car,
                coach, and even motorhome hires for any location in the world.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="border-right">
              <p>
                <strong>ifancyaflight</strong>
              </p>
              <p>
                ifancyaflight will offer users the best deals to fly to their
                dream destination. ifancythat is working with the world’s biggest
                brands to give its customers easy access to all flight timings and
                destinations. Users will be able to book their dream holiday using
                the fancythattoken.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="border-right">
              <p>
                <strong>ifancyahotel</strong>
              </p>
              <p>
                ifancyahotel will showcase beautiful hotels in stunning
                destinations. It will allow users to browse a list of thousands of
                stunning hotels, find the correct accommodation for vacations, and
                book a hotel with fancythattoken.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="border-right">
              <p>
                <strong>ifancyadate</strong>
              </p>
              <p>
                ifancyadate is designed to give a new spark to the dating life
                of its customers. ifancyadate will encourage users to talk and
                meet with their perfect matches on the app. It will launch soon
                with some latest features.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="border-right">
              <p>
                <strong>ifancyaphone</strong>
              </p>
              <p>
                ifancyaphone will allow users to easily sell their unwanted
                mobiles at an excellent price. It will enable them to compare the
                best prices available for their old phone with the best reviews.
                It will soon accept fancythattoken on the purchase of cell phones.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="border-right">
              <p>
                <strong>ifancyawatch</strong>
              </p>
              <p>
                ifancyawatch guarantees to show a wide range of watches at the
                best prices possible. It will allow users to purchase their new
                accessory with fancythattokens on a discount offer.{" "}
              </p>
            </div>
          </div>
        </div>


        <p>
          <strong>Wallet</strong>
        </p>
        <p>
          ifancythat will develop and launch a wallet that will be used to
          purchase any items available on websites created by ifancythat team.
          Users can refer this wallet to their friends and relatives and earn
          fancythattokens in return.{" "}
        </p>

        <p>
          <strong>Payment gateway</strong>
        </p>
        <p>
          Developers can integrate ifancythat’s payment services into their
          websites or apps. The goal of this functionality is to make any
          payment as easy as possible. Users can make payments using
          fancythattokens in their wallet, or from credit cards or debit cards.{" "}
        </p>
        <div className="main-heading">
          <span className="heading-bage">6</span>
          <h3>
            <strong>  Technology Stack </strong>{" "}
          </h3>
        </div>


        <p>
          fancythattoken is an BEP-20 based token that is developed on the
          Binance Smart Chain Blockchain - an immutable blockchain platform that
          is used to create decentralised applications. It is one of the oldest
          and most trusted standards for creating tokens. All tokens that are
          created and run on the Binance Smart Chain Blockchain must abide by
          the rules of the BEP-20 token standard. The below-listed are some
          prominent benefits that make BEP-20 the most suitable standard for
          token creation:
        </p>
        <div className="row">
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>Lightweight Architecture</strong>
              </p>
              <p>
                Binance Smart Chain adopts a lightweight architecture with the aim
                of reducing resource consumption to guarantee system performance.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>Robust</strong>
              </p>
              <p>
                Bandwidth consumption is predictable and static since each
                computational step cost is fixed.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>High Compatibility </strong>
              </p>
              <p>
                BEP-20 is compatible with other token standards in the mainstream.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>Token Swapping</strong>
              </p>
              <p>
                The BEP20 token can be swapped easily while using it in the
                decentralised apps, which increases the transaction speed.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>Easily Traceable </strong>
              </p>
              <p>
                Due to the transparent nature of Binance Smart Chain blockchain,
                users can track transactions in case of any dispute.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>Affordable </strong>
              </p>
              <p>
                The cost per transaction in the case of Binance Smart Chain is
                less compared to transactions that happen in other popular
                Blockchain networks.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>Scalable </strong>
              </p>
              <p>
                Binance Smart Chain blockchain comes with numerous scalability
                features and has the ability to handle thousands of transactions
                in a second.
              </p>
            </div>
          </div>


          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>Secure </strong>
              </p>
              <p>
                The BEP20 token is highly secure and users can customize it to
                meet their specific business needs.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 ">
            <div className="border-right">
              <p>
                <strong>Low Cost </strong>
              </p>
              <p>
                Due to Binance Smart Chain’s bandwidth setup, development costs
                are reduced and developers can focus on the logical development of
                their contract code. It also offers all-in-one interfaces for
                contract deployment, triggering, and viewing to provide
                convenience to developers.
              </p>
            </div>
          </div>

        </div>
        <div className="main-heading">
          <span className="heading-bage">7</span>
          <h3>
            <strong>  fancythattoken Stack </strong>{" "}
          </h3>
        </div>


        <p>
          Users can connect the fancythatwallet with all services available on
          fancythattoken platform and make payments using fancythattokens to
          get products and services at discounted prices.{" "}
        </p>

        <ol type="1">
          <li>
            {/* <p>
              <strong>Lightweight Architecture</strong>
            </p> */}
            <p>Ifancyahotel</p>
          </li>
          <li>
            {/* <p>
              <strong>Lightweight Architecture</strong>
            </p> */}
            <p>ifancyadate</p>
          </li>
          <li>
            {/* <p>
              <strong>Lightweight Architecture</strong>
            </p> */}
            <p>ifancyawatch</p>
          </li>
          <li>
            {/* <p>
              <strong>Lightweight Architecture</strong>
            </p> */}
            <p>ifancyaflight</p>
          </li>
          <li>
            {/* <p>
              <strong>Lightweight Architecture</strong>
            </p> */}
            <p>ifancyahirecar</p>
          </li>
          <li>
            {/* <p>
              <strong>Lightweight Architecture</strong>
            </p> */}
            <p>ifancyabet</p>
          </li>
          <li>
            {/* <p>
              <strong>Lightweight Architecture</strong>
            </p> */}
            <p>ifancyaphone</p>
          </li>
        </ol>

        <div className="main-heading">
          <span className="heading-bage">8</span>
          <h3>
            <strong>Tokenomics </strong>
          </h3>
        </div>

        <p>
          <strong>fancythattoken distribution</strong>
        </p>
        <p>
          It is proposed that 100,000,000,000 (100BN) fancythattokens will be
          created as a fixed supply. The proposed fancythattoken allocations
          are:
        </p>

        <p>
          <strong>fancythattoken distribution phases</strong>
        </p>
        <p>
          There will be a minimum purchase value of $50 and a maximum value of
          $25,000. In order to purchase the tokens, users will be required to
          register through the fancythattoken.io website and convert the value
          of USD into BNB. Within 24 hours, 20% of the tokens will be delivered
          into the wallet. These will be locked until 1st June 2023. This is
          when it will be possible to carry out free trade. The remaining tokens
          will need to be claimed via the website. Log In and connect wallet and
          claim into the wallet at 20% each month until 1st October 2023. This
          will control the growth and give the token maximum potential to grow
          in value. There will be 6 Phases of token distribution.
        </p>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Phase/Stage</th>
                <th scope="col">Duration</th>
                <th scope="col">Lock-inPeriod</th>
                <th scope="col">Token Available</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Seedstage</th>
                <td>1st Jun 2022 – 30th Jun 2022</td>
                <td>11 Months</td>
                <td> $0.0005</td>
              </tr>
              <tr>
                <th scope="row">Phase 1</th>
                <td>1st Aug 2022 – 31st Aug 2022</td>
                <td>9 Months</td>
                <td>$0.0008</td>
              </tr>
              <tr>
                <th scope="row">Phase 2</th>
                <td>1st Oct 2022 – 31st Oct 2022</td>
                <td>7 Months</td>
                <td>$0.0012</td>
              </tr>
              <tr>
                <th scope="row">Phase 3</th>
                <td>1st Dec 2022 – 31st Dec 2022</td>
                <td>5 Months</td>
                <td>$0.0016</td>
              </tr>
              <tr>
                <th scope="row">Phase 4</th>
                <td>1st Feb 2023 – 28th Feb 2023</td>
                <td>3 Months</td>
                <td>$0.0020</td>
              </tr>
              <tr>
                <th scope="row">Phase 5</th>
                <td>1st Apr 2023 – 30th Apr 2023</td>
                <td>1 Months</td>
                <td>$0.0025</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="main-heading">
          <span className="heading-bage">9</span>
          <h3>
            <strong>Roadmap</strong>
          </h3>
        </div>

        <p>
          <strong>Phase 1</strong>
        </p>
        <ol type="1">
          <li>
            <p>Token launch</p>
          </li>

          <li>
            <p>Website + social media launch</p>
          </li>

          <li>
            <p>Reach 1,000 holding addresses</p>
          </li>
          <li>
            <p>Charity wallet</p>
          </li>
          <li>
            <p>Release the first whitepaper</p>
          </li>
        </ol>


        <p>
          <strong>Phase 2 </strong>
        </p>
        <ol type="1">
          <li>
            <p>Apply for CoinJab listing</p>
          </li>

          <li>
            <p>Apply for CoinGecko listing</p>
          </li>

          <li>
            <p>Apply for CoinMarketCap listing</p>
          </li>

          <li>
            <p>Re-brand website and social media</p>
          </li>

          <li>
            <p>Contract audit</p>
          </li>

          <li>
            <p>Outreach crypto influencers on YouTube, Instagram, and TikTok</p>
          </li>

          <li>
            <p>First use case – marketplace</p>
          </li>

        </ol>

        <p>
          <strong>Phase 3</strong>
        </p>
        <ol type="1">
          <li>
            <p>Reach 5,000 holding addresses</p>
          </li>

          <li>
            <p>Donate to a chosen charity</p>
          </li>

          <li>
            <p>Further development of the ecosystem</p>
          </li>

          <li>
            <p>Wallet launch: Early 2023</p>
          </li>

          <li>
            <p>ifancyadate launch: September 2022</p>
          </li>

          <li>
            <p>Ifancyahotel launch: December 2022</p>
          </li>

          <li>
            <p>ifancyawatch launch: Late 2022</p>
          </li>

          <li>
            <p>ifancyaphone launch: Early 2023</p>
          </li>

          <li>
            <p>ifancyahirecar launch: Early 2023</p>
          </li>

          <li>
            <p>ifancyaflight launch: Early 2023</p>
          </li>

          <li>
            <p>ifancyaholiday launch: Early 2023</p>
          </li>

          <li>
            <p>ifancyabet launch: 2024</p>
          </li>

          <li>
            <p>Communication in the wallet</p>
          </li>

          <li>
            <p>Exchange built within the wallet</p>
          </li>


          <li>
            <p>New cryptocurrency information with reports</p>
          </li>

          <li>
            <p>Multiple exchange listings. The first will be in June 2023 and then multiple at a further phase</p>
          </li>
        </ol>

        <div className="main-heading">
          <span className="heading-bage">10</span>
          <h3>
            <strong>  Team  </strong>{" "}
          </h3>
        </div>

        <p>The team behind fancythattoken has decades of experience in Gaming, Blockchain, and mobile applications. The fancythattoken team is excited to create the fancythattoken to enable millions of Blockchain enthusiasts around the globe to access products and services offered by fancythattoken. </p>
        <div className="main-heading">
          <span className="heading-bage">11</span>
          <h3>
            <strong>  Legal disclaimer  </strong>{" "}
          </h3>
        </div>

        <p>The following terms and conditions (“Terms”) govern the offer and sale of fancythattoken cryptographic tokens (“FancyThat”) by the fancythattoken Foundation to crowdsale participants (“Purchasers” collectively, and “Purchaser” individually). This document describes the initial sale in which the fancythattoken (“FNCY”) is sold. It is not a solicitation for investment and does not pertain in any way to an offering of securities in any jurisdiction. Individuals, businesses, and other organisations should carefully weigh the risks, costs, and benefits of acquiring fancythattoken early in the initial sale versus waiting to purchase fancythattoken on open, third-party exchanges.</p>
        <p>Ownership of fancythattoken carries no rights, express or implied. Purchases of fancythattoken are non-refundable. Purchasers should have no expectation of influence over governance of the Fancy That Platform. By participating in the sale of fancythattoken, you expressly acknowledge and represent that you have carefully reviewed the Terms and fully understand the risks, costs, and benefits of purchasing fancythattoken and agree to be bound by these Terms. As set forth below, you further represent and warrant that, to the extent permitted by law, you are authorised to purchase fancythattoken in your relevant jurisdiction, are of a legal age to be bound by these Terms, and will not hold liable for any losses or any special, incidental, or consequential damages arising out of, or in anyway connected to the sale of fancythattoken , now or in the future, fancythattoken Foundation and its affiliates and successors, and the officers, directors, agents, joint ventures, employees and suppliers of Fancy That or its affiliates, including  ifancythat.com Ltd.</p>


        <p>
          <strong> WARNING </strong>
        </p>
        <p>DO NOT PURCHASE fancythattoken IF YOU ARE NOT AN EXPERT IN DEALING WITH CRYPTOGRAPHIC TOKENS AND BLOCKCHAIN-BASED SOFTWARE SYSTEMS Purchases of fancythattoken should be undertaken only by individuals, entities, or companies that have significant experience with, and understanding of, the usage and intricacies of cryptographic tokens, like Binance smart chain (“BNB”), and blockchain- based software systems. Purchasers should have functional understanding of storage and transmission mechanisms associated with other cryptographic tokens. While the fancythattoken Foundation will be available to assist Purchasers of Fancy That during and after the sale, the fancythattoken Foundation will not be responsible for lost BNB or fancythattokens resulting from actions taken by or omitted by Purchasers.</p>
        <p>Note, in particular, that fancythat Purchasers should take great care to write down their wallet password and not lose it so as to be sure that they will be able to access their fancythattoken when it becomes available after the initial sale. If you do not have such experience or expertise, then you should not purchase fancythattoken or participate in the pre-sale of fancythattokens.</p>


        <p>
          <strong> WARNING </strong>
        </p>
        <p>CRYPTOGRAPHIC TOKENS MAY EXPERIENCE EXTREME PRICE VOLATILITY. fancythattokens do not represent any formal or legally binding investment. Cryptographic tokens that possess value in public markets, such as BNB, have demonstrated extreme fluctuations in price over short periods of time on a regular basis. A Purchaser of fancythattoken should be prepared to expect similar fluctuations, both down and up, in the price of Fancy That denominated in BNB or United States dollars (“USD”) or currencies of other jurisdictions. Such fluctuations are due to market forces and represent changes in the balance of supply and demand. fancythattoken Foundation cannot and does not guarantee market liquidity for Fancy That and therefore there may be periods of time in which Fancy That is difficult to buy. Additionally, due to different regulatory dictates in different jurisdictions and the inability of citizens of certain countries to open accounts at exchanges located anywhere in the world, the liquidity of Fancy That may be markedly different in different countries, and this would likely be reflected in significant price discrepancies. By purchasing fancythattoken, you expressly acknowledge and represent that you fully understand that fancythattoken may experience volatility in pricing and will not seek to hold any of the Fancy That Foundation Parties liable for any losses or any special, incidental, or consequential damages arising from, or in any way connected to, the sale of fancythattoken.</p>


        <p>
          <strong> WARNING </strong>
        </p>
        <p>PURCHASE OF fancythattokens ENTAILS A NUMBER OF RISKS. The purchase of fancythattokens carries with it a number of risks. Prior to purchasing fancythattokens, you should carefully consider the risks listed below and, to the extent necessary, consult an appropriate lawyer, accountant, or tax professional. If any of the following risks are unacceptable to you, you should not purchase fancythattokens. By purchasing fancythattokens, and to the extent permitted by law, you are agreeing not to hold any of the fancythattokens Foundation Parties liable for any losses or any special, incidental, or consequential damages arising from, or in any way connected, to the sale of fancythattokens, including losses associated with the risks set forth below.        </p>




      </div>
    </section>
  );
};

export default WhitePaperFooter;