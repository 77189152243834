import React, { useSatae, useEffect, useState, useLayoutEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { arrayInsert } from "redux-form";
import { Tabs } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AdminChart = ({ monthData, coinBuyMount, ifanctTokenDecimal }) => {
  const [optionsArr, setOptionsArr] = useState([]);
  const [transactionArr, setTransactionArr] = useState([]);

  useLayoutEffect(() => {
    monthDistribution();
  }, []);

  const monthDistribution = () => {
    let optionsarr = [];
    let dataarr = [];

    monthData.map((data) => {
      optionsarr.push(data?.transactionMonth);
      dataarr.push(
        ifanctTokenDecimal
          ? Number(data?.buyAmounttotal) / ifanctTokenDecimal
          : Number(data?.buyAmounttotal) / 10 ** 6
      );
    });
    setOptionsArr(optionsarr);
    setTransactionArr(dataarr);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        color: "#fff",
      },
      title: {
        display: true,
        text: "Fancythat Tokens Sales Data",
        color: "#fff",
      },
    },
  };
  const labels = optionsArr;
  // const labels = ["jan", "feb", "mar", "apr", "may", "jun", "jul"];
  const data = {
    labels,
    datasets: [
      {
        label: "Tokens Sold Data",
        data: transactionArr,
        // data: [251, 856, 411, 253, 145, 684, 2000],
        borderColor: "#ffffff",
        backgroundColor: "#ffffff",
        color: "#254",
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default AdminChart;
