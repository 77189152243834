import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import lock from "../../../assets/images/wallet/lock.png";

const Funds = () => {
  return (
    <>
      <section className="fundSec">
        <Container className="commnCont">
          <Row>
            <Col md={6} left>
              <h2 className="walletheading__title common-heading">
                <span className="walletheading__subText"> All Funds </span>
                are SAFE
              </h2>
              <div className="wallet__content">
                <p>
                  Buy your desired stuff, make secure payments, and view your
                  balance instantly. We implement world-class security methods
                  and technologies.
                </p>
                {/* <StoreBtns /> */}
              </div>
            </Col>
            <Col md={6} right>
              {/* <ImageWrap main={lock} className="fundsImg" /> */}
              <img src={lock} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Funds;
