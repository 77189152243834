import React from 'react'
import { Container, Row, Col, Accordion } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './index.css'
const Roadmap = (props) => {
  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="roadmapSec comnPadd">
      <Container>
        <ComnHeading
          beforeTxt="Roadmap"
          heading="Roadmap"
        />
        <div className="roadmap_Slider">
          <Slider {...settings}>
            <div className="roadmapWrap">
              <span className="phase">Phase 1</span>
              <div className="roadmapWrap_content">
                <ul>
                  <li>Token Launch</li>
                  <li>Website + Social Media Launch</li>

                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>Reach 1,000 Holding Addresses</li>
                  <li>Charity Wallet</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>

                  <li>Release The First WhitePaper</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <span className="phase">Phase 2</span>
              <div className="roadmapWrap_content">
                <ul>
                  <li>Apply For CoinJab Listing</li>
                  <li>Apply For Coingecko Listing</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>Apply For CoinMarketcap Listing</li>
                  <li>Re-Branding Website And Social Media</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>Contract Audit</li>
                  <li>First Use Case – Marketplace</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>Outreach Crypto Influencers on YouTube, Instagram, and TikTok</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <span className="phase">Phase 3</span>
              <div className="roadmapWrap_content">
                <ul>
                  <li>Reach 5,000 Holding Addresses</li>
                  <li>Donate to a Chosen Charity</li>
                </ul>
              </div>
            </div>

            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>Further Development of The Ecosystem</li>
                  <li>fancythatwallet Launched: Now available on android and ios</li>

                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>ifancyadate Launched: Now available on android and ios</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>ifancyahotel Launched: Now available on web, android and ios</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>Communication in The Wallet</li>
                  <li>Exchange Built Within The Wallet</li>

                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>New Cryptocurrency Information With Reports</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>Multiple Exchange listings. The First Will be Coming Soon and Then Multiple at a Further Phase</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>ifancyawatch Launch: Early 2024</li>
                  <li>ifancyaphone Launch: Early 2024</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>ifancyahirecar Launch: Early 2024</li>
                  <li>ifancyaflight Launch: Early 2024</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>ifancyaholiday Launch: TBC</li>
                  <li>ifancyabet Launch: 2024</li>
                </ul>
              </div>
            </div>
            {/* <div className="roadmapWrap">
              <span className="phase">Phase 4</span>
              <div className="roadmapWrap_content">
                <ul>
                  <li>Apply For CoinJab Listing</li>
                  <li>Apply For Coingecko Listing</li>
                  <li>Apply For CoinMarketcap Listing</li>
                </ul>
              </div>
            </div>
            <div className="roadmapWrap">
              <div className="roadmapWrap_content">
                <ul>
                  <li>Token Launch</li>
                  <li>Website + Social Media Launch</li>
                  <li>Reach 2,000 Holding Addresses</li>
                </ul>
              </div>
            </div> */}
          </Slider>
        </div>
      </Container>
    </section>
  )
}

export default Roadmap