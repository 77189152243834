import React from "react";
import ScrollToTop from "../../components/ScrollToTop";

const PrivacyPolicy = (props) => {
  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };
  return (
    <section className="Privacy-Policy comnPadd text-white">
      <ScrollToTop />
      <div className="container white-papaer-more">
        <ComnHeading beforeTxt="Privacy Policy" heading="Privacy Policy" />

        {/* <p></p>

        <h3>
            <strong></strong>
          </h3>
          <p></p>
        <ul>
          <li>
            <p></p>
          </li>
        </ul> */}

        <p>
          This information document on personal data describes the practices of
          personal data processing for ifancythat website, as well as through
          the social network accounts of ifancythat, in accordance with art.
          13 of the GDPR (EU Regulation 679/2016).
        </p>
        <p>
          This site is owned and managed by ifancythat Limited and processes
          personal data in accordance with this information note.
        </p>

        <h3>
          <strong>The Economic Operator</strong>
        </h3>
        <p>
          ifancythat Limited as administrator of the ifancythat website,
          processes personal data in accordance with the legislation EU
          Regulation 2016/679 (GDPR), as an operator within the meaning of the
          Regulation. The complete data are: ifancythat Limited Office 16
          Berkley House London Colney St Albans AL2 1BG email Address
          support@ifancythat.com
        </p>

        <h3>
          <strong>Purposes of processing</strong>
        </h3>
        <p>
          When participating, accessing or using the Website or the Services of
          ifancythat, in order to personalize the services offered to you
          (live chat, email), to execute any contracts and / or related payment
          documents: invoices and receipts, payment processing, marketing
          services (periodic emails) or retargeting:
        </p>
        <p>
          Third parties who have access to this data are the card processor, the
          billing partners, and the marketing companies.
        </p>
        <p>
          In the case of data collected as associated operators on social media
          accounts managed by ifancythat (eg Facebook, Instagram, Linkedin,
          Twitter, Youtube) - we collect that data only for normal interaction
          (comments, Like, Share, direct messaging , etc.) with users interested
          in the respective social networks. The legal basis for the collection
          is consent to this data.
        </p>
        <p>
          Personal data on the social networks of ifancythat might be
          collected, if you subscribe to our pages, if you leave us a comment or
          write us a message. All this is collected with your consent through
          the actions you take. This data is used exclusively for the purposes
          indicated by you - from the publication of the comment to the reply to
          you for the message sent. This data is kept until the consent obtained
          through the social network is revoked or until you ask us to delete
          this information.
        </p>
        <p>
          This data is collected together with the respective social networks
          (eg Facebook, Youtube, etc.), with which we are associated operators.
          The functionality of our accounts on these networks is limited by
          those platforms, so we encourage you to read the privacy policies of
          these sites for more details.
        </p>

        <p>
          <strong>Payment Processing</strong>
        </p>
        <p>
          All payments are processed by our partners, so that banking
          information is transmitted directly to them and is not processed by ifancythat. Their personal data processing policies are available on
          their own websites.
        </p>

        <h3>
          <strong>Cookies</strong>
        </h3>
        <p>
          ifancythat may use cookies for the functionality of our websites,
          but also for the optimization of our websites, including
          personalization. Most of the cookies used are those necessary for the
          operation of the website.
        </p>
        <p>
          Regarding the external services ifancythat that place cookies (third
          party), when browsing the ifancythat site we use the Google
          Analytics service, for the purpose of statistical analysis of traffic.
          To minimize data collection, we implemented anonymization of the IP
          address before sending the data to Google and limited the collection
          of other data for 14 months. If you still do not want this cookie, the
          safest and easiest way is to use the browser add- on that prevents
          data from being collected by Google Analytics or to use any other
          add-on with similar functionality.
        </p>

        <h3>
          <strong>Data hosting</strong>
        </h3>
        <p>
          The information, including personal data received on our servers will
          be recorded, used, stored and organized on protected servers, where
          the website itself is hosted, as well as on any other media that allow
          such operations, in optimal security , by appropriate means. For the
          secure hosting of personal data, we use the services of specialized
          companies from Romania or the European Union that act as persons
          empowered by ifancythat.
        </p>

        <h3>
          <strong>Data correction</strong>
        </h3>
        <p>
          For any errors related to the data entered by you, please contact us
          at support@ifancythat.com
        </p>

        <h3>
          <strong>Your rights</strong>
        </h3>
        <p>According to the GDPR, you have the following specific rights:</p>
        <ol>
          <li>
            <p>the right to access personal data;</p>
          </li>
          <li>
            <p>the right to intervene (and correct) data;</p>
          </li>
          <li>
            <p>the right to delete data, if applicable;</p>
          </li>
          <li>
            <p>the right of opposition;</p>
          </li>
          <li>
            <p>the right to restrict processing;</p>
          </li>
          <li>
            <p>the right to data portability, if applicable.</p>
          </li>
        </ol>
        <p>For data collected by consent, you have the right to withdraw your consent at any time.</p>
        <p>You also have the right to lodge a complaint with a competent supervisory authority or to bring
an action in court.</p>
        <p>To exercise these rights, you can send a request by e-mail to Support@ifancythat.com</p>
        <p>For personal data collected by social media networks, we recommend that you contact these
operators directly.</p>

<h3>
            <strong>Sharing of Information</strong>
          </h3>
          <p>The Company may share the Participants’ data with others as follows:</p>
          <p>With partners necessary to acquiring, holding or using the Token and/or providing the Services;</p>
          <p>When using interactive areas of the Website, like our blog or other online forums, certain
information you choose to share may be displayed publicly, such as your username, actions you
take and any content you post;</p>
          <p>To Courts and Government authorities or bodies if requested to do so under a court order or legal
process, or to establish or exercise the Company’s legal rights or defend against legal claims;</p>
          <p>If the Company believes that the Participant’s actions are inconsistent with the Terms or policies,
or to protect the rights, property and safety of the Company or others;</p>
          <p>In connection with, or during negotiations of, any merger, sale of company assets, financing or
acquisition of all or a portion of our business by another company;</p>

          <ol>
          <li>
            <p>Between and among the Company; or</p>
          </li>
          <li>
            <p>With your consent or at your direction.</p>
          </li>
        </ol>

          <p>At any point in time you shall retain all rights to your data.</p>

          <h3>
            <strong>Other provisions</strong>
          </h3>
          <p>In case of special situations, ifancythat may disclose personal data when the law expressly
provides for this, when requested by a competent authority or when this is necessary to protect
the rights and interests of ifancythat.</p>
          <p>The policy regarding the processing of personal data must be interpreted together with the Terms
and Conditions of Use ifancythat and are subject to the laws in force in the United Kingdom.</p>
          <p>If we modify this document, we will publish the new version on the first page of the ifancythat
site and, if possible, we will send information in this regard.</p>

      </div>
    </section>
  );
};

export default PrivacyPolicy;
