import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { ADMIN_NAME } from "../../constant";
import { ADMINLOGIN } from "../../redux/constant.actionTypes";
import { toast } from "../../components/Toast/Toast";
import "./AdminHeader.scss";
// import { admin_log_out } from "../../redux/_actions/apis.action";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const AdminHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCollapes, setIsCollapes] = useState(false);
  //   const walletAddress = useSelector((state) => state.user.walletAddress);
  const authStatus = useSelector((state) => state?.user?.AdminLogin?.token);

  const handleCollapse = (data) => {
    setIsCollapes(!isCollapes);
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Confirm to Log Out</h1>
            <p>Are you sure to do this.?</p>
            <div className="logOutBtn_wrap">
              <button onClick={onClose}>No</button>
              <button
                onClick={() => {
                  _adminLogOut();
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const _adminLogOut = async () => {
    //await dispatch(disconnectAddress());

    // const result = await dispatch(admin_log_out({ token: authStatus }));
    // if (result?.status == 200) {
    //   // navigate(`/admin/adminLogin`);
    // }
    dispatch({ type: ADMINLOGIN, payload: "" });
    toast.success("Logout Successfully.");
  };

  return (
    <div className="headermain">
      <Navbar expand="lg" onToggle={handleCollapse} expanded={isCollapes}>
        <Navbar.Brand href="/dashboard">
          <img src={Logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="top-nav-bar">
            <ul>
              <li>
                <NavLink to={`/admin/AdminDashboard`} className={"mob"}>
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to={`/admin/UserInfo`} className={"mob"}>
                  User List
                </NavLink>
              </li>
              <li>
                <NavLink to={`/admin/Order`} className={"mob"}>
                  Orders
                </NavLink>
              </li>
              <li>
                <NavLink to={`/admin/Changepassword`} className={"mob"}>
                  Setting
                </NavLink>
              </li>
            </ul>
            <div className="right_nav">
              {/* <Nav.Item>My Wallet :  <Nav.Link target="_blank">wallet ...   */}
              {/* {walletAddress.substring(0, 4)}...{walletAddress.substring(39, 42)} */}
              <Nav.Link
                // to={`/admin `}
                onClick={() => submit()}
                className="logout"
              >
                Logout
              </Nav.Link>
              {/* <button onClick={() => submitFunc()}>Logout</button> */}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default AdminHeader;
