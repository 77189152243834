import { combineReducers } from "redux";
import persist from "./persist.reducer";
import user from "./user";
import loader from "./loader";
const appReducer = combineReducers({
  user,
  loader,
  persist,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USERS_PERSIST") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
