import React, { useState, useEffect } from "react";
import { getUserProfile } from "../../redux/_actions/apis.action";
import { useDispatch, useSelector } from "react-redux";
import banner_bg from '../../assets/images/banner-bg.svg'

const ReferralCode = () => {

    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState();
    const [referralLink, setReferralLink] = useState();

    const profileFunc = async () => {
        const result = await dispatch(getUserProfile());
        if (result) {
            let code = result?.data?.userProfile.username.substring(0, 2).toUpperCase() + result?.data?.userProfile.wallet_address.slice(-4)
            setProfileData(code);
            setReferralLink(`${window.location.origin}/register?referral_code=${code}`)
        }
    };

    useEffect(() => {
        profileFunc();
    }, []);

    const ComnHeading = (props) => {
        return (
            <div className="comnHeading">
                <span className="beforeTxt_Style">{props.beforeTxt}</span>
                <h2>{props.heading}</h2>
                <p>{props.subTxt}</p>
            </div>
        );
    };
    return (
        <section className="Dashboard ReferralCode comnPadd text-white" style={{ backgroundImage: `url(${banner_bg})`, }}>

            <div className="container white-papaer-more">
                <ComnHeading beforeTxt="Referral Code" heading="Referral Code"
                
                subTxt={
                    <strong>
                     When your invite purchases fancythattokens for the first time they’ll receive an extra 25% and you’ll receive 25% extra tokens too ($50.00 minimum spend required)
                      {/* <br />
                      {" "}20% unlocked every month after exchange listing  1st June
                      2023 */}
                    </strong>
                  }
                
                />


                <div className="form-group referral-code mx-auto">
                    <label for="inputEmail4">Referral Code</label>
                    <input
                        type="text"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Referral Code"
                        value={profileData}
                    />

                    <div className="text-center mt-4 ">

                        <button
                            className="btn theme-btn my-2"
                            onClick={() => { navigator.clipboard.writeText('I’m a member of fancythattoken’s, if you purchase tokens we will both receive an extra 25% don’t forget to use my code: '+  profileData +' (Minimum spend $50)  '+referralLink) }}

                        >
                            Copy link
                        </button>


                        
                        <a href={`sms:?&body=${'I’m a member of fancythattoken’s, if you purchase tokens we will both receive an extra 25% don’t forget to use my code: '+  profileData +' (Minimum spend $50)  '+referralLink}`} className="btn theme-btn  ml-3 my-2">
                            Share Via Sms
                        </a>

                        <a
                            href={`mailto:?subject=fancythattoken&body=${'I’m a member of fancythattoken’s, if you purchase tokens we will both receive an extra 25% don’t forget to use my code: '+  profileData +' (Minimum spend $50)  '+referralLink } `}
                            className="btn theme-btn ml-3 my-2"
                        >
                            Share Via Email
                        </a>
                    </div>

                </div>

            </div>
        </section>
    );
};

export default ReferralCode;
