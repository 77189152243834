import React, { useEffect } from "react";
import "./CryptoCards.scss";
import Fiat from "./SlidesNew/Fiat";
import Funds from "./SlidesNew/Funds";
import Fees from "./SlidesNew/Fees";
import Sell from "./SlidesNew/Sell";
import { Container } from "react-bootstrap";
import Cashback from "./SlidesNew/Cashback";
import CryptoHero from "./SlidesNew/CryptoHero";
import AOS from "aos";
import "aos/dist/aos.css";

const CryptoCards = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid className="mainCont crypto_temp p-0 crypto-handle" style={{ overflow: "hidden" }}>
        <div data-aos="fade-left" data-aos-duration="1000">
          <CryptoHero />
        </div>
        {/* <CryptoWalletSec /> */}
        {/* <Button/> */}
        <div data-aos="fade-right" data-aos-duration="1000">
          <Cashback />
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <Fees />
        </div>
        <div data-aos="fade-right" data-aos-duration="1000">
          <Fiat />
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <Funds />
        </div>
        <div data-aos="fade-right" data-aos-duration="1000">
          <Sell />
        </div>
        {/* <DebitCardSec /> */}
      </Container>
    </>
  );
};

export default CryptoCards;
