import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

const HowToBuy = () => {
  let navigate = useNavigate();

  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };
  const EmbedVideo = ({ source }) => {
    return (
      <div className="col-12 col-lg-6 mx-auto my-2">
        <embed
          width="100%"
          height="350"
          src={source}
          title="YouTube video player"
        />
      </div>
    );
  };
  return (
    <section className="video-tur comnPadd text-white">
      <div className="container">
        <ComnHeading beforeTxt="How To Buy" heading="How To Buy" />

        {/* <div className="row mb-5">
          <div className="col-12 col-lg-6 mx-auto">
            <embed
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/164VxZJelQ4"
              title="YouTube video player"
            />
          </div>
        </div> */}
        <h1 className="text-center py-4">
          <strong>How to connect your wallet from Desktop/Computer:</strong>
        </h1>
        <div className="border-container">
          <ul>
            <li>
              Install{" "}
              <a href="https://metamask.io/" target="_blank">
                Metamask
              </a>{" "}
              (recommended) since it is the most commonly used wallet for
              interacting with Dapps and web3 services. It has a Google Chrome
              extension that allows you to easily connect to DeFi platforms via
              your browser. Download Metamask from{" "}
              <a href="https://metamask.io/" target="_blank">
                here
              </a>
              .
            </li>
            <li>
              {" "}
              Be sure Binance Smart Chain Network is enabled on Metamask. To add
              Binance Smart Chain to Metamask, follow this tutorial:{" "}
              <a
                href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
                target="_blank"
              >
                How to add Binance Smart Chain to MetaMask
              </a>
            </li>
            <EmbedVideo source={"https://www.youtube.com/embed/164VxZJelQ4"} />

            <li>
              Your now ready to add BNB to your wallet in preparation to
              purchase FNCY Tokens. Firstly, press the buy button and this gives
              you two options. Buy BNB from Transak or buy BNB from Moonpay.
              Both of these sites will require you to set up an account and
              verify yourself by supplying identification. Once you have gone
              through the KYC process you will be able to purchase BNB with GBP
              or any other currency. Once the purchase is complete go back to
              your wallet and your BNB will be there. You are now ready to
              purchase your FNCY Tokens.
            </li>
            <EmbedVideo source={"https://www.youtube.com/embed/_hs7loEYPR0"} />
          </ul>
          <h3>
            <strong>Now we are ready to purchase FNCY tokens!</strong>
          </h3>
          <ol>
            <li>
              Visit the{" "}
              <a href="https://fancythattoken.io" target="_blank">
                https://fancythattoken.io
              </a>{" "}
              website
            </li>
            <li>Press REGISTER NOW.</li>
            <li>
              Insert your full name and email address (this forms part of your
              set up)
            </li>
            <li>
              Connect your wallet (please make sure that your Metamask wallet is
              logged in){" "}
            </li>
            <li>Once your wallet is connected please press REGISTER.</li>
            <li>
              An email will be sent to your email address to verify. Please
              check your spam and promotions inbox.
            </li>
            <li>
              Once your email is confirmed you will be directed back to the log
              in page where you need to input your email address again and
              connect your wallet.
            </li>
            <li>
              You are now inside your dashboard. This is where you can see all
              your future purchases.
            </li>
            <li>
              Please scroll down and select either BNB or BUSD as prefer payment
              of FNCY tokens. The calculation of tokens will then appear, and
              you can then press BUY.
            </li>
            <li>
              This will then take you to your METAMASK wallet where you can
              approve the transaction. Once approved the transaction will take
              place.
            </li>
            <li>
              Please then press the IMPORT TOKENS button and 20% of your tokens
              will immediately go into your wallet.{" "}
            </li>
            <li>
              The other 80% will be claimable as per the vesting schedule.
            </li>
            <EmbedVideo source={"https://www.youtube.com/embed/DScyzaomues"} />
          </ol>
        </div>
        <h1 className="text-center py-4">
          <strong>Buying FNCY tokens from a Mobile Phone:</strong>
        </h1>
        <div className="border-container">
          <p>
            Firstly, you will need to add the Metamask app. Please follow the
            instructions that Metamask have and remember to keep safe the
            password.
          </p>
          <p>
            Once you have added the Metamask app please follow the below
            procedures.
          </p>
          <ul>
            <li>
              Be sure Binance Smart Chain Network is enabled on Metamask. To add
              Binance Smart Chain to Metamask, follow this tutorial:{" "}
              <a
                href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
                target="_blank"
              >
                How to add Binance Smart Chain to MetaMask
              </a>
            </li>
            <EmbedVideo source={"https://www.youtube.com/embed/164VxZJelQ4"} />

            <li>
              Your now ready to add BNB to your wallet in preparation to
              purchase FNCY Tokens. Firstly, press the buy button and this gives
              you two options. Buy BNB from Transak or buy BNB from Moonpay.
              Both of these sites will require you to set up an account and
              verify yourself by supplying identification. Once you have gone
              through the KYC process you will be able to purchase BNB with GBP
              or any other currency. Once the purchase is complete go back to
              your wallet and your BNB will be there. You are now ready to
              purchase your FNCY Tokens.
            </li>
            <EmbedVideo source={"https://www.youtube.com/embed/aav6qb0VnZI"} />
          </ul>

          <h3>
            <strong>Now we are ready to purchase FNCY tokens!</strong>
          </h3>
          <ol start="1">
            <li>
              Visit the{" "}
              <a href="https://fancythattoken.io" target="_blank">
                https://fancythattoken.io
              </a>{" "}
              website{" "}
            </li>
            <li>Press REGISTER NOW. </li>
            <li>
              Insert your full name and email address (this forms part of your
              set up)
            </li>
            <li>
              Connect your wallet. A wallet connect pop up will appear please
              select Metamask. This will connect your wallet to Fancythattoken.
            </li>
            <li>Once your wallet is connected please press REGISTER.</li>
            <li>
              An email will be sent to your email address to verify. Please
              check your spam and promotions inbox.
            </li>
            <li>
              Once your email is confirmed you will be directed back to the log
              in page where you need to input your email address again and
              connect your wallet.{" "}
            </li>
            <li>
              You are now inside your dashboard. This is where you can see all
              your future transactions.
            </li>
            <li>
              Please scroll down and select either BNB or BUSD as preferred
              payment to receive FNCY tokens. The calculation of tokens will
              then appear and you can then press BUY.
            </li>
            <li>
              This will then take you to your METAMASK wallet where you can
              approve the transaction. Once approved the transaction will take
              place.{" "}
            </li>
            <li>
              Please IMPORT TOKENS into your wallet by pressing import within
              your Metamask wallet and paste the smart chain contract for FNCY
              Token. Once completed you will be able to view your tokens within
              your wallet.
            </li>
            <li>
              The other 80% will be claimable as per the vesting schedule.
            </li>
            <EmbedVideo source={"https://www.youtube.com/embed/TNJQGy84k5Q"} />
          </ol>
        </div>
      </div>
    </section>
  );
};

export default HowToBuy;
