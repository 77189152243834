import React, { useState, useEffect } from "react";
import "./Login.scss";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { _updatePassword } from "../../redux/_actions/apis.action";
import { toast } from "../../components/common/Toast";
import { ADMINLOGIN } from "../../redux/constant.actionTypes";
import { ADMIN_NAME } from "../../constant";

const Changepassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");

  const adminChangePassword = async () => {
    let data = {};
    data.currentPassword = oldPass;
    data.newPassword = newPass;
    let updatePassword = await dispatch(_updatePassword(data));
    if (updatePassword?.status == 200) {
      toast.success(updatePassword?.message);
      toast.success("Logout Sucessfully");
      dispatch({ type: ADMINLOGIN, payload: "" });
      navigate(`/${ADMIN_NAME}`);
    }
  };

  return (
    <div className="centerofScreen">
      <Container className="commonTop ">
        <h3 className="title text-center">Change Password </h3>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Form className="logForm AdminCard" style={{ "max-width": "100%" }}>
              <Form.Group className="mb-4 passwordFild" controlId=" ">
                <Form.Control
                  type="password"
                  placeholder="Old Password"
                  value={oldPass}
                  onChange={(e) => setOldPass(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-4 passwordFild" controlId=" ">
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
              </Form.Group>
              <Button
                className="btn theme-btn"
                onClick={() => adminChangePassword()}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Changepassword;
