//

// ******************************************************************

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./index.css";
import ID_Specimen from "../../assets/images/National-ID-specimen.jpg";
import ID_specimenPng from "../../assets/images/National-ID-specimen.jpg";
import gC46Dg_png from "../../assets/images/9gC46Dg.png";
import { Button, Modal, Form, Dropdown, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTokens,
  claimedTokens,
  getAllowanceAction,
  getAllowanceApprovalAction,
  getBalanceAction,
  getTokenApproval,
  getUSDTDecimal,
  lockedTokens,
  lockTokenInformation,
  phaseInformation,
  toBuyTokensAction,
  totalPhases,
  totalTokenSold,
  totalTokenSoldUSD,
  userUSDValueData,
} from "../../redux/_actions/user.action";
import Moment from "moment";
import {
  BUSD_ADDRESS,
  IFANCY_PUBLIC_ADDRESS,
  IFANCY_TOKEN_ADDRESS,
} from "../../constant";
import { CommonService } from "../../services/commonService";
import { toast } from "../../components/common/Toast";
import bannerBG from "../../assets/images/banner-bg.svg";
import Timer from "../../components/Timer";
import moment from "moment";
import Countdown, { zeroPad } from "react-countdown";
import { getUserProfile } from "../../redux/_actions/apis.action";
import { Web3Service } from "../../CommonServices/web3.service";
import { isMobile } from "react-device-detect";
import tokenImg from "../../assets/images/tokenimg.png";
import { getUsdPrice } from "../../CommonServices/BnbToUsd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../assets/images/copy_icon.png";
import { setLoader, stopLoader } from "../../redux/_actions/loader";
import { startMainLoader, stopMainLoader } from "../../redux/_actions/loader";
import { useNavigate, Link } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const authStatus = useSelector((state) => state?.loader?.isLoading);
  const [loading, setloading] = useState(true);
  let navigate = useNavigate();

  const wallet = useSelector((state) => state?.user.connectedWallet);
  const walletAddress = useSelector((state) => state?.user.walletAddress);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputValue, setInputVlaue] = useState(0);
  const [usdtDecimal, setUsdtDecimal] = useState(0);
  const [ifancyToken, setIfancyToken] = useState(0);
  const [dashboardData, setDashboardData] = useState();
  const [amount, setAmount] = useState(0);
  const [currencyType, seCurrencyType] = useState("");
  const [type, setType] = useState("");
  const [totalSoldToken, setTotalSoldToken] = useState("");
  const [totalSoldTokenPrice, setTotalSoldTokenPrice] = useState("");
  const [tokenBalane, setTokenBalane] = useState();
  const [phaseInfoVal, setPhaseInfo] = useState();
  const [phaseInfoOldValue, setPhaseInfoOld] = useState();
  const [phaseInfoNextValue, setPhaseInfoNext] = useState();
  const [checkPast, setCheckPast] = useState(false);
  const [checkNext, setCheckNext] = useState(false);
  const [iCORunning, setICORunning] = useState(false);
  const [ifanctTokenDecimal, setTokenDecimal] = useState(0);
  const [myLockedTokens, setUserLockedTokens] = useState(0);
  const [userUsdValue, setUserUsdValue] = useState(0);
  const [latestBNBPrice, setLatestBNBPrice] = useState();
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [modalStates1, setModalStates1] = useState(false);
  const handleClosed = () => setModalState(false);
  const handleCloseds = () => setModalStates(false);
  const handleCloseds1 = () => setModalStates1(false);
  const handleShowd = () => setModalState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [updatedPrice, setUpdatedPrice] = useState();

  const timerOut = useRef(null);

  const getBnbDetails = async () => {
    const result = await getUsdPrice();
    if (result) {
      setUpdatedPrice(result / 10 ** 8);
    }
  };
  useLayoutEffect(() => {
    getBnbDetails();
  }, []);

  const importToken = () => {
    setModalStates(true);
  };

  const selectNetwok = (currtype) => {
    seCurrencyType(currtype);
    setType(currtype == "USDT" ? 2 : currtype == "BNB" ? 1 : 1);
    if (!inputValue == 0) {
      inputAmount("", inputValue);
    }
  };

  const claimedToken = async () => {
    const userLockedTokens = await dispatch(
      lockedTokens({
        wallet: wallet,
        to: walletAddress,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );
    setUserLockedTokens(userLockedTokens);
  };

  const userUSDValue = async () => {
    const usersUSDValue = await dispatch(
      userUSDValueData({
        wallet: wallet,
        to: walletAddress,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );
    setUserUsdValue(usersUSDValue);
  };

  const inputAmount = async (event) => {
    let intValue = CommonService.allowOnlyNumber(event) ? event : "";
    setInputVlaue(intValue);
    // await selectNetwok(currencyType);
    if (timerOut.current) clearTimeout(timerOut.current);
    if (!intValue == 0) {
      timerOut.current = setTimeout(async () => {
        const busdDecimal = await dispatch(
          getUSDTDecimal({
            wallet: wallet,
            address: BUSD_ADDRESS,
          })
        );
        console.log("busdDecimal", busdDecimal);
        setUsdtDecimal(busdDecimal);
        const tokenDecimal = await dispatch(
          getUSDTDecimal({
            wallet: wallet,
            address: IFANCY_TOKEN_ADDRESS,
          })
        );
        let _amount = CommonService.convertWithDecimal(
          intValue,
          type == 1 ? 10 ** 18 : type == 2 ? 10 ** busdDecimal : 10 ** 18
        );
        setAmount(_amount);
        console.log("wallet,_amount", wallet, _amount, type, busdDecimal);
        let CalculateToken = await dispatch(
          calculateTokens({
            wallet: wallet,
            _amount,
            currencyType: type ? type : 1,
            address: IFANCY_PUBLIC_ADDRESS,
          })
        );

        setIfancyToken(
          CalculateToken == undefined
            ? 0
            : CalculateToken[0] / 10 ** tokenDecimal
        );
      }, 1000);
    } else {
      setIfancyToken(0);
    }
  };
  const buyModalfirst = async () => {
    if (isMobile) {
      setModalStates1(true);
      setTimeout(() => {
        setModalStates1(false);
      }, 4000);
      setTimeout(() => {
        byuModal();
      }, 4500);
    } else {
      byuModal();
    }
  };

  const byuModal = async () => {
    setShow(false);
    let balance = await dispatch(
      getBalanceAction({
        wallet: wallet,
        walletAddress,
        address: BUSD_ADDRESS,
      })
    );
    let allowance = await dispatch(
      getAllowanceAction({
        wallet: wallet,
        walletAddress,
        address: BUSD_ADDRESS,
      })
    );

    if (Number(allowance) < Number(amount)) {
      let getApproval = await dispatch(
        getTokenApproval({
          wallet: wallet,
          to: walletAddress,
          type: type ? type : 1,
          address: BUSD_ADDRESS,
        })
      );
      if (getApproval?.status) {
        let butToken = await dispatch(
          toBuyTokensAction({
            wallet: wallet,
            to: walletAddress,
            amount,
            type: type ? type : 1,
            address: IFANCY_PUBLIC_ADDRESS,
          })
        );

        if (butToken?.status) {
          setTimeout(() => {
            setModalState(true);
          }, 1000);
          setTimeout(() => {
            setModalState(false);
            window.location.reload();
          }, 5000);
        }
      }
    } else if (Number(allowance) >= Number(amount)) {
      let butToken = await dispatch(
        toBuyTokensAction({
          wallet: wallet,
          to: walletAddress,
          amount,
          type: type ? type : 1,
          address: IFANCY_PUBLIC_ADDRESS,
        })
      );
      if (butToken?.status) {
        setTimeout(() => {
          setModalState(true);
        }, 1000);
        setTimeout(() => {
          setModalState(false);
          window.location.reload();
        }, 5000);
      }
    } else if (Number(balance) < Number(amount)) {
      toast.error("Insufficient Balance");
    }
    // setAmount(0);
  };

  useEffect(async () => {
    // setTimeout(() => {
    //   setLoadingState(true);
    // }, 500);
    // setTimeout(() => {
    //   setLoadingState(false);
    // }, 7000);
    toRemovePreviousData();
  }, []);

  useEffect(() => {
    dispatch(startMainLoader())
    Promise.all([
      claimedToken(),
      userUSDValue(),
      TotalSoldToken(),
      total_Phases(),
      seCurrencyType("BNB")
    ]).then(() => {
      dispatch(stopMainLoader())
    })
  }, [wallet, walletAddress]);

  const copyClipBoard = () => {
    toast.success("Copied");
  };

  const toRemovePreviousData = () => {
    setIfancyToken(0);
    setInputVlaue("");
  };
  const TotalSoldToken = async () => {
    const tokenDecimal = await dispatch(
      getUSDTDecimal({
        wallet: wallet,
        address: IFANCY_TOKEN_ADDRESS,
      })
    );
    setTokenDecimal(tokenDecimal);

    const usdtDecimal = await dispatch(
      getUSDTDecimal({
        wallet: wallet,
        address: BUSD_ADDRESS,
      })
    );

    let soldToken = await dispatch(
      totalTokenSold({
        wallet: wallet,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );

    let totalTokenPrice = await dispatch(
      totalTokenSoldUSD({
        wallet: wallet,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );

    let balance = await dispatch(
      getBalanceAction({
        wallet: wallet,
        walletAddress,
        address: IFANCY_TOKEN_ADDRESS,
      })
    );

    setTotalSoldToken(soldToken / 10 ** tokenDecimal);
    setTotalSoldTokenPrice(totalTokenPrice / 10 ** usdtDecimal);
    setTokenBalane(balance / 10 ** tokenDecimal);
  };

  const convertInMilions = (value) => {
    let milionValue = `${
      Math.abs(value) > 999999
        ? (Math.sign(value) * (Math.abs(value) / 1000000).toFixed(1)).toFixed(
            2
          ) + "M"
        : Math.abs(value) > 999
        ? (Math.sign(value) * (Math.abs(value) / 1000).toFixed(1)).toFixed(2) +
          "k"
        : (Math.sign(value) * Math.abs(value)).toFixed(2)
    }`;
    milionValue = milionValue === "NaN" ? 0 : milionValue;
    return milionValue;
  };

  const checkPhase = (value) => {
    if (value == 1) {
      return "Pre Pre Sale";
    } else if (value == 2) {
      return "Seed Sale";
    } else {
      return `Phase ${value - 2}`;
    }
  };

  const total_Phases = async () => {
    let totalPhase = await dispatch(
      totalPhases({
        wallet: wallet,
        walletAddress,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );

    let currentTimeStapm = new Date().getTime() / 1000;

    let currentPhase;
    let phaseInfoValue;
    if (totalPhase) {
      for (let i = 1; i <= totalPhase; i++) {
        let phaseInfo = await dispatch(
          phaseInformation({
            wallet: wallet,
            type: i,
            address: IFANCY_PUBLIC_ADDRESS,
          })
        );
        if (
          phaseInfo?.startTimestamp <= currentTimeStapm &&
          phaseInfo?.expirationTimestamp >= currentTimeStapm
        ) {
          currentPhase = i;
          setICORunning(true);

          phaseInfoValue = phaseInfo;
          phaseInfoValue.phase = i;
          setPhaseInfo(phaseInfoValue);
          setloading(false);
          break;
        } else if (phaseInfo?.startTimestamp > currentTimeStapm) {
          setICORunning(false);
          currentPhase = i;
          phaseInfoValue = phaseInfo;
          phaseInfoValue.phase = i;
          setPhaseInfo(phaseInfoValue);
          setloading(false);
          break;
        } else if (
          i == totalPhase &&
          phaseInfo?.expirationTimestamp < currentTimeStapm
        ) {
          setICORunning(false);

          currentPhase = i + 1;
          setPhaseInfo();
          setloading(false);
          break;
        }
      }
    } else setloading(false);

    let oldPhase = currentPhase - 1;
    let nextPhase = currentPhase + 1;
    if (oldPhase > 0) {
      setCheckPast(true);
      let phaseInfoOld = await dispatch(
        phaseInformation({
          wallet: wallet,
          type: oldPhase,
          address: IFANCY_PUBLIC_ADDRESS,
        })
      );
      phaseInfoOld.oldPhase = oldPhase;
      setPhaseInfoOld(phaseInfoOld);
      setloading(false);
    }
    if (nextPhase <= totalPhase) {
      setCheckNext(true);
      let phaseInfoNext = await dispatch(
        phaseInformation({
          wallet: wallet,
          type: nextPhase,
          address: IFANCY_PUBLIC_ADDRESS,
        })
      );
      phaseInfoNext.nextPhase = nextPhase;
      setPhaseInfoNext(phaseInfoNext);
      setloading(false);
    }
  };

  const momentTime = (timeStap) => {
    return moment(timeStap * 1000).format("Do MMM");
  };
  const momentTimer = (timeStap) => {
    return moment(timeStap * 1000).format("M/DD/YYYY hh:mm:ss"); //"4/27/2022 09:00:00"
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      {
        window.location.reload();
      }

      return (
        <div className="countdownTimer">
          {/* <div className="timerBlocks box">
            <h5>00</h5>
            <p>Days</p>
          </div>
          <p>:</p>
          <div className="timerBlocks box">
            <h5>00</h5>
            <p>Hours</p>
          </div>
          <p>:</p>
          <div className="timerBlocks box">
            <h5>00</h5>
            <p>Minutes</p>
          </div>
          <p>:</p>
          <div className="timerBlocks box">
            <h5>00</h5>
            <p>Seconds</p>
          </div> */}
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div className="countdownTimer">
          <div className="timerBlocks ">
            <div className="box">
              <h5 className="text-dark">{zeroPad(days)}</h5>
            </div>
            <p>Days</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              <h5 className="text-dark">{zeroPad(hours)}</h5>
            </div>
            <p>Hours</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              {" "}
              <h5 className="text-dark">{zeroPad(minutes)}</h5>
            </div>
            <p>Minutes</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              {" "}
              <h5 className="text-dark">{zeroPad(seconds)}</h5>
            </div>
            <p>Seconds</p>
          </div>
        </div>
      );
    }
  };

  const profileFunc = async () => {
    const result = await dispatch(getUserProfile());
    if (result) {
      setDashboardData(result?.data);
    }
  };

  useEffect(() => {
    profileFunc();
  }, []);

  return (
    <div
      className=" Dashboard p-3"
      style={{ backgroundImage: `url(${bannerBG})` }}
    >
      <div className="container">
        <div className="d-flex justify-content-around text-right">
          <button
            className="btn theme-btn import-mobile"
            onClick={() => navigate('/referral-code')}
          >
            {"Referral code"}
          </button>
          <button
            className="btn theme-btn import-mobile"
            onClick={() => importToken()}
          >
            {"Import Tokens"}
          </button>
        </div>

        <div className="row  das-status">
          <div className="col-12 col-md-12 col-lg-4 mt-3">
            <div className="card">
              <p>Total Tokens Purchased</p>
              <h5>
                {Number(
                  myLockedTokens / 10 ** ifanctTokenDecimal
                ).toLocaleString("US")}{" "}
                FNCY
              </h5>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-4 mt-3">
            <div className="card">
              <p>Total Value Spend</p>
              <h5>
                $
                {userUsdValue
                  ? Number(userUsdValue / 10 ** 8).toLocaleString("US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </h5>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-4 mt-3">
            <div className="card">
              <p>Current Value</p>
              <h5>
                $ ={" "}
                {Number(
                  (myLockedTokens / 10 ** ifanctTokenDecimal) *
                    (iCORunning
                      ? phaseInfoVal?.price / 10 ** 5
                      : phaseInfoOldValue?.price / 10 ** 5)
                )
                  ? Number(
                      (myLockedTokens / 10 ** ifanctTokenDecimal) *
                        (iCORunning
                          ? phaseInfoVal?.price / 10 ** 5
                          : phaseInfoOldValue?.price / 10 ** 5)
                    ).toLocaleString("US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </h5>
            </div>
          </div>
        </div>
        <div className="row inner-card  my-4">
          <div className="col-12 col-md-4">
            <div className="col-12 p-0">
              <div className="card">
                <h5 className="font-weight-bold"> Your Tokens</h5>
                <hr></hr>
                <h4 className="text-center">
                  Claimable Tokens ={" "}
                  {tokenBalane ? Number(tokenBalane).toLocaleString("US") : 0}{" "}
                  FNCY
                </h4>
                <h4 className="text-center">
                  Worth of Tokens = $
                  {tokenBalane &&
                  (phaseInfoVal?.price || phaseInfoOldValue?.price)
                    ? Number(
                        tokenBalane *
                          (iCORunning
                            ? phaseInfoVal?.price / 10 ** 5
                            : phaseInfoOldValue?.price / 10 ** 5)
                      ).toLocaleString("US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </h4>
              </div>
            </div>
            {checkPast ? (
              <div className="col-12 p-0 mt-3">
                <h5 className="text-white font-weight-bold my-4">Past</h5>
                <div className="card text-center">
                  <div className="d-flex justify-content-between">
                    <h5>{`${checkPhase(
                      phaseInfoOldValue?.oldPhase
                    )} ended`}</h5>
                  </div>
                  <hr></hr>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="font-weight-bold">Price</p>
                    <h5 className="font-weight-bold">
                      ${phaseInfoOldValue?.price / 10 ** 5}
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="font-weight-bold">Period</p>
                    <h5 className="font-weight-bold">
                      {`${momentTime(phaseInfoOldValue?.startTimestamp)}`}-
                      {`${momentTime(phaseInfoOldValue?.expirationTimestamp)}`}
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="font-weight-bold">Sold</p>

                    <h5 className="font-weight-bold">
                      {Number(
                        phaseInfoOldValue?.tokenSold / 10 ** ifanctTokenDecimal
                      ).toLocaleString("US")}{" "}
                      FNCY
                    </h5>
                  </div>

                  {/* <div className="progress my-progress">
                  <div className="progress-bar w-75 text-End" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span>75%</span></div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p >0%</p>
                  <p >100%</p>
                </div> */}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {phaseInfoVal ? (
            <div className="col-12 col-md-8 mt-3 mt-md-0 main-section">
              <div className="banner p-0">
                <div className="card text-center">
                  <h5 className="font-weight-bold text-dark">
                    {`${checkPhase(phaseInfoVal?.phase)} Details`}{" "}
                  </h5>
                  <h6 className="font-weight-bold my-3">
                    {iCORunning ? "" : "Coming soon"}
                  </h6>
                  <hr></hr>
                  <div className="timer-dark d-flex justify-content-between ">
                    <h6 className="font-weight-bold  mb-0">
                      {iCORunning ? "Live" : "Starts In"}
                    </h6>

                    <Countdown
                      date={
                        JSON.parse(
                          iCORunning
                            ? phaseInfoVal?.expirationTimestamp
                            : phaseInfoVal?.startTimestamp
                        ) * 1000
                      }
                      renderer={renderer}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="font-weight-bold">Price</p>
                    <h5 className="font-weight-bold text-dark ">
                      ${phaseInfoVal?.price / 10 ** 5}
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="font-weight-bold">Network</p>
                    <h5 className="font-weight-bold text-dark ">BNB</h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="font-weight-bold">Min Purchase</p>
                    <h5 className="font-weight-bold text-dark ">
                      ${phaseInfoVal?.minPurchaseAmount / 10 ** 8}
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="font-weight-bold">Max Purchase</p>
                    <h5 className="font-weight-bold text-dark ">
                      ${phaseInfoVal?.maxPurchaseAmount / 10 ** 8}
                    </h5>
                  </div>
                  {/* <div className="progress my-progress"> */}
                  {/* <div className="progress-bar w-0 text-End" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"> */}

                  {/* {<span>{`${(phaseInfoVal?.tokenSold * 100) / phaseInfoVal?.tokenLimit}%`}</span>} */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* <div className="progressBarText">
                    <ProgressBar
                      now={Number(phaseInfoVal?.tokenSold)}
                      label={`${(
                        (phaseInfoVal?.tokenSold * 100) /
                        phaseInfoVal?.tokenLimit
                      ).toFixed(2)} %`}
                      variant="custom"
                      max={Number(phaseInfoVal?.tokenLimit)}
                    />
                  </div> */}

                  {/* <div className="d-flex justify-content-between align-items-center">
                    <p>0k</p>
                    <p>
                      {convertInMilions(
                        phaseInfoVal?.tokenLimit / 10 ** ifanctTokenDecimal
                      )}
                    </p>
                  </div> */}
                  <hr></hr>
                  <h5 className="font-weight-bold text-dark">
                    Buy tokens in BNB or BUSD
                  </h5>
                  <hr></hr>
                  <div className="row align-items-center token-formula">
                    <div className="col-12 col-md-7">
                      <div className="form-group m-0">
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                          onChange={(e) => selectNetwok(e.target.value)}
                        >
                          {/* <label for="framework">Select Token</label> */}
                          <option value="BNB">Binance Coin (BNB)</option>
                          <option value="USDT">Tether (BUSD)</option>
                        </select>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={inputValue.toLocaleString("US")}
                        value={inputValue}
                        onChange={(e) => inputAmount(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-1">
                      <h5 className="text-dark">=</h5>
                    </div>
                    <div className="col-12 col-md-4 mb-2">
                      <input
                        type="number"
                        className="form-control"
                        value={ifancyToken}
                        disabled
                      />
                      <h6>FNCY</h6>
                    </div>
                  </div>
                  <hr></hr>
                  {inputValue && updatedPrice && currencyType == "BNB" ? (
                    <div class="col-12 col-md-12 mb-2">
                      <input
                        type="text"
                        class="form-control text-center"
                        disabled={true}
                        value={
                          inputValue && updatedPrice > 0
                            ? Number(inputValue * updatedPrice).toLocaleString(
                                "US"
                              )
                            : 0
                        }
                      />
                      <h6>USD</h6>
                    </div>
                  ) : null}
                  <div className="text-center my-3">
                    <button
                      className="btn theme-btn"
                      disabled={!iCORunning}
                      onClick={buyModalfirst}
                    >
                      {iCORunning ? "Buy " : "Coming soon"}

                      {/* {iCORunning
                        ? ""
                        : `starts after ${momentTime(
                            phaseInfoVal?.startTimestamp
                          )}`} */}
                    </button>
                  </div>

                  <p className="text-left mt-3">
                    Use your wallet to send your funds to the address shown.
                    Please make sure that the funds in your wallet are enough to
                    buy tokens. The deposit will appear on your account only
                    after several confirmations. It can takes up to 2 hours to
                    get them
                  </p>
                </div>
                {checkNext ? (
                  <div className="p-0 mt-3">
                    <h5 className="text-white font-weight-bold my-4">
                      Next Stage
                    </h5>
                    <div className="card text-center mt-3">
                      <h5 className="font-weight-bold text-dark">
                        {`${checkPhase(phaseInfoNextValue?.nextPhase)} Details`}{" "}
                      </h5>
                      {/* <h6 className="font-weight-bold my-3">Sale to Details</h6> */}
                      <hr></hr>
                      {/* <div className="d-flex justify-content-between align-items-center">
                    <h6 className="font-weight-bold  mb-0">State</h6>
                    <Timer />
                  </div> */}
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="font-weight-bold">Price</p>
                        <h5 className="font-weight-bold text-dark ">
                          ${phaseInfoNextValue?.price / 10 ** 5}
                        </h5>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="font-weight-bold">Period</p>
                        {/* <h5 className="font-weight-bold text-dark ">1st June - 30th June</h5> */}

                        <h5 className="font-weight-bold text-dark ">
                          {" "}
                          {`${momentTime(phaseInfoNextValue?.startTimestamp)}`}-
                          {`${momentTime(
                            phaseInfoNextValue?.expirationTimestamp
                          )}`}
                        </h5>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="font-weight-bold">Network</p>
                        <h5 className="font-weight-bold text-dark ">BNB</h5>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="font-weight-bold">Min Purchase</p>
                        <h5 className="font-weight-bold text-dark ">
                          ${phaseInfoNextValue?.minPurchaseAmount / 10 ** 8}{" "}
                        </h5>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="font-weight-bold">Max Purchase</p>
                        <h5 className="font-weight-bold text-dark ">
                          ${phaseInfoNextValue?.maxPurchaseAmount / 10 ** 8}{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="col-12 col-md-8 text-center align-items-center">
              <h5 className="font-weight-bold text-white textCenter">
                {loading
                  ? "Loading "
                  : "ICO ENDED"}{" "}
              </h5>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={modalState}
        onHide={handleClosed}
        keyboard={false}
        className="connect_modal process_cl"
      >
        <Modal.Body className="modal_body12 ht_fix">
          <button
            className="closeBtn"
            type="button"
            onClick={() => handleClosed()}
          >
            X
          </button>
          <h2>
            Congratulations on your purchase of FNCY Tokens please import your
            tokens to your wallet by pressing the import tokens button. 20% will
            be deposited now and the rest as per the vesting schedule.
          </h2>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalStates}
        onHide={handleCloseds}
        keyboard={false}
        className="connect_modal process_cl"
      >
        <Modal.Body className="modal_body12 ht_fix">
          <button
            className="closeBtn"
            type="button"
            onClick={() => handleCloseds()}
          >
            X
          </button>
          <h4 className="mb-4 text-center">IFANCY Token Address</h4>
          <span className="d-flex btn_fleximg">
            <h4>{IFANCY_TOKEN_ADDRESS} </h4>{" "}
            <CopyToClipboard text={IFANCY_TOKEN_ADDRESS} onCopy={copyClipBoard}>
              <span>
                {" "}
                <img src={copy} alt="Copy" width="24" />
              </span>
            </CopyToClipboard>
          </span>
          <span>
            Copy this token address, open your Metamask wallet, press import
            tokens and paste into token contract address.
          </span>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalStates1}
        onHide={handleCloseds1}
        keyboard={false}
        className="connect_modal process_cl"
      >
        <Modal.Body className="modal_body12 ht_fix">
          <button
            className="closeBtn"
            type="button"
            onClick={() => handleCloseds1()}
          >
            X
          </button>
          <h3>
            Please open MetaMask application in your phone and approve the
            transaction for your purchase of FancyThatToken.
          </h3>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dashboard;
