import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { ADMIN_NAME } from "../constant";

const AdminAuthGuard = () => {
  const authStatus = useSelector((state) => state?.user?.AdminLogin?.token);

  return authStatus ? (
    <Outlet />
  ) : (
    <Navigate to={`/${ADMIN_NAME}/adminLogin`} replace />
  );
};

export default AdminAuthGuard;
