import React, { useState, useEffect } from "react";
import "./index.css";
import { getTransactionDetails } from "../../redux/_actions/apis.action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../assets/images/copy_icon.png";
import {
  BNB_TX_ADDRESS,
  BUSD_ADDRESS,
  IFANCY_PUBLIC_ADDRESS,
  IFANCY_TOKEN_ADDRESS,
} from "../../constant";
import {
  claimedTokens,
  claimeToken,
  getUSDTDecimal,
  lockedTokens,
  lockTokenInformation,
} from "../../redux/_actions/user.action";
import { toast } from "../../components/Toast/Toast";
import Countdown, { zeroPad } from "react-countdown";
import { Modal } from "react-bootstrap";

const TransactionHistory = () => {
  const dispatch = useDispatch();
  let decimals = 10 ** 8;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [transactionData, setTransactionData] = useState([]);
  const [tokenDecimals, setDecimals] = useState(0);
  const [busdDecimal, setbusdDecimals] = useState(0);
  const [timeStamp, setTimeStamp] = useState();
  const [totalTokensPurchased, setTotalTokens] = useState(0);
  const [depositedRem, setDepositedRem] = useState(0);
  const [vestingPoint, setVestingPoint] = useState(0);
  const [claimedPoint, setClaimedPoint] = useState(0);

  const wallet = useSelector((state) => state?.user.connectedWallet);
  const walletAddress = useSelector((state) => state?.user.walletAddress);
  const transactionFunc = async () => {
    const tokenDecimal = await dispatch(
      getUSDTDecimal({ wallet: wallet, address: IFANCY_TOKEN_ADDRESS })
    );
    setDecimals(tokenDecimal);
    const busdDecimal = await dispatch(
      getUSDTDecimal({ wallet: wallet, address: BUSD_ADDRESS })
    );
    setbusdDecimals(busdDecimal);
    const datas = {
      page: page,
      limit: limit,
    };
    const result = await dispatch(getTransactionDetails(datas));
    setTransactionData(result?.data?.claimList);
  };

  useEffect(() => {
    transactionFunc();
  }, []);
  const copyClipBoard = () => {
    toast.success("Copied");
  };

  useEffect(() => {
    claimedToken();
  }, []);

  const claimedToken = async () => {
    const userLockedTokens = await dispatch(
      lockedTokens({
        wallet: wallet ? wallet : "METAMASK",
        to: walletAddress,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );
    setTotalTokens(userLockedTokens / 10 ** tokenDecimals);

    const userClaimedTokens = await dispatch(
      claimedTokens({
        wallet: wallet ? wallet : "METAMASK",
        to: walletAddress,
        address: IFANCY_PUBLIC_ADDRESS,
      })
    );
    let claimpoint = userClaimedTokens / (userLockedTokens / 5) - 1;
    setClaimedPoint(claimpoint);

    setDepositedRem(userClaimedTokens / 10 ** tokenDecimals);
    let lockedToken = userLockedTokens - userClaimedTokens;
    let currentTimeStapm = new Date().getTime() / 1000;
    let isSelected;
    for (let i = 1; i <= 4; i++) {
      let lockTokenInfo = await dispatch(
        lockTokenInformation({
          wallet: wallet ? wallet : "METAMASK",
          type: i,
          address: IFANCY_PUBLIC_ADDRESS,
        })
      );
      if (lockTokenInfo.expirationTimestamp > currentTimeStapm && !isSelected) {
        isSelected = true;
        setVestingPoint(i - 1);
        setTimeStamp(lockTokenInfo?.expirationTimestamp);
      } else if (
        lockTokenInfo.expirationTimestamp < currentTimeStapm &&
        !isSelected &&
        i == 4
      ) {
        isSelected = true;
        setVestingPoint(i);
        setTimeStamp(lockTokenInfo?.expirationTimestamp);
      }
    }
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      {
        window.location.reload();
      }

      return <div className="countdownTimer"></div>;
    } else {
      // Render a countdown
      return (
        <div className="countdownTimer">
          <div className="timerBlocks ">
            <div className="box">
              <h5 className="text-dark">{zeroPad(days)}</h5>
            </div>
            <p>Days</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              <h5 className="text-dark">{zeroPad(hours)}</h5>
            </div>
            <p>Hours</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              {" "}
              <h5 className="text-dark">{zeroPad(minutes)}</h5>
            </div>
            <p>Minutes</p>
          </div>
          <div className="timerBlocks ">
            <div className="box">
              {" "}
              <h5 className="text-dark">{zeroPad(seconds)}</h5>
            </div>
            <p>Seconds</p>
          </div>
        </div>
      );
    }
  };

  const claimedLokedToken = async () => {
    const claimed_LokedToken = await dispatch(
      claimeToken({ wallet, address: IFANCY_PUBLIC_ADDRESS, to: walletAddress })
    );
  };

  return (
    <div
      className="Dashboard p-3"
      style={{
        backgroundImage: `url(${require("../../assets/images/banner-bg.svg")})`,
      }}
    >
      <div className="container">
        <div className="transaction-history">
          <h4 className="text-white my-3 font-weight-bold">
            Transaction History
          </h4>
          <div className="table-responsive-lg">
            <table className="table table-bordered table_white_spce" responsive>
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">time</th>
                  <th scope="col">Deposited BNB/BUSD</th>
                  {/* <th scope="col">Token Type</th> */}
                  <th scope="col">Total Fancy Tokens</th>
                  <th scope="col">Price</th>
                  <th scope="col">Phase</th>
                  <th scope="col">FNCY Tokens claimable</th>
                  <th scope="col">Transaction Hash</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {transactionData && transactionData?.length > 0 ? (
                  <>
                    {transactionData.map((data) => {
                      return (
                        <tr>
                          <td scope="row">
                            {moment(data?.created_at).format("DD-MM-YYYY ")}
                          </td>
                          <td scope="row">
                            {moment(data?.created_at).format(" hh:mm:ss")}
                          </td>
                          <td>
                            {data?.buyAmount
                              ? data?.buyAmount /
                                (data?.tokenType == 1
                                  ? 10 ** 18
                                  : 10 ** busdDecimal)
                              : null}{" "}
                            {data?.tokenType == 1 ? "BNB" : "BUSD"}
                          </td>
                          {/* <td>{data?.tokenType == 1 ? "BNB" : "BUSD"}</td> */}
                          <td>
                            {data?.noOfTokens
                              ? data?.noOfTokens / 10 ** tokenDecimals
                              : 0}
                          </td>
                          <td>{data?.price ? data?.price : 0}</td>
                          <td>
                            {data?.phase == "1"
                              ? "Pre Pre Sale"
                              : data?.phase == 2
                              ? "seed sale"
                              : data?.phase < 2
                              ? Number(data?.phase)
                              : 0}
                          </td>
                          <td>
                            {data?.userTransferTokens
                              ? data?.userTransferTokens / 10 ** tokenDecimals
                              : 0}
                          </td>
                          <td>
                            <a
                              title={data?.tx_address}
                              href={`${BNB_TX_ADDRESS}${data?.tx_address}`}
                              target="_blank"
                            >
                              {data?.tx_address?.slice(0, 6)}....
                              {data?.tx_address?.slice(-6, -1)}
                            </a>
                            <CopyToClipboard
                              text={data?.tx_address}
                              onCopy={copyClipBoard}
                            >
                              <img
                                src={copy}
                                alt="Copy"
                                width="24"
                                className="imgStyle"
                              />
                            </CopyToClipboard>
                          </td>
                          <td>{data?.Status}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr scope="row">
                    <td colSpan="6" className="text-center">
                      No Data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalTokensPurchased > 0 ? (
            <>
              <div className="my-5">
                <div className="d-block d-md-flex align-items-center justify-content-between">
                  <h6>Total Tokens Purchased</h6>
                  <p>{totalTokensPurchased / 10 ** tokenDecimals} FNCY</p>
                </div>
                <div className="d-block d-md-flex align-items-center justify-content-between">
                  <h6>Total Deposited @20%</h6>
                  <p>{depositedRem / 10 ** tokenDecimals} FNCY</p>
                </div>
                <div className="d-block d-md-flex align-items-center justify-content-between">
                  {timeStamp && vestingPoint < 4 ? (
                    <>
                      <h6>Next Claim Time</h6>
                      <p>
                        <Countdown
                          date={timeStamp * 1000}
                          renderer={renderer}
                        />
                      </p>{" "}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="d-block d-md-flex align-items-center justify-content-between">
                <h6>Claim Tokens 1st July @20% </h6>

                <button className="btn theme-btn rounded mt-2">
                  {vestingPoint >= 1
                    ? claimedPoint >= 1
                      ? "Claimed"
                      : "Claimable"
                    : "Not Ready"}
                </button>
              </div>
              <div className="d-block d-md-flex align-items-center justify-content-between">
                <h6>Claim Tokens 1st Auguest @20% </h6>
                <button className="btn theme-btn rounded mt-2">
                  {vestingPoint >= 2
                    ? claimedPoint >= 2
                      ? "Claimed"
                      : "Claimable"
                    : "Not Ready"}
                </button>
              </div>
              <div className="d-block d-md-flex align-items-center justify-content-between">
                <h6>Claim Tokens 1st September @20% </h6>
                <button className="btn theme-btn rounded mt-2">
                  {vestingPoint >= 3
                    ? claimedPoint >= 3
                      ? "Claimed"
                      : "Claimable"
                    : "Not Ready"}
                </button>
              </div>
              <div className="d-block d-md-flex align-items-center justify-content-between">
                <h6>Claim Tokens 1st October @20% </h6>
                <button className="btn theme-btn rounded mt-2">
                  {vestingPoint >= 4
                    ? claimedPoint >= 4
                      ? "Claimed"
                      : "Claimable"
                    : "Not Ready"}
                </button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {claimedPoint < vestingPoint ? (
        <button
          className="btn theme-btn d-md-flex rounded mt-2 justify-content-center mx-auto"
          onClick={claimedLokedToken}
        >
          Claim Tokens
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default TransactionHistory;
