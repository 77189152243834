import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "../pages/dashboard/index";

const NoAuth = () => {
  const userAuthenticated = useSelector((state) => state.user?.login?.token);
  const navigate = useNavigate();
  const dashFunc = () => {};
  // alert("userAuthenticated"+ userAuthenticated);
  return !userAuthenticated ? (
    <Outlet />
  ) : (
    window.location.replace("/dashboard")
  );
  // return <Outlet />;
};

export default NoAuth;
