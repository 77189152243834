import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Spinner } from "react-bootstrap";
import Loader from "react-loader-spinner";
import loaderimg from "../../assets/images/loaderimg.png"
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.scss";
function LoaderComponent() {
    const authStatus = useSelector((state) => state?.loader?.isLoading);
    // const mainLoader = useSelector((state) => state?.loader?.mainLoader);
    const [mainLoader, setMainLoader] = useState(true)

    useEffect(() => {
        AOS.init();

        setTimeout(() => {
            setMainLoader(false)
        }, 2000);

    }, []);

    if (window.location.pathname === "/" || window.location.pathname === "/dashboard") {

        if (mainLoader) {
            return (
                <div class="overlayloader">
                    <div data-aos="zoom-in" data-aos-duration="3000" class="h-100">
                        <div class="logo-hold"  >
                            <img src={loaderimg} width="170px" />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    } else if (authStatus) {
        return (
            <div class="overlayloader">
                <div data-aos="zoom-in" data-aos-duration="3000" class="h-100">
                    <div class="logo-hold"  >
                        <img src={loaderimg} width="170px" />
                    </div>
                </div>
            </div>
        );
    } else {
        return "";
    }
}

export default LoaderComponent;
