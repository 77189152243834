import React from "react";
import ScrollToTop from "../../components/ScrollToTop"

import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import Whitepaper_iFancyThat from "../../assets/pdf/ifancy-whitepaper.pdf";

const WhitePaper = () => {
  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <section className="comnPadd">
      <ScrollToTop />
      <div className="container">
        <ComnHeading beforeTxt="White Paper" heading="White Paper" />
        <div className="row">
          <div className="col-12 col-md-12 mx-auto">
            <button className="btn theme-btn">Open</button>
            <a className="btn theme-btn ml-2" href={Whitepaper_iFancyThat}>
              Download
            </a>

            <div className="mt-3">
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "1500px",
                }}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">

                  <Viewer fileUrl={Whitepaper_iFancyThat}
                    plugins={[defaultLayoutPluginInstance]} />
                </Worker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
