import axios from "axios";
import { storeInstance } from "../../App";
import { toast } from "../../components/common/Toast";
import { ADMIN_NAME, APIURL } from "../../constant";
import {
  admin_login,
  apiCallDelete,
  apiCallGet,
  apiCallGet_header,
  apiCallPost,
  apiCallPut,
  token_expiration,
  apiCallPost_header,
} from "../../services/apiService";
import { CommonService } from "../../services/commonService";
import { ADMINLOGIN, LOGIN, SIGNUP } from "../constant.actionTypes";
import { disconnectAddress } from "./user.action";
import { setLoader, stopLoader } from "./loader";

export const register_api = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallPost(APIURL.SIGNUP, data, {}, true, true)
        .then((result) => {
          dispatch({ type: SIGNUP, payload: result?.data?.data });
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch(async (ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};
export const landing_details = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallGet(APIURL.LANDINGPAGEDETAILS, {}, true, true)
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch(async (ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};

export const verifyEmail = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.VERIFYEMAIL, data, {}, true, true)
        .then((result) => {
          dispatch({ type: LOGIN, payload: result?.data?.data });
          return resolve(result);
        })
        .catch(async (ex) => {
          return reject(ex);
        });
    });
};
export const resendEmail = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.RESENDEMAIL, data, {}, true, true)
        .then((result) => {
          return resolve(result);
        })
        .catch(async (ex) => {
          return reject(ex);
        });
    });
};
export const contactdata = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.CONTACTUS, data, {}, true, true)
        .then((result) => {
          return resolve(result);
        })
        .catch(async (ex) => {
          return reject(ex);
        });
    });
};
export const subscribeData = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallPost(APIURL.SUBSCRIBE, data, {}, true, true)
        .then((result) => {
          return resolve(result);
        })
        .catch(async (ex) => {
          return reject(ex);
        });
    });
};

export const login_api = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallPost(APIURL.LOGIN, data, {}, true, true)
        .then((result) => {
          dispatch({ type: LOGIN, payload: result?.data?.data });
          dispatch(stopLoader(false));

          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};

export const userOrder = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallGet_header(
        `${APIURL.ADMIN_USER_ORDER}?page=${data.page}&limit=${data.limit}`,
        {},
        false,
        true
      )
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};
export const Admin_user_list_action = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallGet_header(
        `${APIURL.ADMIN_USER_ORDER_LIST}?wallet_address=${data}`,
        {},
        false,
        true
      )
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};
export const getUserProfile = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallGet_header(APIURL.PROFILE, {}, false, true)
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};
export const getTransactionDetails = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallGet_header(
        `${APIURL.TRANSACTION_DETAILS}?page=${data.page}&limit=${data.limit}`,
        {},
        false,
        true
      )
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};

export function isAdminLogin(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoader(true));
      const res = await admin_login(data);
      if (res?.error || res?.status === 400) {
        return toast.error(res?.message);
      }
      dispatch(stopLoader(false));
      dispatch({ type: ADMINLOGIN, payload: res?.data });
      return res;
    } catch (error) {
      // dispatch(stopLoading());
      dispatch(stopLoader(false));
      return toast.error(error.message);
    }
  };
}

export function expiration_token(data) {
  return async (dispatch, getState) => {
    try {
      const res = await token_expiration(data);
      return res;
    } catch (error) {
      // dispatch(stopLoading());
      return toast.error(error.message);
    }
  };
}

export const dashboardData = () => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      apiCallGet_header(APIURL.ADMIN_DASHBOARD_DATA, {}, false, true)
        .then((result) => {
          // let path = window.location.pathname;
          // if("/admindashboard") {
          //   window.location.replace("/adminlogin")
          // }
          return resolve(result);
        })
        .catch(async (ex) => {
          if (!ex || ex == undefined) {
            await dispatch(disconnectAddress());
            window.location.replace(`/${ADMIN_NAME}/adminlogin`);
          }
          reject(ex);
        });
    });
};

export const _adminUserList = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallGet_header(
        `${APIURL.ADMIN_USER_DATA}?page=${data.page}&limit=${data.limit}`,
        {},
        false,
        true
      )
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};

export const _adminUserListSeach = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      // dispatch(setLoader(true));
      // ?page=1&limit=10&search=shubham%40gmail.com
      apiCallGet_header(
        `${APIURL.ADMIN_USER_DATA}?search=${data.search ? data.search : " "}`,
        {},
        false,
        true
      )
        .then((result) => {
          // dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          // dispatch(stopLoader(false));
          reject(ex);
        });
    });
};

export const _adminUserOrderSeach = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      // ?page=1&limit=10&search=shubham%40gmail.com
      apiCallGet_header(
        `${APIURL.ADMIN_USER_ORDER}?search=${data.search ? data.search : ""}`,
        {},
        false,
        true
      )
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};

export const _updatePassword = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallPut(APIURL.UPDATE_PASS, data, {}, false, true)
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};

export const admin_log_out = (data) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(setLoader(true));
      apiCallPost(APIURL.ADMIN_LOGOUT, data, {}, false, true)
        .then((result) => {
          dispatch(stopLoader(false));
          return resolve(result);
        })
        .catch((ex) => {
          dispatch(stopLoader(false));
          reject(ex);
        });
    });
};
