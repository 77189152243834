import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader"
import { useParams } from "react-router-dom";
import { blogUrl } from "../../constant"

import blogBanner from "../../assets/images/blog-banner.png"

const DetailPage = (props) => {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [blogdata, setblogdata] = useState([])

    const loadblogdata = async () => {
        let catagiorydata;

        const res = await fetch(blogUrl + "/wp-json/wp/v2/posts?slug=" + params.name, {
            method: "GET",
        })
        const data = await res.json();
        if (data) {
            let upatedata = await Promise.all(data.map(async (key) => {
                catagiorydata = await getCategories(key.id)
                if (catagiorydata) {
                    key = { ...key, catagiory: catagiorydata }
                    return (key)
                }
            }))
            console.log(upatedata)

            if (upatedata) {
                setblogdata(upatedata)
                setLoading(false)

            }


        }
    }

    useEffect(() => {
        loadblogdata()
    }, []);

    const getCategories = async (id) => {
        let list = []
        const res = await fetch(`${blogUrl}/wp-json/wp/v2/categories?post=` + id, {
            method: "GET",
        })
        const data = await res.json();
        if (data) {
            data.map((key) => {
                list.push(key.name)
            })
            return (list.toString())
        }

    }

    const decodeHtmlCharCodes = (str) => {
        return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
        });
    }

    return (
        <>
            {loading ? <Loader /> :
                <section className="blog-details">
                    <div className="container">
                        <p className="bage-theme">#{blogdata[0].catagiory}</p>
                        <h1>{decodeHtmlCharCodes(blogdata[0].title.rendered)}</h1>
                        <h6>{blogdata[0].modified.split("T")[0]}</h6>
                        <img className="details-img" src={blogdata[0].jetpack_featured_media_url ? blogdata[0].jetpack_featured_media_url : blogBanner}></img>
                        <div className="row mt-4">
                            <div className="col-12 col-md-3 col-lg-2">
                                <div className="social-icons">
                                    <ul>
                                        <li><a className="twitter-share-button" href={`http://www.twitter.com/share?url=${blogdata[0].link}`} target="_blank" ><i class="fab fa-twitter"></i></a></li>
                                        <li><div class="fb-share-button" data-href={blogdata[0].link} data-layout="button" data-size="small"><a target="_blank"
                                            href={`https://www.facebook.com/sharer/sharer.php?u=${blogdata[0].link}&amp;src=sdkpreparse`} class="fb-xfbml-parse-ignore"> <i class="fab fa-facebook-f"></i></a></div></li>
                                        <li><a href={`https://xn--r1a.link/share/url?url=${blogdata[0].link}`} target="_blank"><i class="fab fa-telegram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-md-8 col-lg-7">
                                <div className="info-details ">
                                    <div dangerouslySetInnerHTML={{ __html: blogdata[0].content.rendered }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default DetailPage;
