export const SET_LOADING = "SET_LOADING";

export const setLoader = (data) => ({ type: SET_LOADING, payload: data });

export const stopLoader = (data) => ({ type: SET_LOADING, payload: data });


export const startMainLoader = () => ({ type: "START_LOADING" });

export const stopMainLoader = () => ({ type: "STOP_LOADING" });
