import { SET_LOADING } from "../_actions/loader";

const initialState = {
  isLoading: false,
  mainLoader: false,
};

export default function loader(state = initialState, action = {}) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case "START_LOADING":
      return { ...state, mainLoader: true };
    case "STOP_LOADING":
      return { ...state, mainLoader: false };
    default:
      return state;
  }
}
