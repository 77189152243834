import React from "react";
import ScrollToTop from "../../components/ScrollToTop";

const Cookies = (props) => {
  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };

  return (
    <section className="Cookies comnPadd text-white white-papaer-more">
      <ScrollToTop />
      <div className="container white-papaer-more">
        <ComnHeading beforeTxt="Cookies Policy" heading="Cookies Policy" />

        {/* <p></p>

        <h3>
            <strong></strong>
          </h3>
          <p></p>
        <ul>
          <li>
            <p></p>
          </li>
        </ul> */}
        {/* <h3>
          <strong>Cookie Policy</strong>
        </h3> */}
        <p>
          This policy applies to cookies in ifancythat websites and
          applications operated by ifancythat
        </p>
        <p>Cookies are used for the following purposes:</p>
        <ol>
          <li>operation of the site,</li>
          <li>analysis of the behavior of site visitors,</li>
          <li>for advertising.</li>
        </ol>

        <p>
          This policy is supplemented by the ifancythat Privacy Policy which
          you can find on the website.
        </p>
        <p>
          Proper protection of personal data is an important goal for ifancythat and we are as clear and transparent as possible about ifancythat approach in this area. For any questions, please contact us via
          email support@ifancythat.com
        </p>
        <h3>
          <strong>What are cookies?</strong>
        </h3>
        <p>
          A cookie is a small file, consisting of letters and numbers, that will
          be stored on a computer, mobile terminal, or other equipment of a user
          accessing the Internet. The cookie is installed by the request made by
          the user's terminal to ifancythat server or to a third party server.
        </p>

        <h3>
          <strong>What are cookies used for?</strong>
        </h3>
        <p>
          These files mainly make it possible to recognize the user's terminal
          and present the content in a relevant way, adapted to the user's
          preferences.
        </p>
        <p>
          Cookies provide users with a pleasant browsing experience and support
          ifancythat efforts to provide more user-friendly services, eg: -
          online privacy preferences or relevant advertising. They are also used
          in the preparation of aggregate anonymous statistics that help us
          understand how a user benefits from our web pages, allowing us to
          improve their structure and content, without allowing the user to be
          personally identified.
        </p>

        <h3>
          <strong>What cookies do we use?</strong>
        </h3>
        <p>
          We use two types of cookies: per session and fixed. Per session
          cookies are temporary files that remain in the user's terminal until
          the end of the session or the application is closed. Fixed cookies
          remain on the user's terminal for a period determined by the cookie
          parameters or until they are manually deleted by the user.
        </p>

        <h3>
          <strong>How are cookies used by this site?</strong>
        </h3>
        <p>A visit to this site may place the following types of cookies:</p>
        <ol>
          <li>cookies strictly necessary for the operation of the site</li>
          <li>analysis cookies</li>
          <li>advertising cookies</li>
        </ol>
        <p>
          Cookies and / or similar technologies strictly necessary are essential
          for the proper functioning of the site, being set on your device when
          accessing the site or following the actions performed on the site, as
          appropriate. You can set your browser to block cookies, but in this
          case certain sections of the site will not work properly.
        </p>

        <h3>
          <strong>Do cookies contain personal data?</strong>
        </h3>
        <p>
          Cookies do not in themselves require personal information in order to
          be used and, in many cases, do not personally identify internet users.
          However, there are situations when personal data may be collected
          through the use of cookies to facilitate certain functionalities for
          the user or to offer the user an experience more adapted to his
          preferences. Such data is encrypted in a way that makes it impossible
          for unauthorized persons to access it.
        </p>

        <h3>
          <strong>Block cookies</strong>
        </h3>
        <p>
          If you wish to block cookies, some features of the site will be
          disabled, and this may lead to certain malfunctions or errors in the
          use of our site.
        </p>
        <p>
          If you agree to these limitations and wish to block cookies, please
          follow the instructions below: Most browsers are set to accept cookies
          by default, but you can change the settings to block some or all
          cookies.
        </p>

        <h3>
          <strong>Administering cookie placement preferences</strong>
        </h3>
        <p>
          In general, an application used to access web pages allows you to save
          cookies and / or similar technologies on the terminal by default.
          These settings can be changed so that the automatic administration of
          cookies is blocked by the web browser or the user is informed every
          time cookies are sent to his terminal.
        </p>
        <p>
          Detailed information about the possibilities and ways of managing
          cookies can be found in the settings area of the application (web
          browser). Limiting the use of cookies may affect certain
          functionalities of the website.
        </p>

        <h3>
          <strong>Security and privacy issues</strong>
        </h3>
        <p>
          In general, browsers have integrated privacy settings that provide
          different levels of acceptance of cookies, the period of validity and
          automatic deletion after the user has visited a particular site.
        </p>
        <p>Other security issues related to cookies:</p>

        <ol>
          <li>
            <p>
              Customize your browser settings for cookies to reflect a
              comfortable level of cookie security for you.
            </p>
          </li>
          <li>
            If you are the only person using your computer, you can set long
            expiration dates for storing your browsing history and personal
            access data if you wish.
          </li>

          <li>
            If you share access to your computer, you can consider setting your
            browser to clear individual browsing data each time you close your
            browser. This is a way to access the sites that place cookies and to
            delete any visit information at the end of the browsing session.
          </li>
        </ol>
        <p>
          ifancythat uses services provided by third parties for the purpose
          of providing functionality, analysis and advertising. These third
          parties may set and use cookies and / or similar technologies on
          websites and applications operated by ifancythat, as well as on
          websites and applications other than those operated by ifancythat.
        </p>
      </div>
    </section>
  );
};

export default Cookies;
