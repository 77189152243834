import React, { useEffect, useRef } from "react";
import './MultiProductsPage.scss'
import { Col, Container, Row } from "react-bootstrap";
import Fancy1 from '../../assets/images/wallet/fancy1.png'
import Fancy2 from '../../assets/images/wallet/fancy2.png'
import Fancy3 from '../../assets/images/wallet/fancy3.png'
import Fancy4 from '../../assets/images/wallet/fancy4.png'
import Fancy5 from '../../assets/images/wallet/fancy5.png'
import Fancy6 from '../../assets/images/wallet/fancy6.png'
import AOS from "aos";
import "aos/dist/aos.css";

const MultiProductsPage = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid className="mainCont p-0 product-handle " style={{ overflow: "hidden" }}>
        <Container className="container-inner product-handle-container">
          <Row className="align-items-center" id="aa" data-aos="fade-left" data-aos-duration="1000">
            <Col md={6}>
              <div className="left">
                <h2 className="walletheading__title common-heading">
                  <span className="walletheading__subText d-inline-block">
                    ifancya
                  </span>
                  flight
                </h2>
                <p>Traverse to the world of secure and transparent crypto transactions with ifancyaflight. </p>
                <p>We enable you to find the biggest brands and book flights to different destinations across the globe. Just
                  like we streamline flight booking, we simplify payments significantly. </p>
                <p>Pay using fancythatcard or FNCY tokens and leverage:</p>
                <ul className="wallet__dotlist">
                  <li className="wallet__dotitem">Simple, transparent, and quick transactions</li>
                  <li className="wallet__dotitem">Institutional-grade security</li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div className="right">
                <img src={Fancy1} alt="" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center" id="bb" data-aos="fade-right" data-aos-duration="1000">
            <Col md={6}>
              <div className="left">
                <h2 className="walletheading__title common-heading">
                  <span className="walletheading__subText d-inline-block">
                    ifancya
                  </span>
                  watch
                </h2>
                <p>As the watches are turning smart, so should your payment methods. It’s time to bid adieu to cash payments.
                </p>
                <p>The ifancyawatch platform provides a collection of watches from the biggest brands. Just like you can choose
                  from multiple options in watches, you can choose between the following two payment options:</p>
                <p>Pay using fancythatcard or FNCY tokens and leverage:</p>
                <ul className="wallet__dotlist">
                  <li className="wallet__dotitem">Payment through FNCY tokens</li>
                  <li className="wallet__dotitem">Payment through fancythatcard</li>
                </ul>
                <p>Leverage seamless, quick, and secure transactions. </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="right">
                <img src={Fancy2} alt="" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center" id="cc" data-aos="fade-left" data-aos-duration="1000">
            <Col md={6}>
              <div className="left">
                <h2 className="walletheading__title common-heading">
                  <span className="walletheading__subText d-inline-block">
                    ifancya
                  </span>
                  hirecar
                </h2>
                <p>Navigate towards secure, quick, and transparent transactions with ifancyahirecar. </p>
                <p>Access multiple cars from different makes and models and hire the car that you desire. We make car hiring
                  easy and accessible to everyone. Not only this, we make payment equally easy and streamlined. </p>
                <p>Pay for your car using FNCY tokens or fancythatcard. Enjoy quick and secure cryptocurrency payments. </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="right">
                <img src={Fancy3} alt="" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center" id="dd" data-aos="fade-right" data-aos-duration="1000">
            <Col md={6}>
              <div className="left">
                <h2 className="walletheading__title common-heading">
                  <span className="walletheading__subText d-inline-block">
                    ifancya
                  </span>
                  phone
                </h2>
                <p>Crypto transactions are calling! Respond with ifancyaphone. </p>
                <p>The ifancyaphone platform enables you to access thousands of phones from popular brands. Buy your desired
                  phone at the best price. However, if you want to sell a phone, compare the best prices available for your
                  phone. </p>
                <p>Leverage FNCY tokens – a global currency – to pay for your purchase. Besides, you can make and accept crypto
                  payments using fancythatcard. </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="right">
                <img src={Fancy4} alt="" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center" id="ee" data-aos="fade-left" data-aos-duration="1000">
            <Col md={6}>
              <div className="left">
                <h2 className="walletheading__title common-heading">
                  <span className="walletheading__subText d-inline-block">
                    ifancya
                  </span>
                  hotel
                </h2>
                <p>Change the way you pay for your hotel booking with ifancyahotel. </p>
                <p>Access multiple luxurious hotels at different locations globally and get the best deals. Book your desired
                  hotel seamlessly and pay in crypto using:</p>
                <ul className="wallet__dotlist">
                  <li className="wallet__dotitem">
                    FNCY tokens
                  </li>
                  <li className="wallet__dotitem">fancythatcard</li>
                </ul>
                <p>Capitalize on accelerated, secure, and transparent payments anywhere across the globe.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="right">
                <img src={Fancy5} alt="" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center" id="ff" data-aos="fade-right" data-aos-duration="1000">
            <Col md={6}>
              <div className="left">
                <h2 className="walletheading__title common-heading">
                  <span className="walletheading__subText d-inline-block">
                    ifancya
                  </span>
                  date
                </h2>
                <p>A date should be all about spending special moments with your loved one, not worrying about paying the bill,
                  especially when you do not have fiat currency. </p>
                <p>On ifancyadate, you can find the best spots for your date. The platform provides you with one of the easiest
                  payment options so that you create memorable moments instead of remaining concerned about the bill payment.
                </p>
                <p>With ifancyadate, you can:</p>
                <ul className="wallet__dotlist">
                  <li className="wallet__dotitem">
                    Pay using FNCY tokens
                  </li>
                  <li className="wallet__dotitem">Pay using fancythatcard</li>
                </ul>
                <p>No matter which payment option you choose out of the two, you only enjoy secure, seamless, and accelerated
                  transactions. </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="right">
                <img src={Fancy6} alt="" />
              </div>
            </Col>
          </Row>

        </Container>
      </Container>
    </>
  );
};
export default MultiProductsPage;