import cogoToast from "cogo-toast";
import "./Toast.scss";
class Toaster {
  success = (message) => {
    let options = {
      position: "top-right",
      heading: "Success",
      hideAfter: 6,
      // bar: { size: "2px", style: "solid/dashed/dotted", color: "#hex" },
    };
    cogoToast.success(message, options);
  };

  error = (message) => {
    let options = {
      position: "top-right",
      heading: "Error",
      hideAfter: 6,
      // bar: { size: "2px", style: "solid/dashed/dotted", color: "#hex" },
    };
    cogoToast.error(message, options);
  };

  info = (message) => {
    let options = {
      position: "top-right",
      heading: "Info",
      hideAfter: 6,
      // bar: { size: "2px", style: "solid/dashed/dotted", color: "#hex" },
    };
    cogoToast.info(message, options);
  };
}

export const toast = new Toaster();
