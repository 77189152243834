import React from "react";
import { Link } from "react-router-dom";

const AdminSideBar = () => {
  return (
    <div className="sidebarAdmin">
      <ul>
        <li>
          <Link className="dropdown-item" to="/admin/AdminDashboard">
            Dashboard
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/admin/UserInfo">
            User List{" "}
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/admin/Order">
            Orders
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/admin/Changepassword">
            Setting
          </Link>
        </li>
        {/* <li>
        <Link className='dropdown-item' to="/">Logout</Link>
      </li> */}
      </ul>
    </div>
  );
};

export default AdminSideBar;
