import React, { useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import "./index.css";


import faq_img from "../../../assets/images/faq_img.svg";

const FAQItem = (props) => {
  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };
  const items = [
    {
      id: 1,
      q: "What is fancythattoken?",
      a: <p><b>fancythattoken</b> is the cryptocurrency for the retail, travel and leisure industries. It is universally adoptable through multi-brand platforms and is built on the Binance blockchain. Our main goal is to become the currency for daily-use in the real world.</p>,
    },
    {
      id: 2,
      q: "How do I buy fancythattoken?",
      a: <p>You will be able to invest and purchase <b>fancythattoken</b> during our seed stage which is scheduled to start from the 1st of June 2022 the price will be set at $0.0005.</p>,
    },
    {
      id: 3,
      q: "How many phases of fund raising will there be?",
      a: "There will be six phases of token distribution throughout the year, starting from 1st June 2022 until 30th April 2023, ready to go live onto the exchange on 1st June 2023. This process is outlined in more detail in our whitepaper.",
    },
    {
      id: 4,
      q: "What is the total supply of tokens?",
      a: <p>It is proposed that 100,000,000,000 (100BN) <b>fancythattokens</b> will be created as a fixed supply.</p>,
    },
    {
      id: 5,
      q: "How many tokens can I buy?",
      a: "There will be a minimum purchase value of $50 and a maximum value of $25,000. In order to purchase the tokens users will be required to register through the fancythattoken.io website and convert the value of USD into BNB.",
    },
    {
      id: 6,
      q: "When will I receive the tokens?",
      a: "Within 24 hours, 20% of the purchased tokens will be delivered into your wallet, these will be locked until 1st June 2023. The remaining tokens will need to be claimed via the website. From 1st July 2023, log In and connect your wallet and claim into the wallet at 20% each month until 1st October 2023. This will control the growth and give the token maximum potential to grow in value.",
    },
    {
      id: 7,
      q: "When will fancythattoken be available on exchanges?",
      a: "1st June 2023. This is when it will be possible to carry out free trade.",
    },
    {
      id: 8,
      q: "What can fancythattoken be used for?",
      a: <p>Our goal with <b>fancythattoken</b> is to incorporate it as a payment method on our other verticals and to implement it into the retail, travel and leisure industries particularly so that our users can use it in the real world to pay for their hotels, flights, car hire, mobile phones plus much more. Our extensive use case is outlined in our whitepaper. </p>,
    },
    {
      id: 9,
      q: "Which wallets are best for holding fancythattoken?",
      a: "We recommend using Metamask or Trust Wallet . All wallets are downloadable from Play Store and the App Store. We are in the process of creating our own wallet and will communicate details of this shortly.",
    },

  ];

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <section id="faq" className="faqSec comnPadd">
      <Container>
        <ComnHeading
          beforeTxt="FAQ"
          heading="Frequently Asked Questions"
        />

        <Row className="align-items-center">
          <Col xl={5} lg={6}>
            <div className="faqSec_left">
              <img src={faq_img} alt="img" />
            </div>
          </Col>
          <Col xl={7} lg={6}>
            <div className="faqSec_right">
              <Accordion defaultActiveKey="0">
                {items.map((item, index) => {
                  return (
                    <div key={index}>
                      <Accordion.Item eventKey={item.id}>
                        <Accordion.Header
                          activekey={item.id}
                          onClick={handleToggle}
                        // className={`toggleIcon ${isActive ? "iconPlus" : "iconMinus"
                        //   }`}
                        >
                          {item.q}
                        </Accordion.Header>
                        <Accordion.Body>{item.a}</Accordion.Body>
                      </Accordion.Item>
                    </div>
                  );
                })}
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FAQItem;
