import React from "react";
import { Fragment } from "react/cjs/react.production.min";
import Header from "./Header";
import SideBar from "../Sidebar";
import NewFooter from "../NewFooter";
// import NewHeaderLogedIn from "../Header/newHeaderLogedIn";
import "./index.css"

const SideBarPageLayout = ({ Component, ...props }) => {
  return (
    <Fragment>
      <div className="blue-gradiant">
        <Header />
      </div>
      <div className="">
        <section className="section-top dasboard ">
          <div className="container-fluid px-0">
            <div className="row no-gutters mx-0">
              <div className="col-0 col-md-0 col-lg-2">
                <SideBar />
              </div>
              <div className="col-12 col-md-12 col-lg-10">
                <Component {...props} />
              </div>
            </div>
          </div>
        </section>
      </div>
      <NewFooter />
    </Fragment>
  );
};

export default SideBarPageLayout;
