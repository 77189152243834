import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "./process.scss";

function ProcessModal({ show, onHide, onClick, handleClose, setShow }) {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        keyboard={false}
        className="connect_modal process_cl"
      >
        <Modal.Body className="modal_body12">
          <button
            className="closeBtn"
            type="button"
            onClick={() => handleClose()}
          >
            X
          </button>
          <Carousel interval={null}>
            <Carousel.Item className="itms_crousel">
              <h4>Welcome to fancythattoken </h4>
              <p>
                Before proceeding to the fancythattoken platform, please ensure
                that you have read the guidlines to operate the platform. In
                the next slides you will learn
              </p>
              <ul>
                <li>1. How to Register</li>
                <li> 2. How to Login </li>
                <li>3. How to Buy Tokens</li>
                {/* <li>4. How to purchase the fancythattokens</li> */}
              </ul>
            </Carousel.Item>
            <Carousel.Item className="itms_crousel">
              <h4>How to Register </h4>
              <ul className="list-unstyled ">
                <li>a) Enter your Full Name and Email</li>
                <li>b) Click the next button</li>
                <li>
                  c) Now, Connect your Metamask or other wallet via browser
                  extension by clicking on the connect button
                </li>
                <li>
                  d) Once your wallet is connected, an email will be sent for
                  the sign up confirmation
                </li>
                <li>e) Click the link provided in the email</li>
                <li>f) All set, now you can login</li>
              </ul>
            </Carousel.Item>

            <Carousel.Item className="itms_crousel">
              <h4>How to Login </h4>
              <ul>
                <li>a) Enter your email</li>
                <li>b) click on "Next" button </li>
                <li>
                  c) Now, click on connect button to connect your Metamask or
                  other wallet{" "}
                </li>
                <li>d) Your wallet is now connected</li>
                <li>e) Press the login button</li>
                <li>f) From the dashboard, you can now purchase the "fancythattokens"</li>
              </ul>
            </Carousel.Item>

            <Carousel.Item className="itms_crousel">
              <h4>How to Buy Tokens</h4>
              <ul>
                <li>
                  a) Select the currency (BNB or BUSD) you want to use to buy the fancythattokens
                </li>
                <li>b) Enter the amount of BNB or BUSD to purchase  fancythattokens</li>
                <li>c) Click the buy button</li>
                <li>d) Confirm the transaction from your MetaMask or TrustWallet</li>
                <li>
                  e) Bought tokens will be listed and shown in your dashboard
                </li>
                <li>
                  f) Please press "Import Token" button to recieve your 20% tokens as per the vesting schedule
                </li>
              
              </ul>
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProcessModal;
