import React from 'react'
// import { Image } from "react-bootstrap";

import Group_589 from "../../assets/images/Michael.png";
import Group_587 from "../../assets/images/Group 587.svg";
import Group_578 from "../../assets/images/Group 578.svg";
import Group_585 from "../../assets/images/Group 585.svg";
import Group_583 from "../../assets/images/Group 583.svg";
import Group_576 from "../../assets/images/Group 576.svg";
import umer from "../../assets/images/umer.jpg"
import Group_580 from "../../assets/images/Group 580.svg";
import Group_574 from "../../assets/images/Group 574.svg";

//Adding NFTs

import Developer1 from "../../assets/NFT/zaman.png";
import Developer2 from "../../assets/NFT/winn.png";
import Developer3 from "../../assets/NFT/umer.png";
import Developer4 from "../../assets/NFT/ibrar.png";
import MichaelNFT from "../../assets/NFT/michael.jpeg";
// import AllenNFT from "../../assets/NFT/AllenNFT.jpg";
import SohaibNFT from "../../assets/NFT/SohaibNFT.png";
import AlexNFT from "../../assets/NFT/joe.png";
import SamNFT from "../../assets/NFT/sam.jpeg";
import ShannonNFT from "../../assets/NFT/shannon.png";

const FancyThatTeam = (props) => {

  const team = [
    {
      name: "Michael",
      role: "CEO & FOUNDER",
      info: "Michael is the co founder of I Fancy That. The dream started 7 years ago and has evolved year on year. He has designed dozens of future build sites with 6 in current build. His passion for Crypto Currency brought forward the idea of the Fancy That Token and Wallet. His vision will enable people to purchase what they fancy with the Fancy That Token.",
      image: MichaelNFT,
      linedin: "https://www.linkedin.com/in/michael-caine-925ab8212/"
    },
    {
      name: "Joe",
      role: "PARTNER",
      info: "",
      image: AlexNFT,
      linedin: ""
    },
    {
      name: "Sohaib",
      role: "TECH LEAD",
      info: "",
      image: SohaibNFT,
      linedin: "https://www.linkedin.com/in/sohaib-ibne-ali/"
    },
    // {
    //   name: "Allen",
    //   role: "INVESTOR",
    //   info: "",
    //   image: AllenNFT,
    //   linedin: ""
    // },
    {
      name: "Zaman",
      role: "TECH",
      info: "",
      image: Developer1,
      linedin: "https://www.linkedin.com/in/muhammad-zaman-25857910a"
    },
    {
      name: "Ibrar",
      role: "TECH",
      info: "",
      image: Developer4,
      linedin: "https://www.linkedin.com/in/ibrar-ahmad-745830114"
    },
    {
      name: "Umer",
      role: "TECH",
      info: "",
      image: Developer3,
      linedin: "https://www.linkedin.com/in/umar-zia-231b3911b"
    },
    {
      name: "Winn",
      role: "BLOCK CHAIN",
      info: "",
      image: Developer2,
      linedin: "https://www.linkedin.com/in/winn-faria-1391141/"
    },
    {
      name: "Sam",
      role: "LEAD CREATIVE",
      info: "",
      image: SamNFT,
      linedin: "https://www.linkedin.com/in/sam-matthews-530827b6/"
    },
    {
      name: "Shannon",
      role: "DESIGNER",
      info: "",
      image: ShannonNFT,
      linedin: "https://www.linkedin.com/in/shannon-tomlinson/"
    },

  ]


  return (
    <section className="pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12  mx-auto">
            <div className="card text-center">
              <div className="comnHeading mt-3 mb-0">
                <span className="beforeTxt_Style">Team</span>
                <h2>Our Fancy That Team</h2>
              </div>
              <div className="row team-box justify-content-center">

                {team.map((data, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-3 mt-4">
                    <div className="hover-area" style={{ cursor: "pointer" }} onClick={() => window.open(data.linedin, "_blank")} >
                      {/* <div className="about-info">
                        <p>{data.info}</p>
                      </div> */}
                      <img src={data.image} />
                      <h4 className="text-white mt-3 mb-0">{data.name}</h4>
                      <p className="text-white text-center">{data.role}</p>
                      <button className='btn theme-btn py-1' onClick={() => window.open(data.linedin, "_blank")} >
                        Linkedin <i className="fab fa-linkedin"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default FancyThatTeam