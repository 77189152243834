import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import fees from "../../../assets/images/wallet/fees.png";

const Fees = () => {
  return (
    <>
      <section className="feesSec">
        <Container className="commnCont">
          <Row className="cm_row">
            <Col md={6} left>
              <h2 className="walletheading__title common-heading d-block">
                Lowest Fees
                <span className="walletheading__subText d-block">
                  in the Market
                </span>
                {/* <span className="walletheading__subText d-block">
                  everytime you spend
                </span> */}
              </h2>
              {/* <WalletHeading
                // small="Get Free Tokens - No Risk - Increase Value"
                // sub="Lowest Fees"
                title="Lowest Fees"
                // sub="in the Market"
                className="walletheading__title--small"
              />
              <WalletHeading
                // small="Get Free Tokens - No Risk - Increase Value"
                sub="in the Market"
                // title="in the Market"
                className="walletheading__title--small"
              /> */}
              <div
                className="wallet__content"
                // data-aos="fade-up"
              >
                <p>
                  fancythattoken card works just like any bank-issued card. The
                  turning point is that you have to pay negligible fees for
                  using this crypto card.
                </p>

                {/* <StoreBtns /> */}
              </div>
            </Col>
            <Col md={6} right>
              {/* <ImageWrap main={fees} className="feesImg" /> */}
              <img src={fees} alt="" className="img-fluid"/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Fees;
