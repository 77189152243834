import cogoToast from "cogo-toast";

class Toaster {
  success = (message) => {
    let options = {
      position: "top-right",
      heading: "Success",
      hideAfter: 6,
      position: "bottom-center",
      bar: { size: "2px", style: "solid/dashed/dotted", color: "#hex" },
    };
    cogoToast.success(message, options);
  };

  error = (message) => {
    let options = {
      position: "top-right",
      heading: "Error",
      hideAfter: 6,
      position: "bottom-center",
      bar: { size: "2px", style: "solid/dashed/dotted", color: "#hex" },
    };
    cogoToast.error(message, options);
  };

  info = (message) => {
    let options = {
      position: "top-right",
      heading: "Info",
      hideAfter: 6,
      position: "bottom-center",
      bar: { size: "2px", style: "solid/dashed/dotted", color: "#hex" },
    };
    cogoToast.info(message, options);
  };
}

export const toast = new Toaster();

// import { ToastContainer, toast  as ToastAlert} from 'react-toastify';

//   export const success = message => {
//      let options = { position: "top-right", heading: "Error" };

//     ToastAlert.success(message, options);
//         <ToastContainer />

//   };

//   export const error = message => {
//     let options = { position: "top-right", heading: "Error" };

//     ToastAlert.error(message, options);
//         <ToastContainer />

//   };

//   export const info = message => {
//     let options = { position: "top-right", heading: "Info" };

//     ToastAlert.info(message, options);
//         <ToastContainer />

//   };
