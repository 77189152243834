import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Cash from "../../../assets/images/wallet/cashback.png";

const Cashback = () => {
  return (
    <>
      <section className="cashbackSec">
        <Container className="commnCont">
          <Row>
            <Col md={6} left>
              {/* <ImageWrap main={Cash} /> */}
              <img src={Cash} alt="" className="img-fluid" />
              {/* <ImageWrap main={Cash} className="cashbackImg" /> */}
            </Col>
            <Col md={6} right>
              <h2 className="walletheading__title common-heading">
                <span className="walletheading__subText d-block">Earn</span>
                FANCY Cashback
                <span className="walletheading__subText d-block">
                  everytime you spend
                </span>
              </h2>

              <div className="wallet__content">
                <p>
                  A crypto card that can revolutionize the ways of spending
                  cryptocurrencies.
                </p>
                <p>Earn FNCY Cashback every time you spend</p>
                <ul className="wallet__dotlist">
                  <li className="wallet__dotitem">
                    {" "}
                    Cardholders can make frictionless payments
                  </li>
                  <li className="wallet__dotitem">
                    {" "}
                    Get amazing cashback offers for making purchases through
                    this next-gen crypto card.
                  </li>
                  <li className="wallet__dotitem">
                    Cashbacks and rewards are not taxable.
                  </li>
                </ul>
                {/* <StoreBtns /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Cashback;
