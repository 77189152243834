import React, { useEffect } from "react";
import "./index.css";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import ScrollToTop from "../../components/ScrollToTop"
import AOS from "aos";
import "aos/dist/aos.css";
import sheduleImg from "../../assets/images/Group 38097.png"
import sheduleline from "../../assets/images/bounty.svg"
import public_scale from "../../assets/images/PublicSale.svg"
import right_scale from "../../assets/images/team_line.svg"


const VestingSchedule = (props) => {
  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p><strong>{props.subTxt}</strong></p>
      </div>
    );
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="vestingschedule comnPadd white-papaer-more">
      <ScrollToTop />
      <div className="container">
        <section>
          <ComnHeading
            beforeTxt="Vesting Schedule"
            heading="Vesting Schedule"
            subTxt={
              <>
                20% of tokens unlocked immediately{" "}
                <br />
                {" "}20% unlocked every month after untill October 2023
              </>
            }
          />
          <Container>
            <Row className="top_shedule_row mbtb schedule-arange">
              <Col id="myRedDIV1">
                <div className="position-relative w-50 mbtb befor_dot fir-dot dektop-view">
                  <p className="mb-0 text-white w-100 text-right mt-5 font-weight-bold my_id_1">
                    20% <br></br>
                    <span className="f-14">On 1st August 2023</span>
                  </p>
                  <img
                    src={sheduleline}
                    alt=""
                    className="position-absolute img_shedue_line1 same_none"
                  />
                </div>
                <div className="position-relative w-50 mbtb befor_dot fift-dot mobile-view">
                  <p className="mb-0 text-white w-100 text-right mt-5 font-weight-bold my_id_1">20% <br></br>
                    <span className="f-14">On 1st  September  2023</span></p>
                  <img src={sheduleline} alt="" className="position-absolute img_shedue_line1 same_none" />
                </div>
                <div className="position-relative w-50 mbtb befor_dot seco-dot">
                  <p className="mb-0 text-white w-100 text-right mt-5 font-weight-bold my_id_2">
                    20% <br></br>
                    <span className="f-14">On 1st October 2023</span>
                  </p>
                  <img
                    src={sheduleline}
                    alt=""
                    className="position-absolute img_shedue_linefa same_none"
                  />
                </div>
              </Col>
              <Col id="myRedDIV2">
                <div className="text-center img-shedule mt-5">
                  <img src={sheduleImg} alt="" />
                </div>
              </Col>
              <Col id="myRedDIV3">
                <div>
                  <div className="position-relative mbtb txt-right befor_dot thrd-dot">
                    <p className="mb-0 text-white text-left font-weight-bold main_right-Svg">
                      20% <br></br>
                      <span className="f-14">Within 24 hours of purchase</span>
                    </p>
                    <img
                      src={public_scale}
                      alt=""
                      className="position-absolute img_shedue_line2 same_none"
                    />
                  </div>

                  <div className="position-relative mbtb txt-right befor_dot fort-dot">
                    <p className="text-white mt-5 text-left font-weight-bold main_right-Svg2">
                      20% <br></br>
                      <span className="f-14">On 1st July 2023</span>
                    </p>
                    <img
                      src={right_scale}
                      alt=""
                      className="position-absolute img_shedue_line3 same_none"
                    />
                  </div>

                  <div className="position-relative mbtb txt-right befor_dot fift-dot dektop-view">
                    <p className="text-white mt-5 text-left font-weight-bold main_right-Svg3">
                      20% <br></br>
                      <span className="f-14">On 1st September 2023</span>
                    </p>
                    <img
                      src={right_scale}
                      alt=""
                      className="position-absolute img_shedue_line4 same_none"
                    />
                  </div>
                  <div className="position-relative mbtb txt-right befor_dot fir-dot mobile-view">
                    <p className="text-white mt-5 text-left font-weight-bold main_right-Svg3" >20% <br></br>
                      <span className="f-14">On 1st  August 2023</span></p>
                    <img src={right_scale} alt="" className="position-absolute img_shedue_line4 same_none" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="row">
          <div className="col-12">
            <div className="col-12 mt-5">
              <div className="row progress-container">
                <div className="col-md-4">
                  <h3>Seed Stage - Price $0.0005</h3>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="col-md-8">
                  <p className="text-left">1st June 2022 – 30th June 2022 (11 Month Lock-in Period)</p>
                  <div className="progresbar">
                    <div className="progress-parent">
                      <div className="progress1" style={{ width: "30%" }}></div>
                    </div>
                  </div>
                  <p className="text-right">
                    There will be a maximum of 6 billion tokens available at a
                    price of $0.0005
                  </p>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-duration="1000" className="row progress-container">
                <div className="col-md-4">
                  <h3>Phase 1 - Price $0.0008</h3>
                </div>
                <div className="col-md-8">
                  <p className="text-left">
                    1st August 2022 – 31st August 2022 (9 Month Lock-in Period)
                  </p>
                  <div className="progresbar">
                    <div className="progress-parent">
                      <div className="progress1" style={{ width: "40%" }}></div>
                    </div>
                  </div>
                  <p className="text-right">
                    There will be a maximum of 6 billion tokens available at a
                    price of $0.0008
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000" className="row progress-container">
                <div className="col-md-4">
                  <h3>Phase 2 - Price $0.0012</h3>
                </div>
                <div className="col-md-8">
                  <p className="text-left">
                    1st October 2022 – 31st October 2022 (7 Month Lock-in
                    Period)
                  </p>
                  <div className="progresbar">
                    <div className="progress-parent">
                      <div className="progress1" style={{ width: "50%" }}></div>
                    </div>
                  </div>
                  <p className="text-right">
                    There will be a maximum of 6 billion tokens available at a
                    price of $0.0012
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000" className="row progress-container">
                <div className="col-md-4">
                  <h3>Phase 3 - Price $0.0016</h3>
                </div>
                <div className="col-md-8">
                  <p className="text-left">
                    1st December 2022 – 31st December 2022 (5 Month Lock-in
                    Period)
                  </p>
                  <div className="progresbar">
                    <div className="progress-parent">
                      <div className="progress1" style={{ width: "60%" }}></div>
                    </div>
                  </div>
                  <p className="text-right">
                    There will be a maximum of 6 billion tokens available at a
                    price of $0.0016
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000" className="row progress-container">
                <div className="col-md-4">
                  <h3>Phase 4 - Price $0.0020</h3>
                </div>
                <div className="col-md-8">
                  <p className="text-left">
                    1st February 2023 – 28th February 2023 (3 Months Lock-in
                    Period)
                  </p>
                  <div className="progresbar">
                    <div className="progress-parent">
                      <div className="progress1" style={{ width: "70%" }}></div>
                    </div>
                  </div>
                  <p className="text-right">
                    There will be a maximum of 6 billion tokens available at a
                    price of $0.0020
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000" className="row progress-container">
                <div className="col-md-4">
                  <h3>Phase 5 - Price $0.0025</h3>
                </div>
                <div className="col-md-8">
                  <p className="text-left">
                    1st April 2023 – 30th April 2023 (1 Month Lock-in Period){" "}
                  </p>
                  <div className="progresbar">
                    <div className="progress-parent">
                      <div className="progress1" style={{ width: "80%" }}></div>
                    </div>
                  </div>
                  <p className="text-right">
                    There will be a maximum of 6 billion tokens available at a
                    price of $0.0025
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000" className="row progress-container">
                <div className="col-md-12">
                  <h3>Phase 6 - Live on the Exchange Summer of 2024</h3>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000" className="progress-container mt-5">
                {/* <p className="">
                  20% of the tokens will be delivered in 24 hours after purchase
                  - the remaining 80% complies to the lockup charting above
                </p> */}
                <p className="">
                  Tokens left unsold at the end of every phase will rollover into the next phase. At the end of the final sale phase any unsold tokens will go into the Burn Wallet.
                </p>
                <p className="">
                  20% of the total tokens purchased will be deposited into the users wallet once the user has imported them by pressing the import button (in desktop) within their dashboard. Mobile users please import FNCY tokens from their Metamask import button.
                </p>

                <p>This will leave 80% of your tokens locked in as per the vesting schedule below and above. The tokens will start to be unlocked 1 month after FNCY is listed on the exchange.</p>
                <p>The tokens will be claimable via your dashboard from 1st July 2023.</p>
                <p className="">
                  Claimable dates:
                </p>

                <ol type="1">
                  <li>
                    <p>20% on 1st July 2023</p>
                  </li>
                  <li>
                    <p>20% on 1st August 2023</p>
                  </li>
                  <li>
                    <p>20% on 1st September 2023</p>
                  </li>
                  <li>
                    <p>20% on 1st October 2023</p>
                  </li>
                </ol>

                <p className="">
                  The tokens will be claimable from the dashboard as per dates
                  above
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VestingSchedule;
