import React, { useState, useEffect } from "react";
import { Table, Container } from "react-bootstrap";
import AdminSearch from "./AdminSearch";
import { userOrder } from "../../redux/_actions/apis.action";
import { _adminUserOrderSeach } from "../../redux/_actions/apis.action";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { CSVLink } from "react-csv";
import moment from "moment";
import { BUSD_ADDRESS, IFANCY_TOKEN_ADDRESS } from "../../constant";
import { getUSDTDecimal } from "../../redux/_actions/user.action";
// import { getUSDTDecimal } from "../../redux/_actions/user.action";

const Order = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [records, setRecords] = useState([]);
  const [csvRecord, setCsvRecord] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [ifanctTokenDecimal, setTokenDecimal] = useState(0);
  const [busdTokenDecimal, setBusdTokenDecimal] = useState(0);

  const wallet = useSelector((state) => state?.user.connectedWallet);

  useEffect(() => {
    userOrderDetails();
  }, [page]);

  const userOrderDetails = async () => {
    const datas = {
      page: page,
      limit: limit,
    };
    const result = await dispatch(userOrder(datas));
    setRecords(result?.data);
    setUsersCount(result?.data?.count);
    const recodData = result?.data?.TokensBoughtListCsv?.map((data, i) => {
      data.noOfTokens = (data.noOfTokens / 10 ** 6).toFixed(3);
      data.userTransferTokens = (data.userTransferTokens / 10 ** 6).toFixed(3);
      data.buyAmount = Number(
        data.tokenType == 1
          ? data.buyAmount / 10 ** 18
          : data.buyAmount / 10 ** 6
      );
      data.tokenType = data.tokenType == 1 ? "BNB" : "BUSD";
      delete data.id;
      delete data.timeStamp;
      delete data.MonthNum;
      delete data.calculatedAmount;
      delete data.type;
      delete data.total_noOfTokens;
      delete data.total_userTransferTokens;
      return data;
    });

    setCsvRecord(recodData);

    const busdDecimal = await dispatch(
      getUSDTDecimal({
        wallet: wallet,
        address: BUSD_ADDRESS,
      })
    );
    setBusdTokenDecimal(10 ** busdDecimal);
    // const tokenDecimal = await dispatch(
    //   getUSDTDecimal({
    //     wallet: wallet ? wallet : "METAMASK",
    //     address: IFANCY_TOKEN_ADDRESS,
    //   })
    // );
    setTokenDecimal(10 ** 6);
  };

  const userOrderSearch = async (e) => {
    e.preventDefault();
    let data = {};
    let skip_value = e.target.value.length;
    if (skip_value) {
      data.search = e.target.value;
      const userDetails = await dispatch(_adminUserOrderSeach(data));
      setRecords(userDetails?.data);
      setUsersCount(userDetails?.data?.count);
    }
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <Container fluid className="commonTop">
      <AdminSearch
        title="Order List"
        total={usersCount}
        searchApi={userOrderSearch}
      />
      {/* <h1>{records?.TokensBoughtList?.length}</h1> */}
      <CSVLink data={csvRecord}>
        <button className="btn theme-btn mb-3">Download CSV</button>
      </CSVLink>
      ;
      <div className="AdminCard mb-5">
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Email</th>
              <th>Wallet address</th>
              <th>Total Tokens</th>
              <th>Total claimable tokens</th>
              <th>Tokens left</th>
              <th>Created Date</th>
              {/* <th>Status</th> */}
            </tr>
          </thead>
          <tbody>
            {records && records?.count > 0 ? (
              records?.TokensBoughtList?.map((items, key) => (
                <tr key={key}>
                  <td>{10 * (page - 1) + key + 1}</td>
                  <td>{items?.email}</td>
                  <td>
                    <a title={items?.walletAddress}>
                      {items?.walletAddress?.slice(0, 6)}.....
                      {items?.walletAddress?.slice(-6, -1)}
                    </a>
                  </td>
                  <td>
                    {(
                      Number(items?.total_noOfTokens) / ifanctTokenDecimal
                    ).toLocaleString("US")}
                  </td>
                  <td>
                    {(
                      Number(items?.total_userTransferTokens) /
                      ifanctTokenDecimal
                    ).toLocaleString("US")}
                  </td>
                  <td>
                    {(
                      Number(items?.total_noOfTokens) / ifanctTokenDecimal -
                      Number(items?.total_userTransferTokens) /
                        ifanctTokenDecimal
                    ).toLocaleString("US")}
                  </td>
                  <td>
                    {moment(items?.created_at).format("DD-MM-YYYY hh:mm:ss")}
                  </td>
                  {/* <td>{items?.status == 1 ? "Active" : "Inactive"}</td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colspan="6" style={{ textAlign: "center" }}>
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {usersCount > 10 ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={10}
            totalItemsCount={450}
            pageRangeDisplayed={Math.ceil(usersCount / 10)}
            onChange={(e) => handlePageChange(e)}
          />
        ) : null}
      </div>
    </Container>
  );
};

export default Order;
