import Web3 from "web3";
import { CHAIN_ID, rootName } from "../../constant";
// import { toast } from "../../components/common/toast";
import {
  LOGIN,
  SIGNUP,
  USER_ADDRESS,
  USER_WALLET,
} from "../constant.actionTypes";
import { initializeProvider } from "../../CommonServices/wallectConnectProvider";
import { toast } from "../../components/Toast/Toast";

// import Web3 from "web3";

let provider;

export const isMetaMaskInstalled = async () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window;
  const result = Boolean(ethereum && ethereum.isMetaMask);
  return result;
};

export const connectMetamask = async (dispatch, connect) => {
  const installed = await isMetaMaskInstalled();
  try {
    let address;
    if (installed) {
      // let validNetwork = await approveNetwork();
      // if (!validNetwork) return false;

      const { ethereum } = window;
      ethereum.on("accountsChanged", async function (accounts) {
        setTimeout(function () {
          // window.location.reload();
        }, 500);
        address = accounts[0];
        dispatch({ type: USER_ADDRESS, payload: "" });
        dispatch({ type: LOGIN, payload: "" });
        toast.success("Logout Successfully.");
        window.location.replace(`${rootName}/`);
        window.localStorage.clear();
        return false;

        return dispatch({ type: USER_ADDRESS, payload: address });
      });

      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      address = accounts[0];

      if (connect) {
        toast.success("Metamask Connected");
      }
      let web3Instance = new Web3(ethereum);

      dispatch({ type: USER_ADDRESS, payload: address });
      // window.location.reload();

      return "connected";
    } else {
      return toast.error("Please connect your wallet");
    }
  } catch (error) {
    return toast.error(error.message);
  }
};

export const approveNetwork = async () => {
  return new Promise(async (resolve, reject) => {
    if (CHAIN_ID === 56) {
      try {
        await window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x38" }],
          })
          .then((result) => {
            resolve(true);
          });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const result = await window.ethereum
              .request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x38",
                    chainName: "Testnet Binance",
                    rpcUrls: ["https://bsc-dataseed.binance.org/"],
                    blockExplorerUrls: ["https://bscscan.com/"],
                    iconUrls: [
                      "https://cdn.freebiesupply.com/logos/large/2x/binance-coin-logo-png-transparent.png",
                    ],
                    nativeCurrency: {
                      name: "BNB",
                      symbol: "BNB",
                      decimals: 18,
                    },
                  },
                ],
              })
              .then((result) => {
                resolve(true);
              });
          } catch (addError) {
            toast.error(addError.message);
            resolve(false);
          }
        }
      }
    } else {
      try {
        await window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x38" }],
          })
          .then((result) => {
            resolve(true);
          });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const result = await window.ethereum
              .request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x38",
                    chainName: "Binance Mainnet",
                    rpcUrls: ["https://bsc-dataseed.binance.org/"],
                    blockExplorerUrls: ["https://bscscan.com/"],
                    iconUrls: [
                      "https://cdn.freebiesupply.com/logos/large/2x/binance-coin-logo-png-transparent.png",
                    ],
                    nativeCurrency: {
                      name: "BNB",
                      symbol: "BNB",
                      decimals: 18,
                    },
                  },
                ],
              })
              .then((result) => {
                resolve(true);
              });
          } catch (addError) {
            toast.error(addError.message);
            resolve(false);
          }
        }
      }
    }
  });
};

export const connectWithWalletConnect = async (dispatch) => {
  try {
    if (!provider) {
      provider = await initializeProvider();
    }

    await provider
      .enable()
      .then(async (result) => {
        dispatch({ type: USER_ADDRESS, payload: result[0] });
        dispatch({ type: USER_WALLET, payload: "WALLETCONNECT" });
        let _web3Instance = new Web3(provider);

        // Subscribe to accounts change
        await provider.on("accountsChanged", async function (accounts) {
          setTimeout(function () {
            // window.location.reload();
          }, 500);
          let address = accounts[0];
          localStorage.clear();
          dispatch({ type: USER_ADDRESS, payload: "" });
          dispatch({ type: USER_WALLET, payload: "" });
          dispatch({ type: LOGIN, payload: "" });
          dispatch({ type: SIGNUP, payload: "" });
          // window.location.replace("/");

          return address;
        });
        // Subscribe to chainId change
        provider.on("chainChanged", (chainId) => {});
        // Subscribe to chainId change
        provider.on("networkChanged", (chainId) => {});
        // Subscribe to provider connection
        provider.on("connect", (chainId) => {});
        // Subscribe to session disconnection
        provider.on("disconnect", async (code, reason) => {
          // await provider.close()            networkChanged
        });

        // setInterval(async function () {
        //   const chainId = await _web3Instance.eth.getChainId();
        // }, 1000);
      })
      .catch((err) => {
        console.log("errrrrrrrrrrrrrrrrrrr", err);
        // window.location.reload();
      });

    if (!provider.connected) {
      await provider.enable();
    }

    const accounts = await provider.accounts[0];
    if (accounts) {
      // address = await Web3Service?.getAccount(type);
      dispatch({ type: USER_ADDRESS, payload: accounts });
      return dispatch({ type: USER_WALLET, payload: "WalletConnect" });
    }
  } catch (error) {
    await provider.disconnect();
    window.location.reload();
    toast.error(error.message);
    return;
  }
};
