import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../../redux/_actions/apis.action";
import { useParams } from "react-router-dom";
import "./verify.css";
import {
  LOGIN,
  SIGNUP,
  USER_ADDRESS,
  USER_WALLET,
} from "../../redux/constant.actionTypes";
import { disconnectAddress } from "../../redux/_actions/user.action";

const VerifyEmail = () => {
  const [emailStatus, setEmailStatus] = useState(0);
  const location = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const verifyfunction = async () => {
    const result = await dispatch(verifyEmail({ hash: location?.id }));
    if (result?.status === 200) {
      setEmailStatus(1);
      setTimeout(() => {
        dispatch({ type: USER_ADDRESS, payload: "" });
        dispatch({ type: USER_WALLET, payload: "" });
        dispatch({ type: LOGIN, payload: "" });
        dispatch({ type: SIGNUP, payload: "" });
        localStorage.clear();
        navigate(`/login`);
        return true;
      }, 5000);
    } else if (result?.status === 422) {
      setEmailStatus(2);
      setTimeout(() => {
        dispatch({ type: USER_ADDRESS, payload: "" });
        dispatch({ type: USER_WALLET, payload: "" });
        dispatch({ type: LOGIN, payload: "" });
        dispatch({ type: SIGNUP, payload: "" });
        localStorage.clear();
        navigate(`/`);
        return true;
      }, 5000);
    } else {
      setTimeout(() => {
        dispatch({ type: USER_ADDRESS, payload: "" });
        dispatch({ type: USER_WALLET, payload: "" });
        dispatch({ type: LOGIN, payload: "" });
        dispatch({ type: SIGNUP, payload: "" });
        // localStorage.clear();
        navigate(`/`);
        return true;
      }, 5000);

      await dispatch(disconnectAddress());
      setTimeout(function () {
        window.location.reload();
        window.location.replace("/login");
      }, 1000);
    }
  };

  useEffect(() => {
    verifyfunction();
  }, []);
  return (
    <div className="verify_div text-white">
      {emailStatus == 2 ? (
        <p>Email is already verified , please login to continue.</p>
      ) : emailStatus == 1 ? (
        <p>Thank you for confirming the Email , Now rediecting to homepage.</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default VerifyEmail;
