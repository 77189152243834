import React, { useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import logoimage from "../../../assets/images/logo.svg";
import { IFANCY_TOKEN_ADDRESS } from "../../../constant";
import { getUSDTDecimal } from "../../../redux/_actions/user.action";
import {
  BrowserView,
  isBrowser,
  isChrome,
  isFirefox,
  isOpera,
  isMobile,
} from "react-device-detect";
import tokenImg from "../../../assets/images/tokenimg.png";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../../assets/images/copy_icon.png";
import { toast } from "../../common/Toast";

const Header = () => {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state?.user.connectedWallet);
  const walletAddress = useSelector((state) => state?.user.walletAddress);
  let navigate = useNavigate();
  const [modalStates, setModalStates] = useState(false);
  const handleCloseds = () => setModalStates(false);

  const importToken = () => {
    setModalStates(true);
  };

  const copyClipBoard = () => {
    toast.success("Copied");
  };
  return (
    <header className="inside-sidebar">
      <Navbar expand="lg" variant="dark" className="justify-content-between">
        <Navbar.Brand href="/">
          <img src={logoimage} alt="logo" />
        </Navbar.Brand>
        <div className="text-right">
          <button
            className="btn theme-btn btn-registration mr-3 import-desktop"
            onClick={() => navigate('/referral-code')}
          >
            {"Referral code"}
          </button>
          <button
            className="btn mr-3 theme-btn btn-registration import-desktop "
            onClick={() => importToken()}
          >
            {"Import Tokens"}
          </button>
        </div>
      </Navbar>
      <Modal
        show={modalStates}
        onHide={handleCloseds}
        keyboard={false}
        className="connect_modal process_cl"
      >
        <Modal.Body className="modal_body12 ht_fix">
          <button
            className="closeBtn"
            type="button"
            onClick={() => handleCloseds()}
          >
            X
          </button>
          <h4 className="mb-4 text-center">IFANCY Token Address</h4>
          <span className="d-flex btn_fleximg">
            <h4>{IFANCY_TOKEN_ADDRESS} </h4>{" "}
            <CopyToClipboard text={IFANCY_TOKEN_ADDRESS} onCopy={copyClipBoard}>
              <span>
                {" "}
                <img src={copy} alt="Copy" width="24" />
              </span>
            </CopyToClipboard>
          </span>
          <h4>
            Copy this token address, open your Metamask wallet, press import
            tokens and paste into token contract address.
          </h4>
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default Header;
