import React, { useState, useEffect } from "react";
import "../Modal/Connect.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal, Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CommonService } from "../../util/commonService";
import {
  LOGIN,
  SIGNUP,
  USER_ADDRESS,
  USER_WALLET,
} from "../../redux/constant.actionTypes";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { toast } from "../../components/common/Toast";
import {
  login_api,
  register_api,
  resendEmail,
} from "../../redux/_actions/apis.action";
import Connect from "../Modal/Connect";
import { disconnectAddress } from "../../redux/_actions/user.action";

const SignupSchema = Yup.object().shape({
  useremail: Yup.string()
    .email("Invalid email")
    .required("Email Required")
    .trim("The Email cannot include leading and trailing spaces")
    .strict(true),
});

const ConnectStage = ({ type, Data }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showre, setShowRe] = useState(false);
  const handleClosere = () => setShowRe(false);
  const handleShowre = () => setShowRe(true);
  const metamaskAddress = useSelector((state) => state.user?.walletAddress);
  const wallet = useSelector((state) => state.user?.connectedWallet);

  const reglogFunc = async () => {
    if (!metamaskAddress) {
      setTimeout(() => {
        toast.error("Please connect wallet first");
      }, 100);
      setTimeout(() => {
        // setDisabled(false);
      }, 2000);
    }

    if (metamaskAddress && type == "login") {
      let loginata = {
        email: Data?.useremail,
        wallet_address: metamaskAddress,
      };

      const logindata = await dispatch(login_api(loginata));

      if (logindata?.status !== 400) {
        navigate(`/dashboard`);
      }
    } else if (metamaskAddress && type === "register") {
      let code =
        Data?.name.substring(0, 2).toUpperCase() + metamaskAddress.slice(-4);
      let registerData = {
        username: Data?.name,
        email: Data?.useremail,
        referral_code: Data?.referral_code,
        user_referral_code: code,
        wallet_address: metamaskAddress,
      };
      const result = await dispatch(register_api(registerData));
      if (result.status === 200) {
        localStorage.clear();
        dispatch({ type: USER_ADDRESS, payload: "" });
        dispatch({ type: USER_WALLET, payload: "" });
        dispatch({ type: LOGIN, payload: "" });
        dispatch({ type: SIGNUP, payload: "" });
        setTimeout(function () {
          window.location.reload();
          window.location.replace("/login");
        }, 5000);
      }
    }
  };

  const resendFunc = async (values) => {
    const result = await dispatch(resendEmail({ email: values?.useremail }));
  };
  const disconnectAccAdddress = async (wallet) => {
    await dispatch(disconnectAddress(wallet));
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <div data-aos="fade-up" data-aos-duration="2000">
        {" "}
        {/* <div className="contact-card registerCard py-5"> */}
        {/* <ul>
        <li>lorem plusam doloree</li>
        <li>lorem plusam dolor</li>
        <li>lorem plusam dolor</li>
        <li>lorem plusam dolor</li>
      </ul> */}
        <p className="text-white px-2">
          Please connect your wallet to complete account setup.
        </p>
        <p className="text-white px-2">
          Make sure to connect the wallet you wish to recieve the fancythattoken
          into.
        </p>
        {/* <p className="text-white px-2">
        lorem plsaum dolor waht you can dor ifor
        lorem plsaum dolor wahsaum dolor waht you can dor ifor
      </p> */}
        <Col sm={12} className="text-center  main_itembtn">
          {metamaskAddress ? (
            <button
              className="btn m-0 mb-2 theme-btn btn-registration"
              onClick={() => disconnectAccAdddress()}
            >
              {CommonService?.custmizeAddress(metamaskAddress)}
            </button>
          ) : (
            <button
              className="btn m-0 mb-2 theme-btn btn-registration"
              onClick={() => handleShow()}
            >
              Connect Wallet
            </button>
          )}
          <button
            className="btn m-0 px-4 mb-2 theme-btn btn-registration btn_padding"
            onClick={() => reglogFunc()}
          >
            {type == "login" ? "Log in" : "Register"}
          </button>
        </Col>
        <Connect
          show={show}
          handleShow={handleShow}
          handleClose={handleClose}
          type={type}
        />
        <a className="btn m-0 mb-2  w-100 m-auto" onClick={handleShowre}>
          <u>{type == "login" ? "Resend Email" : null}</u>
        </a>
        <Modal
          show={showre}
          onHide={handleClosere}
          keyboard={false}
          className="connect_modal process_cl "
        >
          <Modal.Header>
            <h5 className="m-auto">Resend Email{"  "}</h5>
            <button
              className="closeBtn"
              type="button"
              onClick={() => handleClosere()}
            >
              X
            </button>
          </Modal.Header>
          <Modal.Body className="modal_body12 modal_resendmail">
            <Formik
              initialValues={{
                useremail: "",
              }}
              validationSchema={SignupSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { resetForm }) => {
                resendFunc(values);
                resetForm({ values: "" });
                handleClosere();
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form onSubmit={handleSubmit} className="registerForm">
                  <div className="form-group">
                    <Field
                      name="useremail"
                      htmlFor="useremail"
                      id="useremail"
                      require="*"
                      type="email"
                      label="Email"
                      onChange={handleChange}
                      value={values.useremail}
                      placeholder="Enter Email"
                      maxLength={45}
                      className="form-control input_resend"
                    />
                    <p className="text-danger mt-3 position-absolute">
                      {errors.useremail}
                    </p>
                  </div>
                  <Row className="mt-5">
                    <Col sm={12} className="text-center">
                      <button
                        text="Submit"
                        type="submit"
                        className="btn m-0 mb-2 theme-btn btn-registration"
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ConnectStage;
