import Web3 from "web3";
import bnbtousd from "../assets/ABI/chainlink.json";
import { BNBTOUSDADDRESS } from "../constant";
import { binance } from "./CustomServiceJson";
import { initializeProvider } from "./wallectConnectProvider";
import { Alert } from "react-bootstrap";
import { isMobile } from "react-device-detect";

let provider;

const BnbFunction = async () => {
  if (!provider) {
    provider = await initializeProvider();
  }

  if (isMobile) {
    let _web3Instance = new Web3(provider);
    let Instance = new _web3Instance.eth.Contract(bnbtousd, BNBTOUSDADDRESS);
    return Instance;
  } else {
    let _web3Instance = new Web3(window.ethereum);
    let Instance = new _web3Instance.eth.Contract(bnbtousd, BNBTOUSDADDRESS);
    return Instance;
  }
};

const getUsdPrice = async () => {
  let contract = await BnbFunction();
  if (contract.methods) {
    const result = contract.methods.latestAnswer().call();
    if (result) {
      return result;
    }
  }
};

export { getUsdPrice };
