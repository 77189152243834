import React from 'react'
import { Table, Container} from 'react-bootstrap'
import AdminSearch from './AdminSearch'

const Transactions = () => {
  return (
    <Container fluid className='commonTop'>
         <AdminSearch title="Transactions" total="2" />
    <div className='AdminCard mb-5'>
    <Table striped hover responsive>
    <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Email</th>
        <th>Mobile No.</th>
        <th>wallet address</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
    <tr>
        <td>1</td>
        <td>gaurav</td>
        <td>gaurav@gmail.com</td>
        <td>4555564654646</td>
        <td>0x0c726787ca4asdf232asdf523cff550918a3a3a59bf4</td>
        <td>Active</td>
      </tr>
      <tr>
      <td>2</td>
       <td>g23aurav</td>
        <td>g23aurav@gmail.com</td>
        <td>453564654646</td>
        <td>0x026787ca4asdf232asdf523cff550918a3a3a59bf4</td>
        <td>Active</td>
      </tr>
      <tr>
      <td>3</td>
      <td>demoaav</td>
        <td>demoaav@gmail.com</td>
        <td>455556654646</td>
        <td>0x0c7206787ca4asdf232asdf523cff550918a3a3a59bf4</td>
        <td>Active</td>
      </tr>
      <tr>
        <td>4</td>
        <td>abhi</td>
        <td>abhi@gmail.com</td>
        <td>45554654646</td>
        <td>0x0c26787ca4asdf232asdf523cff550918a3a3a59bf4</td>
        <td>Active</td>
      </tr>
      <tr>
      <td>5</td>
      <td>urav</td>
        <td>urav@gmail.com</td>
        <td>45564654646</td>
        <td>000c726787ca4asdf232asdf523cff550918a3a3a59bf4</td>
        <td>Active</td>
      </tr>
      <tr>
      <td>6</td>
      <td>rav</td>
        <td>rav@gmail.com</td>
        <td>455564654646</td>
        <td>0x026787ca4asdf232asdf523cff550918a3a3a59bf4</td>
        <td>Active</td>
      </tr>
    </tbody>
  </Table>
  </div>
  </Container>
  )
}

export default Transactions