import React from "react";
import Form from "react-bootstrap/Form";

function CheckBox({
  label,
  labeltext,
  children,
  helpText,
  placeholder,
  value,
  onChange,
  style,
  type = "checkbox",
  required,
  maxLength,
  error,
}) {
  return (
    <>
      <Form.Group style={style}>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Check
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required || false}
          maxLength={maxLength || 999999999999}
          isInvalid={error}
          label={labeltext || children}
        />
      </Form.Group>
      <Form.Text className="error-msg form-text">{error}</Form.Text>
    </>
  );
}
export default CheckBox;
