import React from "react";
import "./index.css";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import tokenDistb from "../../assets/images/tokenDistb.png";


const TokenOmics = () => {

  const DistbList = (props) => {
    return (
      <div className={`distbList ${props.className}`}>
        <span>{props.title}</span>
        <span>{props.perc}</span>
      </div>
    );
  };

  const ComnHeading = (props) => {
    return (
      <div className="comnHeading">
        <span className="beforeTxt_Style">{props.beforeTxt}</span>
        <h2>{props.heading}</h2>
        <p>{props.subTxt}</p>
      </div>
    );
  };

  return (
    <section className="tokenDistb_sec comnPadd">
      <Container>
        <ComnHeading
          beforeTxt="Token Distribution"
          heading="Token Distribution"
        />
        <Row>
          <Col xl={7} lg={12}>
            <div className="tokenDistb_left">
              <img src={tokenDistb} alt="img" />
            </div>
          </Col>
          <Col xl={5} lg={12}>
            <div className="tokenDistb_right">
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <DistbList
                    title="Presale"
                    perc="2.50%"
                    className="bg1"
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <DistbList
                    title={
                      <>
                        Lottery/Burn 
                      </>
                    }
                    perc="20.00%"
                    className="bg2"
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <DistbList title="Team" perc="15%" className="bg3" />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <DistbList
                    title="Marketing"
                    perc="10.00%"
                    className="bg4"
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <DistbList title="Public Sale" perc="36%" className="bg5" />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <DistbList
                    title="Developer Incentives"
                    perc="2.50%"
                    className="bg6"
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <DistbList title="Charity" perc="9%" className="bg8" />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <DistbList title="Advisor/Airdrop" perc="5%" className="bg7" />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="text-white text-center mt-4">
          <p><strong>100,000,000,000 (100BN) FNCY will be created as a fixed supply</strong></p>
        </div>
      </Container>
    </section>
  );
};

export default TokenOmics;
